import { Card } from '@mui/material'
import clsx from 'clsx'
import { FC } from 'react'
interface Props {
    children?: any
    className?: string
}

// prettier-ignore
const CKPageContent: FC<Props> = ({ children, className }) => {
    return (
        <Card
            className={clsx('ck-page-content', {
                [className]: className ? true : false,
            })}
            sx={{
                p: 2,
                mx: { xs: 0, md: 2, lg: 3 },
                mt: -8,
                mb: -8,
                backgroundColor: ({
                    //@ts-ignore
                    palette: { white },
                    //@ts-ignore
                    functions: { rgba },
                }) => rgba(white.main, 0.6),
                backdropFilter: 'saturate(200%) blur(15px)',
                //@ts-ignore
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
        >
            {children}
        </Card>
    )
}
export default CKPageContent
