import { OverlayView, OverlayViewF } from '@react-google-maps/api'
import Location from 'models/Location'
import { FC, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

interface Props {
    location: Location
}
const CKLocationMarker: FC<Props> = (props) => {
    const history = useHistory()

    const location = useMemo(() => {
        return props.location
    }, [props.location])

    return (
        <>
            <OverlayViewF
                position={{
                    lat: location.$address.$lat,
                    lng: location.$address.$lng,
                }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
                <div
                    className="ck-location-marker"
                    style={{ backgroundColor: 'white' }}
                    onClick={(e) => {
                        e.preventDefault()
                        history.push(location.$detailUrl)
                    }}
                >
                    <img
                        alt={'location-logo-' + location.$slug}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                        src={location.$logo.$url}
                    />
                </div>
            </OverlayViewF>
        </>
    )
}
export default CKLocationMarker
