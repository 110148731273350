import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import MKDatePicker from 'components/MaterialKit/MKDatePicker'
import { FC, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'

interface Props {
    defaultValue?: any
    onChange: (date: Date) => void
    label?: string
    disabled?: boolean
    className?: string
    fullWidth?: boolean
    required?: boolean
    disableFuture?: boolean
    timeOnly?: boolean
}

const CKDatePicker: FC<Props> = (props): JSX.Element => {
    const [value, setValue] = useState(props.defaultValue)
    const [id] = useState(uuid())

    useMemo(() => {
        if (props.defaultValue) {
            setValue(props.defaultValue)
        }
    }, [props.defaultValue])

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="ck-time-picker" id={id}>
                    <MKDatePicker
                        options={
                            props.timeOnly
                                ? {
                                    noCalendar: true,
                                    enableTime: true,
                                    // dateFormat: "h:i K",
                                    time_24hr: true,
                                }
                                : {
                                    dateFormat: 'd/m/Y',
                                }
                        }
                        input={{
                            title: props.label,
                            label: props.label,
                            fullWidth: true,
                            required: props.required,
                            shrink: true,
                        }}
                        disabled={props.disabled}
                        value={value}
                        onChange={(e) => {
                            setValue(e)
                            if (e && e.length > 0) props.onChange(new Date(e))
                            else props.onChange(undefined)
                        }}
                        fullWidth
                    />
                </div>
            </LocalizationProvider>
        </>
    )
}

export default CKDatePicker
