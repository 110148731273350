import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { Container, Grid, Switch } from '@mui/material'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import CKImageUpload from 'components/Form/CKImageUpload'
import CKSocialsForm from 'components/Form/CKSocialsForm'
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import MKDatePicker from 'components/MaterialKit/MKDatePicker'
import MKInput from 'components/MaterialKit/MKInput'
import MKTypography from 'components/MaterialKit/MKTypography'
import { CKPageTitle } from 'components/UI/CKPageContent'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import { useArtistEdit } from 'services/UserService'
import { UserPageContainer } from '../UserPageContainer'
import { BadgeIcon, ImageIcon, InfoIcon, SaveIcon, ShareIcon, } from 'components/UI/CKIcons'
import { CKSidebarTabs } from 'components/UI/CKSidebarTabs'

interface Props { }

const PageUserEdit: FC<Props> = () => {
    const userId = useParams()['userId']
    const {
        user,
        errors,
        saving,
        loading,
        setPictureData,
        setBannerData,
        selectedComedyTags,
        setSelectedComedyTags,
        unselectedComedyTags,
        setUnselectedComedyTags,
        save
    } = useArtistEdit(userId);

    const renderGeneral = () => {
        if (!user) return;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MKInput
                        label="Voornaam"
                        title="firstName"
                        defaultValue={user.$first_name}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            user.$first_name = e.target.value
                        }}
                        required
                        fullWidth
                        error={errors.hasOwnProperty('firstName')}
                    />
                    <CKFormFeedback>{errors['firstName']}</CKFormFeedback>
                </Grid>
                <Grid item xs={12}>
                    <MKInput
                        label="Achternaam"
                        title="lastName"
                        defaultValue={user.$last_name}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            user.$last_name = e.target.value
                        }}
                        required
                        fullWidth
                        error={errors.hasOwnProperty('lastName')}
                    />
                    <CKFormFeedback>{errors['lastName']}</CKFormFeedback>
                </Grid>
                <Grid item xs={12}>
                    <MKDatePicker
                        input={{
                            title: 'Geboortedatum',
                            label: 'Geboortedatum',
                            fullWidth: true,
                            required: true,
                        }}
                        value={user.$birthdate}
                        onChange={(e) => {
                            const _date = new Date(e)
                            user.$birthdate = _date
                        }}
                        fullWidth
                        required
                    />
                    <CKFormFeedback>{errors['birthdate']}</CKFormFeedback>
                </Grid>
                <Grid item xs={12}>
                    <MKInput
                        type="standard"
                        multiline
                        defaultValue={user.$description}
                        placeholder="Geef een beschrijving"
                        title="Beschrijving"
                        label="Beschrijving"
                        onChange={(e) => {
                            user.$description = e.target.value
                        }}
                        InputLabelProps={{ shrink: true }}
                        rows={8}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <MKDatePicker
                        input={{
                            title: 'Begonnen met comedy',
                            label: 'Begonnen met comedy',
                            fullWidth: true,
                            required: true,
                        }}
                        value={user.$started_at}
                        onChange={(e) => {
                            const _date = new Date(e)
                            user.$started_at = _date
                        }}
                        fullWidth
                        required
                    />
                </Grid>

            </Grid>
        )

    }

    const renderImageForm = () => {
        if (!user) return null

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <MKTypography variant="caption">Profielfoto</MKTypography>
                    <CKImageUpload
                        defaultImage={user.$picture}
                        onChange={(files: Array<{ fileName: string; ext: string; content: string | ArrayBuffer }>) => {
                            setPictureData(files[0])
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    <MKTypography variant="caption">Banner</MKTypography>
                    <CKImageUpload
                        defaultImage={user.$banner}
                        onChange={(files: Array<{ fileName: string; ext: string; content: string | ArrayBuffer }>) => {
                            setBannerData(files[0])
                        }}
                    />
                </Grid>
            </Grid>
        )
    }

    const renderAbout = () => {
        if (!user) return null;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MKTypography variant="button" color="text" fontWeight="bold">
                        Duidt aan wat van toepassing is op jou:
                    </MKTypography>
                    <div className="d-flex justify-content-between align-items-center px-3">
                        <MKTypography variant="button" color="dark">
                            Open-Mic'er
                        </MKTypography>
                        <Switch
                            defaultChecked={user.$isOpenMicer}
                            color="info"
                            onChange={(e) => {
                                user.$isOpenMicer = e.target.checked
                            }}
                        />
                    </div>
                    <div className="d-flex justify-content-between align-items-center px-3">
                        <MKTypography variant="button" color="dark">
                            MC
                        </MKTypography>
                        <Switch
                            defaultChecked={user.$isMC}
                            color="info"
                            onChange={(e) => {
                                user.$isMC = e.target.checked
                            }}
                        />
                    </div>
                    <div className="d-flex justify-content-between align-items-center px-3">
                        <MKTypography variant="button" color="dark">
                            Organisator
                        </MKTypography>
                        <Switch
                            defaultChecked={user.$isOrganizer}
                            color="info"
                            onChange={(e) => {
                                user.$isOrganizer = e.target.checked
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <MKTypography variant="button" color="text">Comedy labels</MKTypography>
                    <MKBox>
                        <Grid container spacing={1}>
                            {selectedComedyTags.map((comedyTag) => {
                                return (
                                    <Grid item key={'comedy-tag-' + comedyTag.$id}>
                                        <MKButton
                                            variant="contained"
                                            color="info"
                                            size="small"
                                            circular
                                            sx={
                                                {
                                                    // padding: 0,
                                                }
                                            }
                                            onClick={() => {
                                                let _selectedTags =
                                                    selectedComedyTags.slice()
                                                _selectedTags = _selectedTags.filter(
                                                    (tag) => tag.$id !== comedyTag.$id
                                                )
                                                setSelectedComedyTags(_selectedTags)

                                                const _unselectedTags =
                                                    unselectedComedyTags.slice()
                                                _unselectedTags.push(comedyTag)
                                                setUnselectedComedyTags(_unselectedTags)
                                            }}
                                        >
                                            {comedyTag.$name} <CloseIcon fontSize="small" />
                                        </MKButton>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </MKBox>
                    <MKBox mt={2}>
                        <Grid container spacing={1}>
                            {unselectedComedyTags.map((comedyTag) => {
                                return (
                                    <Grid item key={'comedy-tag-' + comedyTag.$id}>
                                        <MKButton
                                            variant="outlined"
                                            color="info"
                                            size="small"
                                            circular
                                            onClick={() => {
                                                let _unselectedTags =
                                                    unselectedComedyTags.slice()
                                                _unselectedTags = _unselectedTags.filter(
                                                    (tag) => tag.$id !== comedyTag.$id
                                                )
                                                setUnselectedComedyTags(_unselectedTags)

                                                const _selectedTags =
                                                    selectedComedyTags.slice()
                                                _selectedTags.push(comedyTag)
                                                setSelectedComedyTags(_selectedTags)
                                            }}
                                        >
                                            {comedyTag.$name} <AddIcon fontSize="small" />
                                        </MKButton>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </MKBox>
                </Grid>
            </Grid>
        )
    }


    const sidebarTabs = {
        root: {
            General: {
                title: 'Algemeen',
                icon: <InfoIcon />,
                component: renderGeneral(),
            },
            Image: {
                title: 'Afbeeldingen',
                icon: <ImageIcon />,
                component: renderImageForm(),
            },
            Socials: {
                title: 'Socials',
                icon: <ShareIcon />,
                component: <CKSocialsForm model={user} />
            },
            About: {
                title: 'Over jou',
                icon: <BadgeIcon />,
                component: renderAbout()
            },
        }
    }


    return (
        <>
            <UserPageContainer user={user} loading={loading}>
                <CKPageTitle>{'Pas profiel aan'}</CKPageTitle>
                <Container className="my-3">
                    {user && (
                        <CKSidebarTabs
                            sections={sidebarTabs}
                            footer={
                                <div className="text-center">
                                    <MKButton
                                        type="submit"
                                        variant="gradient"
                                        color="info"
                                        onClick={save}
                                        disabled={saving}
                                    >
                                        {saving ? (
                                            <p className="mb-0 me-2">
                                                <ClipLoader size={15} color={'white'} />
                                            </p>
                                        ) : (
                                            <SaveIcon className="me-2" />
                                        )}
                                        Sla op!
                                    </MKButton>
                                </div>
                            }
                            hideGroup
                        />
                    )}
                </Container>
            </UserPageContainer>

        </>
    )
}
export default PageUserEdit
