import { Grid, useMediaQuery } from '@mui/material'
import MKTypography from 'components/MaterialKit/MKTypography'
import { FC } from 'react'

interface Props {
    center?: boolean
    children?: any
    [rest: string]: any
}
const CKPageBody: FC<Props> = ({ center, children, ...rest }) => {
    const isMobile = useMediaQuery('(max-width:768px)')

    return (
        <Grid container spacing={3} className="d-flex justify-content-center" {...rest}>
            <Grid item xs={12} md={10}>
                <MKTypography
                    component="div"
                    variant="body2"
                    color="text"
                    px={isMobile ? 2 : 4}
                    pt={2}
                    mb={3}
                    style={{ textAlign: center ? 'center' : 'left' }}
                    {...rest}
                >
                    {children}
                </MKTypography>
            </Grid>
        </Grid>
    )
}
export default CKPageBody
