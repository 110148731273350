import { useMediaQuery } from '@mui/material'
import { FC, ReactNode } from 'react'
import { CKDesktopSidebarTabs } from './DesktopSidebarTabs'
import { CKMobileSidebarTabs } from './MobileSidebarTabs'
import './style.scss'
export interface ISidebarTabs {
    sections: {
        [key: string]: {
            [key: string]: {
                title?: string
                icon: any
                onlyMobile?: boolean
                component: ReactNode
            }
        }
    }
    hideTitle?: boolean
    hideGroup?: boolean
    footer?: ReactNode
}

export const CKSidebarTabs: FC<ISidebarTabs> = (props) => {
    const isMobileSize = useMediaQuery('(max-width:768px)')

    return (
        <div className="ck-sidebar-tabs">
            {isMobileSize ? (
                <CKMobileSidebarTabs {...props} />
            ) : (
                <CKDesktopSidebarTabs {...props} />
            )}
        </div>
    )
}
