import { Card, Collapse, Container, Grid, InputAdornment, useMediaQuery } from '@mui/material'
import bgImage from 'assets/images/bgImage.jpg'
import bgImage2 from 'assets/images/bgImage2.jpg'
import CKSearchBar from 'components/Form/CKSearchBar'
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import { CKHero } from 'components/UI/CKHero'
import { CKPageBody, CKPageContent } from 'components/UI/CKPageContent'
import { FC, Fragment, useEffect, useMemo, useState } from 'react'

import PlaceIcon from '@mui/icons-material/Place'
import TuneIcon from '@mui/icons-material/Tune'

import clsx from 'clsx'
import CKAddressPicker from 'components/Form/CKAddressPicker'
import CKDatePicker from 'components/Form/CKDatePicker'
import CKSelect from 'components/Form/CKSelect'
import MKTypography from 'components/MaterialKit/MKTypography'
import CKOrganisationCompactCard from 'components/UI/CKOrganisationCard/CKOrganisationCompactCard'
import CKPagination from 'components/UI/CKPagination'
import Organisation from 'models/Organisation'
import { useLocation } from 'react-router-dom'
import OrganisationService from 'services/OrganisationService'
import CKIndexProps from 'utils/CKIndexProps'

interface Props {}
const PageOrganisationOverview: FC<Props> = () => {
    const isMobile = useMediaQuery('(max-width:768px)')
    const [searchTerm, setSearchTerm] = useState('')
    const [seed] = useState(Math.round(Math.random() * 9999))
    const [organisations, setOrganisations] = useState([])
    const [viewMode, setViewMode] = useState('grid')
    const { search } = useLocation()

    const [filtersOpen, setFiltersOpen] = useState(false)
    const [filters, setFilters] = useState({ includes: ['mc', 'openmicer'] })

    const [paginationInfo, setPaginationInfo] = useState<{
        current_page: number
        total: number
        per_page: number
        last_page: number
        to: number
    }>()

    const init = async (
        _page: number = 1,
        _search: string = searchTerm,
        _filters: any = filters,
        _viewMode = viewMode
    ) => {
        if (!seed) return

        let data: CKIndexProps = {}

        data = { seed: seed, page: _page, perPage: 9, search: _search }

        if (_filters['organisation']) {
            const organisation = _filters['organisation']
            data['organisation'] = `${organisation.lat},${organisation.lng},${organisation.radius}`
        }

        if (_filters['start']) {
            data['start'] = _filters['start']
        }

        if (_filters['end']) {
            data['end'] = _filters['end']
        }

        const resp = await OrganisationService.index(data)
        setPaginationInfo(resp)
        setOrganisations(resp.data)
    }

    useMemo(async () => {
        const query = new URLSearchParams(search)
        const _viewMode = query.get('view')
        if (_viewMode) {
            setViewMode(_viewMode)
        }
        init(1, searchTerm, filters, _viewMode)
    }, [search])

    const renderFilters = () => {
        const distances = [3, 5, 10, 15, 25, 50, 75]

        return (
            <>
                <MKBox component="div" sx={{ mt: 4 }} className="d-flex  mb-3">
                    <div className="pe-3 w-100">
                        <CKSearchBar
                            onSearch={(e) => {
                                setSearchTerm(e)
                                init(1, e)
                            }}
                        />
                    </div>
                    <MKButton
                        variant="outlined"
                        color="info"
                        size="small"
                        onClick={() => {
                            setFiltersOpen((prev) => !prev)
                        }}
                    >
                        <TuneIcon className={clsx({ 'me-2': !isMobile })} />{' '}
                        {!isMobile && 'Filters'}
                    </MKButton>
                </MKBox>
                <Collapse in={filtersOpen} sx={{ pb: filtersOpen && 4 }}>
                    <Card sx={{ p: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <MKTypography variant="caption" fontWeight="bold">
                                    In de buurt van:
                                </MKTypography>
                                <Grid
                                    container
                                    columnSpacing={3}
                                    className="d-flex justify-content-center align-items-end"
                                    style={{ height: '45px' }}
                                >
                                    <Grid item xs={8}>
                                        <CKAddressPicker
                                            className={'w-100'}
                                            onSelect={({ lat, lng }) => {
                                                if (!lat || !lng) return

                                                const _filters = filters
                                                if (!_filters['organisation']) {
                                                    _filters['organisation'] = {
                                                        radius: 50,
                                                    }
                                                }

                                                _filters['organisation']['lat'] = lat
                                                _filters['organisation']['lng'] = lng

                                                setFilters(_filters)
                                                init(1, searchTerm, _filters)
                                            }}
                                            placeholder="Locatie"
                                            hideMap={true}
                                            types={['locality']}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PlaceIcon fontSize="medium" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CKSelect
                                            variant="standard"
                                            options={distances.map((a) => {
                                                return {
                                                    value: a,
                                                    label: '< ' + a + 'km',
                                                }
                                            })}
                                            defaultValue={
                                                filters &&
                                                filters['organisation'] &&
                                                filters['organisation'].radius
                                                    ? filters['organisation'].radius
                                                    : 50
                                            }
                                            onSelect={(e) => {
                                                const value: number =
                                                    typeof e === 'string' ? parseInt(e) : e

                                                const _filters = filters
                                                if (!_filters['organisation']) {
                                                    _filters['organisation'] = {}
                                                }

                                                _filters['organisation']['radius'] = value
                                                setFilters(_filters)
                                                init(1, searchTerm, _filters)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MKTypography variant="caption" fontWeight="bold">
                                    Heeft optredens tussen:
                                </MKTypography>
                                <Grid
                                    container
                                    columnSpacing={3}
                                    style={{ height: '45px' }}
                                    className="d-flex justify-content-center align-items-end"
                                >
                                    <Grid item xs={6}>
                                        <CKDatePicker
                                            label={'Startdatum'}
                                            onChange={(e: Date) => {
                                                const _filters = filters
                                                if (e) _filters['start'] = e.toDateString()
                                                else _filters['start'] = undefined
                                                setFilters(_filters)
                                                init(1, searchTerm, _filters)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CKDatePicker
                                            label={'Einddatum'}
                                            onChange={(e) => {
                                                const _filters = filters
                                                if (e) _filters['end'] = e.toDateString()
                                                else _filters['end'] = undefined
                                                setFilters(_filters)
                                                init(1, searchTerm, _filters)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Collapse>
            </>
        )
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <>
            <CKHero
                bgImage={isMobile ? bgImage2 : bgImage}
                title={'Organisaties'}
                maxHeight={isMobile ? '300px' : '500px'}
                height={'50vh'}
                description={'Zij brengen je de beste comedy events!'}
            />
            <CKPageContent>
                <CKPageBody>
                    <Container>
                        {renderFilters()}
                        <div style={{ minHeight: !isMobile && '900px' }}>
                            <Grid
                                container
                                spacing={3}
                                direction="row"
                                justifyContent={'center'}
                                alignItems={'flex-start'}
                            >
                                {!organisations ||
                                    (organisations.length === 0 && (
                                        <div
                                            className={clsx(
                                                'd-flex justify-content-center align-items-center w-100 pt-3',
                                                { 'pt-3': isMobile }
                                            )}
                                        >
                                            <MKTypography fontWeight="bold" className="mt-2">
                                                Geen organisaties gevonden met de opgegeven
                                                criteria...
                                            </MKTypography>
                                        </div>
                                    ))}
                                {organisations.map((organisation: Organisation) => {
                                    return (
                                        <Fragment key={'user-card-' + organisation.$id}>
                                            <Grid item xs={12} md={6} lg={3}>
                                                <CKOrganisationCompactCard
                                                    organisation={organisation}
                                                />
                                            </Grid>
                                        </Fragment>
                                    )
                                })}
                            </Grid>
                        </div>
                        <CKPagination
                            onSelect={(e) => {
                                init(e)
                            }}
                            info={paginationInfo}
                        />
                    </Container>
                </CKPageBody>
            </CKPageContent>
        </>
    )
}
export default PageOrganisationOverview
