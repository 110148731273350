import Event from 'models/Event'
import EventSpot from 'models/EventSpot'
import User from 'models/User'
import AuthService from './AuthService'
import { EVENTS, SPOTS } from './Constants'

const EventSpotService = {
    apply: async function (event: Event, spot: EventSpot) {
        const resp = await AuthService.post(`${EVENTS}/${event.$slug}/${SPOTS}/${spot.$id}/apply`, {})
        return EventSpot.fromJSON(resp.data)
    },
    resign: async function (event: Event, spot: EventSpot) {
        const resp = await AuthService.post(`${EVENTS}/${event.$slug}/${SPOTS}/${spot.$id}/resign`, {})
        return EventSpot.fromJSON(resp.data)
    },
    getApplications: async function (event: Event, spot: EventSpot, search: string = '') {
        const resp = await AuthService.get(
            `${EVENTS}/${event.$slug}/${SPOTS}/${spot.$id}/applications?search=${search}`
        )

        const result: User[] = []
        for (const e of resp.data) {
            result.push(User.fromJSON(e))
        }

        resp.data = result
        return resp
    },
}
export default EventSpotService
