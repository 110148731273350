import { forwardRef } from 'react'
import MKDatePickerJS from './MKDatePickerJS'
import './style.scss'

const MKDatePicker = forwardRef((props: any, ref) => {
    return (
        //@ts-ignore
        <MKDatePickerJS forwardRef={ref} {...props}></MKDatePickerJS>
    )
})
export default MKDatePicker
