import useSWR from "swr"
import { ORGANISATIONS } from "./Constants"
import Organisation from "models/Organisation"
import AuthService from "./AuthService"

export function useOrganisationMollieState(org: Organisation) {
    const url = `${ORGANISATIONS}/${org.$slug}/mollie/oauth/state`
    const { data, ...props } = useSWR(url)

    return {
        state: data?.state,
        ...props,
    }
}

export async function postMollieOAuthCallback(state: string, code: string) {
    if (!state || !code) return
    const url = `mollie/oauth/callback`
    const resp = await AuthService.post(url, { state, code })

    if (resp.data.organisation) {
        return Organisation.fromJSON(resp.data.organisation)
    }
    return resp.data;
}

export async function disconnectOrganisationMollie(org: Organisation) {
    const url = `${ORGANISATIONS}/${org.$slug}/mollie`
    const resp = await AuthService.delete(url)

    return resp.data;
}