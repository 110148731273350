import MKTypography from 'components/MaterialKit/MKTypography'
import { FC } from 'react'

interface Props {
    children: any
}

const CKFormFeedback: FC<Props> = (props) => {
    return (
        <MKTypography
            variant="label"
            style={{
                fontSize: '0.8rem',
                color: 'red',
            }}
        >
            {props.children}
        </MKTypography>
    )
}
export default CKFormFeedback
