import Social from 'models/Social'
import { FC } from 'react'
import { SocialIcon } from 'react-social-icons'

interface Props {
    socials: Social[]
}

const CKSocials: FC<Props> = (props) => {
    return (
        <div className="d-flex justify-content-center align-items-center">
            {props.socials.map((social) => {
                return (
                    <SocialIcon
                        key={`social-${social.$platform}`}
                        network={social.$platform.toLowerCase()}
                        url={social.$link}
                        target="_blank"
                        className="mx-3"
                        style={{ height: '2.5rem', width: '2.5rem' }}
                    />
                )
            })}
        </div>
    )
}
export default CKSocials
