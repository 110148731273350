import { forwardRef } from 'react'
import MKAvatarJS from './MKAvatarJS'

const MKAvatar = forwardRef((props: any, ref) => {
    return (
        //@ts-ignore
        <MKAvatarJS ref={ref} {...props}></MKAvatarJS>
    )
})
export default MKAvatar
