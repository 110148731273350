import CKModel from 'models/CKModel'
import User from 'models/User'
import AuthService from './AuthService'
import { ACCESS } from './Constants'

const AccessService = {
    getPossibilities: async function (model: CKModel) {
        const resp = await AuthService.get(`${ACCESS}/possibilities?model=${model.$className}`)

        return resp.data
    },
    getElevatedUsers: async function (model: CKModel) {
        const resp = await AuthService.get(`${ACCESS}/${model.$className}/${model.$id}`)

        const result = []
        for (const data of resp.data) {
            if (data.user) {
                data.user = User.fromJSON(data.user)
            }
            if (data.rights) {
                data.rights = JSON.parse(data.rights)
            }
            result.push(data)
        }
        return result
    },
    updateElevatedUsers: async function (model: CKModel, data: { user_id: number; rights: [] }[]) {
        const resp = await AuthService.put(`${ACCESS}/${model.$className}/${model.$id}`, {
            access: data,
        })
        const result = []
        for (const data of resp.data) {
            if (data.user) {
                data.user = User.fromJSON(data.user)
            }
            if (data.rights) {
                data.rights = JSON.parse(data.rights)
            }
            result.push(data)
        }
        return result
    },
}
export default AccessService
