import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { InputAdornment, Popover } from '@mui/material'
import MKButton from 'components/MaterialKit/MKButton'
import MKInput from 'components/MaterialKit/MKInput'
import { FC, useState } from 'react'
import './style.scss'

interface Props {
    variant?: string
    options: { value: any; label: any; [rest: string]: any }[]
    onSelect?: (value) => void
    defaultValue?: any
    label?: string
    required?: boolean
    className?: string
}
const CKSelect: FC<Props> = (props) => {
    const [value, setValue] = useState(props.defaultValue)
    const [open, setOpen] = useState(false)
    const [inputRef, setInputRef] = useState<EventTarget & HTMLDivElement>()
    const [width, setWidth] = useState(100)
    const [inputLabel, setInputLabel] = useState(
        props.defaultValue
            ? props.options.find((a) => a.value === props.defaultValue)?.label
            : undefined
    )

    return (
        <>
            <div
                className={`ck-select-container ${props.className}`}
                onClick={(e) => {
                    setOpen((prev) => !prev)
                    setWidth(e.currentTarget.offsetWidth)
                    setInputRef(e.currentTarget)
                }}
            >
                <MKInput
                    className="ck-select-input"
                    variant={props.variant ? props.variant : 'outlined'}
                    // disabled
                    value={inputLabel}
                    fullWidth
                    disabled
                    InputProps={{
                        classes: { 'bg-none': true },
                        endAdornment: (
                            <InputAdornment position="end">
                                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </InputAdornment>
                        ),
                    }}
                    label={props.label}
                    required={props.required}
                />
            </div>
            <Popover
                className="w-100"
                open={open && Boolean(inputRef)}
                anchorEl={inputRef}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={(e) => {
                    setOpen(false)
                    setInputRef(undefined)
                }}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                }}
            >
                <div className="bg-white text-center" style={{ width: width + 'px' }}>
                    {props.options.map((option, index) => {
                        return (
                            <div key={'ck-select-option-' + index}>
                                <MKButton
                                    variant="text"
                                    color="info"
                                    className="px-4"
                                    onClick={(e) => {
                                        setValue(option.value)
                                        setOpen(false)
                                        if (props.onSelect) {
                                            props.onSelect(option.value)
                                            setInputLabel(option.label)
                                        }
                                    }}
                                >
                                    {option.label}
                                </MKButton>
                            </div>
                        )
                    })}
                </div>
            </Popover>
        </>
    )
}
export default CKSelect
