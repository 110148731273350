import CKModel from './CKModel'

export default class EventSpot extends CKModel {
    private event_id: string | undefined
    private length: number | undefined
    private amount: number | undefined
    private applied: boolean | undefined
    private type: string | undefined

    constructor(
        id: string | undefined = undefined,
        event_id: string | undefined = undefined,
        length: number | undefined = 8,
        amount: number | undefined = 8,
        applied: boolean | undefined = false,
        type: string | undefined = undefined
    ) {
        super(id)
        this.event_id = event_id
        this.length = length
        this.amount = amount
        this.applied = applied
        this.type = type
    }

    public static fromJSON(json: any): EventSpot {
        let result = new EventSpot()
        result = Object.assign(result, json)
        return result
    }

    public get $length(): number | undefined {
        return this.length
    }
    public set $length(value: number | undefined) {
        this.length = value
    }

    public get $amount(): number | undefined {
        return this.amount
    }
    public set $amount(value: number | undefined) {
        this.amount = value
    }

    public get $eventId(): string | undefined {
        return this.event_id
    }
    public set $eventId(value: string | undefined) {
        this.event_id = value
    }

    public get $applied(): boolean | undefined {
        return this.applied
    }
    public set $applied(value: boolean | undefined) {
        this.applied = value
    }

    public get $type(): string | undefined {
        return this.type
    }
    public set $type(value: string | undefined) {
        this.type = value
        if (value === 'MC') {
            this.amount = 1
            this.length = 0
        }
    }

    public get $className(): string {
        return 'event_spot'
    }
}
