import { forwardRef } from 'react'
import MKButtonJS from './MKButtonJS'

const MKButton = forwardRef((props: any, ref) => {
    return (
        //@ts-ignore
        <MKButtonJS ref={ref} {...props}></MKButtonJS>
    )
})
export default MKButton
