/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// react-flatpickr components
import Flatpickr from 'react-flatpickr'

// react-flatpickr styles
import 'flatpickr/dist/flatpickr.css'

// Material Kit 2 React components
import MKInput from 'components/MaterialKit/MKInput'

function MKDatePickerJS({ input, value, ...rest }) {
    return (
        <Flatpickr
            options={{
                dateFormat: 'd/m/Y',
            }}
            value={value}
            {...rest}
            render={({ defaultValue }, ref) => (
                <MKInput
                    {...input}
                    InputLabelProps={{ shrink: Boolean(value) }}
                    defaultValue={defaultValue}
                    inputRef={ref}
                />
            )}
        />
    )
}

// Setting default values for the props of MKDatePickerJS
MKDatePickerJS.defaultProps = {
    input: {},
}

// Typechecking props for the MKDatePickerJS
MKDatePickerJS.propTypes = {
    input: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
}

export default MKDatePickerJS
