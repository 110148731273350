import { Card, Collapse, Container, Grid, InputAdornment, useMediaQuery } from '@mui/material'
import bgImage from 'assets/images/bgImage.jpg'
import bgImage2 from 'assets/images/bgImage2.jpg'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import { CKHero } from 'components/UI/CKHero'
import { CKPageBody, CKPageContent } from 'components/UI/CKPageContent'
import { FC, Fragment, useState } from 'react'

import PlaceIcon from '@mui/icons-material/Place'
import TuneIcon from '@mui/icons-material/Tune'
import clsx from 'clsx'
import CKAddressPicker from 'components/Form/CKAddressPicker'
import CKCheckBox from 'components/Form/CKCheckBox'
import CKDatePicker from 'components/Form/CKDatePicker'
import CKSearchBar from 'components/Form/CKSearchBar'
import CKSelect from 'components/Form/CKSelect'
import MKBox from 'components/MaterialKit/MKBox'
import CKArtistCard from 'components/UI/CKArtistCard'
import CKPagination from 'components/UI/CKPagination'
import User from 'models/User'
import { useArtists } from 'services/UserService'
const seed = Math.round(Math.random() * 9999)

interface Props { }
const PageArtists: FC<Props> = () => {
    const isMobile = useMediaQuery('(max-width:768px)')
    const [page, setPage] = useState(1)
    const [searchTerm, setSearchTerm] = useState('')
    const [filtersOpen, setFiltersOpen] = useState(false)

    const [includes, setIncludes] = useState(['mc', 'openmicer'])
    const [locationFilter, setLocationFilter] = useState<{ lat?, lng?, radius?}>({});
    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')

    const { artists: users, paginationInfo } = useArtists({
        seed,
        page,
        perPage: 12,
        start, end,
        search: searchTerm,
        includes: includes.join(','),
        location: Object.values(locationFilter).join(',')
    })

    const renderFilters = () => {
        const distances = [3, 5, 10, 15, 25, 50, 75]

        return (
            <>
                <MKBox component="div" sx={{ mt: 4 }} className="d-flex  mb-3">
                    <div className="pe-3 w-100">
                        <CKSearchBar
                            onSearch={(e) => {
                                setSearchTerm(e)
                            }}
                        />
                    </div>
                    <MKButton
                        variant="outlined"
                        color="info"
                        size="small"
                        onClick={() => {
                            setFiltersOpen((prev) => !prev)
                        }}
                    >
                        <TuneIcon className={clsx({ 'me-2': !isMobile })} />{' '}
                        {!isMobile && 'Filters'}
                    </MKButton>
                </MKBox>
                <Collapse in={filtersOpen} sx={{ pb: 4 }}>
                    <Card sx={{ p: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={2}>
                                <MKTypography variant="caption" fontWeight="bold">
                                    Treedt op als:
                                </MKTypography>
                                <div
                                    className={clsx({
                                        'd-flex justify-content-between px-2 pt-2': isMobile,
                                    })}
                                >
                                    <CKCheckBox
                                        defaultChecked={includes.includes('openmicer')
                                        }
                                        onChange={(e) => {
                                            setIncludes((prev) => {
                                                if (e.target.checked) {
                                                    return [...prev, 'openmicer']
                                                }
                                                return prev.filter((a) => a !== 'openmicer')
                                            });
                                        }}
                                        label="Open-Mic'er"
                                    />
                                    <CKCheckBox
                                        defaultChecked={
                                            includes.includes('mc')
                                        }
                                        onChange={(e) => {
                                            setIncludes((prev) => {
                                                if (e.target.checked) {
                                                    return [...prev, 'mc']
                                                }
                                                return prev.filter((a) => a !== 'mc')
                                            });
                                        }}
                                        label="MC"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MKTypography variant="caption" fontWeight="bold">
                                    Treedt op in de buurt van:
                                </MKTypography>
                                <Grid
                                    container
                                    columnSpacing={3}
                                    className="d-flex justify-content-center align-items-end"
                                    style={{ height: '45px' }}
                                >
                                    <Grid item xs={8}>
                                        <CKAddressPicker
                                            className={'w-100'}
                                            onSelect={({ lat, lng }) => {
                                                if (!lat || !lng) return

                                                let radius = locationFilter?.radius;
                                                if (!radius) {
                                                    radius = 50;
                                                }

                                                setLocationFilter({ lat, lng, radius });
                                            }}
                                            placeholder="Locatie"
                                            hideMap={true}
                                            types={['locality']}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PlaceIcon fontSize="medium" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CKSelect
                                            variant="standard"
                                            options={distances.map((a) => {
                                                return {
                                                    value: a,
                                                    label: '< ' + a + 'km',
                                                }
                                            })}
                                            defaultValue={
                                                locationFilter?.radius ?? 50
                                            }
                                            onSelect={(e) => {
                                                const value: number =
                                                    typeof e === 'string' ? parseInt(e) : e

                                                setLocationFilter((prev) => {
                                                    return { ...prev, radius: value }
                                                })
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <MKTypography variant="caption" fontWeight="bold">
                                    Treedt op tussen:
                                </MKTypography>
                                <Grid
                                    container
                                    columnSpacing={3}
                                    style={{ height: '45px' }}
                                    className="d-flex justify-content-center align-items-end"
                                >
                                    <Grid item xs={6}>
                                        <CKDatePicker
                                            label={'Startdatum'}
                                            onChange={(e: Date) => {
                                                if (e) setStart(e.toDateString())
                                                else setStart(undefined)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CKDatePicker
                                            label={'Einddatum'}
                                            onChange={(e) => {
                                                if (e) setEnd(e.toDateString())
                                                else setEnd(undefined)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Collapse>
            </>
        )
    }

    return (
        <>
            <CKHero
                bgImage={isMobile ? bgImage2 : bgImage}
                title={'Artiesten'}
                size="md"
                maxHeight={'500px'}
                height={'30vh'}
                description={"De beste open-mic'ers en MC'ers die Vlaanderen te bieden heeft"}
            />
            <CKPageContent>
                <CKPageBody>
                    <Container>
                        {renderFilters()}
                        <div style={{ minHeight: !isMobile && '940px' }}>
                            <Grid
                                container
                                spacing={5}
                                direction="row"
                                justifyContent={'center'}
                                alignItems={'flex-start'}
                            >
                                {!users ||
                                    (users.length === 0 && (
                                        <div
                                            className={clsx(
                                                'd-flex justify-content-center align-items-center w-100 pt-3',
                                                { 'pt-3': isMobile }
                                            )}
                                        >
                                            <MKTypography fontWeight="bold" className="mt-2">
                                                Geen artiesten gevonden met de opgegeven criteria...
                                            </MKTypography>
                                        </div>
                                    ))}
                                {users.map((user: User) => {
                                    return (
                                        <Fragment key={'user-card-' + user.$id}>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <CKArtistCard user={user} />
                                            </Grid>
                                        </Fragment>
                                    )
                                })}
                            </Grid>
                        </div>
                        <CKPagination
                            onSelect={setPage}
                            info={paginationInfo}
                        />
                    </Container>
                </CKPageBody>
            </CKPageContent>
        </>
    )
}
export default PageArtists
