import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { FC } from 'react'

interface Props {
    onClick: (e) => void
}
const CKElevatedUsersMenuItem: FC<Props> = (props) => {
    return (
        <MenuItem onClick={props.onClick}>
            <ListItemIcon>
                <ManageAccountsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Beheerders</ListItemText>
        </MenuItem>
    )
}
export default CKElevatedUsersMenuItem
