import MKBox from 'components/MaterialKit/MKBox'
import { FC } from 'react'

interface Props {
    children: any
    [rest: string]: any
}
const CKFormBody: FC<Props> = ({ children, ...rest }) => {
    return (
        <MKBox p={4} pt={0} {...rest}>
            {children}
        </MKBox>
    )
}
export default CKFormBody
