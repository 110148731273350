import { createContext, useContext, useReducer } from 'react'
import AuthService from 'services/AuthService'

export const UserContext = createContext(undefined)
export const UserContextDispatch = createContext(undefined)

const userReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                user: action.user, // Set the user data in the state after login
            }
        case 'REFRESH':
            return {
                ...state,
                user: action.user,
            }
        case 'LOGOUT':
            AuthService.logout()
            return null // Clear the user data from the state after logout
        default:
            throw Error('Unknown action in userReducer: ' + action.type)
        // return state;
    }
}

export function UserProvider({ children, defaultUser = undefined }) {
    const [user, dispatchMode] = useReducer(userReducer, {
        user: defaultUser,
    })

    return (
        <UserContext.Provider value={user}>
            <UserContextDispatch.Provider value={dispatchMode}>
                {children}
            </UserContextDispatch.Provider>
        </UserContext.Provider>
    )
}

export function useUserContext() {
    return useContext(UserContext)
}

export function useUserContextDispatch() {
    return useContext(UserContextDispatch)
}
