/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
	All of the routes for the Material Kit 2 React are added here,
	You can add a new route, customize the routes and delete the routes here.

	Once you add a new route on this file it will be visible automatically on
	the Navbar.

	For adding a new route you can follow the existing routes in the routes array.
	1. The `name` key is used for the name of the route on the Navbar.
	2. The `icon` key is used for the icon of the route on the Navbar.
	3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
	inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
	4. The `route` key is used to store the route location which is used for the react router.
	5. The `href` key is used to store the external links location.
	6. The `component` key is used to store the component of its route.
	7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
	8. The `description` key is used to define the description of
					a route under its name.
	9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
					you can set the columns amount based on this key.
	10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui icons
import HomeIcon from '@mui/icons-material/Home'
import PageChatOverview from 'pages/Chat/PageChatOverview'
import PageDashboard from 'pages/Dashboard/PageDashboard'
import PageEventDetail from 'pages/Events/PageEventDetail'
import PageEventEdit from 'pages/Events/PageEventEdit'
import { PageEventTicketScanner } from 'pages/Events/PageEventTicketScanner'
import PageFeatureRequest from 'pages/FeatureRequest'
import Home from 'pages/Home'
import PageLocationDetail from 'pages/Locations/PageLocationDetail'
import PageLocationEdit from 'pages/Locations/PageLocationEdit'
import PageLocationOverview from 'pages/Locations/PageLocationOverview'
import LoginPage from 'pages/Login'
import PageOrganisationDetail from 'pages/Organisations/PageOrganisationDetail'
import PageOrganisationEdit from 'pages/Organisations/PageOrganisationEdit'
import { PageOrganisationMollieOAuthCallback } from 'pages/Organisations/PageOrganisationMollieOAuthCallback'
import PageOrganisationOverview from 'pages/Organisations/PageOrganisationOverview'
import PageArtists from 'pages/PageArtists'
import PagePrivacyPolicy from 'pages/PrivacyPolicy'
import RegisterPage from 'pages/Register'
import PageUserSettings from 'pages/Settings/PageUserSettings'
import PageTermsAndConditions from 'pages/TermsAndConditions'
import PageUserDetail from 'pages/Users/PageUserDetail'
import PageUserEdit from 'pages/Users/PageUserEdit'
import PageWorkshopDetail from 'pages/Workshops/PageWorkshopDetail'
import PageWorkshopEdit from 'pages/Workshops/PageWorkshopEdit'
import PageWorkshopOverview from 'pages/Workshops/PageWorkshopOverview'

const routes = [
    {
        name: (
            <span className="d-flex align-items-center">
                <HomeIcon className="me-1" /> Home
            </span>
        ),
        route: '/',
        component: Home,
    },
    {
        name: 'Dashboard',
        route: '/dashboard',
        component: PageDashboard,
    },
    {
        name: 'Login',
        route: '/login',
        component: LoginPage,
        hide: true,
        fullscreen: false,
    },
    {
        name: 'Register',
        route: '/register',
        component: RegisterPage,
        hide: true,
        fullscreen: false,
    },
    {
        name: 'User Detail',
        route: '/user/:userId',
        component: PageUserDetail,
        hide: true,
    },
    {
        name: 'User Edit',
        route: '/admin/user/:userId',
        component: PageUserEdit,
        hide: true,
    },
    {
        name: 'Location Detail',
        route: '/location/:locationId',
        component: PageLocationDetail,
        hide: true,
    },
    {
        name: 'Location Edit',
        route: '/admin/location/:locationId',
        component: PageLocationEdit,
        hide: true,
    },
    {
        name: 'Event Detail',
        route: '/event/:eventId',
        component: PageEventDetail,
        hide: true,
    },
    {
        name: 'Event Edit',
        route: '/admin/event/:eventId',
        component: PageEventEdit,
        hide: true,
    },
    {
        name: "Event Ticket Scanner",
        route: '/event/:eventSlug/scanner',
        component: PageEventTicketScanner,
        hide: true
    },
    {
        name: 'Organisation Detail',
        route: '/organisation/:organisationId',
        component: PageOrganisationDetail,
        hide: true,
    },
    {
        name: 'Organisation Edit',
        route: '/admin/organisation/:organisationId',
        component: PageOrganisationEdit,
        hide: true,
    },
    {
        name: 'Workshop Detail',
        route: '/workshop/:workshopId',
        component: PageWorkshopDetail,
        hide: true,
    },
    {
        name: 'Workshop Edit',
        route: '/admin/workshop/:workshopId',
        component: PageWorkshopEdit,
        hide: true,
    },
    {
        name: 'Chat Overview',
        route: '/chat',
        component: PageChatOverview,
    },
    {
        name: 'Settings',
        route: '/settings',
        component: PageUserSettings,
        hide: true,
    },
    {
        name: 'Artists',
        route: '/artists',
        component: PageArtists,
    },
    {
        name: 'Locations',
        route: '/locations',
        component: PageLocationOverview,
    },
    {
        name: 'Organisations',
        route: '/organisations',
        component: PageOrganisationOverview,
    },
    {
        name: 'Workshops',
        route: '/workshops',
        component: PageWorkshopOverview,
    },
    {
        name: 'Terms & Conditions',
        route: '/terms-and-conditions',
        component: PageTermsAndConditions,
    },
    {
        name: 'Privacy policy',
        route: '/privacy',
        component: PagePrivacyPolicy,
    },
    {
        name: 'Feature request',
        route: '/feature-request',
        component: PageFeatureRequest,
    },
    {
        name: 'Mollie OAuth Callback',
        route: '/mollie-oauth-callback',
        component: PageOrganisationMollieOAuthCallback
    }
]

export default routes
