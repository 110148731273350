import MKTypography from 'components/MaterialKit/MKTypography'
import { FC } from 'react'

const CKFormDescription: FC<any> = (props) => {
    return (
        <MKTypography
            variant="body2"
            component="div"
            color="text"
            px={4}
            pt={4}
            mb={3}
            style={{ textAlign: props.center ? 'center' : 'left' }}
            {...props}
        >
            {props.children}
        </MKTypography>
    )
}
export default CKFormDescription
