import { Storage } from '@ionic/storage'
import { createContext, useContext, useMemo, useReducer } from 'react'
import { useDarkreader } from 'react-darkreader'

const DarkModeContext = createContext(null)

const DarkModeDispatchContext = createContext(null)
const initialMode = { dark: false }

export function DarkModeProvider({ children, defaultDark = false }) {
    // console.log(defaultDark)
    const [dark, dispatchMode] = useReducer(darkModeReducer, {
        dark: defaultDark,
    })
    const [isDark, { toggle }] = useDarkreader(defaultDark)

    // useMemo(() => {
    // 	if (defaultDark == undefined) return;
    // 	if (defaultDark != isDark) {
    // 		toggle();
    // 	}
    // }, [defaultDark])

    useMemo(() => {
        if (dark === undefined || dark.dark === undefined || !toggle) return

        if (isDark !== dark.dark) {
            toggle()
        }
    }, [dark.dark])

    return (
        <DarkModeContext.Provider value={dark}>
            <DarkModeDispatchContext.Provider value={dispatchMode}>
                {children}
            </DarkModeDispatchContext.Provider>
        </DarkModeContext.Provider>
    )
}

export function useDarkMode() {
    return useContext(DarkModeContext)
}

export function useDarkModeDispatch() {
    return useContext(DarkModeDispatchContext)
}

const updateStore = async (value) => {
    const store = new Storage()
    await store.create()

    await store.set('ck-dark-mode', value ? 'true' : 'false')
}

function darkModeReducer(state = initialMode, action) {
    switch (action.type) {
        case 'toggle': {
            updateStore(action.value)
            return {
                ...state,
                dark: action.value,
            }
        }

        default: {
            throw Error('Unknown action: ' + action.type)
        }
    }
}
