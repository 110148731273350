import { isPlatform } from '@ionic/react'
import DirectionsIcon from '@mui/icons-material/Directions'
import { Popover } from '@mui/material'
import { GoogleMap, Marker } from '@react-google-maps/api'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import MKButton from 'components/MaterialKit/MKButton'
import MKInput from 'components/MaterialKit/MKInput'
import MKTypography from 'components/MaterialKit/MKTypography'
import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'
import './style.scss'

const GoogleMapsComponent = (props) => {
    const [selected, setSelected] = useState(null)
    const [inputValue, setInputValue] = useState(props.defaultValue ? props.defaultValue : '')
    const history = useHistory()
    const [anchorEl, setAnchorEl] = useState()

    const center = useMemo(() => {
        if (!selected) {
            if (props.selected) return props.selected
            return { lat: 50.4991878, lng: 3.8054614 }
        }
        return selected
    }, [selected])

    const [zoom, setZoom] = useState(7)

    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            types: props.types,
            componentRestrictions: { country: 'be' },
        },
    })

    useEffect(() => {
        if (props.selected) {
            setSelected(props.selected)
            setZoom(20)
        }
    }, [])

    const [inputTimeout, setInputTimeout] = useState()

    const extractFromAddress = (address, type) => {
        const addrComps = address.address_components
        for (let comp of addrComps) {
            if (comp.types.includes(type)) {
                return comp['long_name']
            }
        }
    }

    const onLocationSelect = async (address) => {
        setValue(address, false)
        clearSuggestions()
        setInputValue(address)

        const results = await getGeocode({ address })
        const { lat, lng } = await getLatLng(results[0])
        const city = extractFromAddress(results[0], 'locality')
        const street_number = extractFromAddress(results[0], 'street_number')
        const street = extractFromAddress(results[0], 'route')
        const postal_code = extractFromAddress(results[0], 'postal_code')
        const region = extractFromAddress(results[0], 'administrative_area_level_2')

        setSelected({
            lat,
            lng,
            address,
            city,
            postal_code,
            street_number,
            street,
            region,
        })
        setZoom(20)
        props.onSelect({
            lat,
            lng,
            address,
            city,
            postal_code,
            street_number,
            street,
            region,
        })
    }

    const dataFilter = (a) => {
        for (let type of a.types) {
            if (props.types.includes(type)) {
                return true
            }
        }
        return false
    }

    return (
        <div className={props.className}>
            {!props.disabled && inputValue != undefined && (
                <>
                    <MKInput
                        error={props.error}
                        label={props.label && props.label}
                        value={inputValue}
                        placeholder={props.placeholder}
                        onChange={(e) => {
                            setAnchorEl(e.currentTarget)
                            setInputValue(e.target.value)
                            if (!e.target.value || e.target.value.length == 0) {
                                props.onSelect({})
                            }

                            if (inputTimeout) {
                                clearTimeout(inputTimeout)
                                setInputTimeout(undefined)
                            }
                            setInputTimeout(
                                setTimeout(() => {
                                    setValue(e.target.value)
                                }, 500)
                            )
                        }}
                        InputProps={props.InputProps}
                        InputLabelProps={{ shrink: true }}
                        required
                        fullWidth
                    />
                    <CKFormFeedback>{props.error}</CKFormFeedback>

                    <Popover
                        open={Boolean(
                            anchorEl && status == 'OK' && data.filter(dataFilter).length > 0
                        )}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        onClose={(e) => {
                            setAnchorEl(undefined)
                        }}
                        disableAutoFocus={true}
                        disableEnforceFocus={true}
                        transformOrigin={{
                            horizontal: 'left',
                            vertical: 'top',
                        }}
                    >
                        <div className="bg-white">
                            {data.filter(dataFilter).map((result) => {
                                return (
                                    <div key={result.place_id}>
                                        <MKButton
                                            onClick={(e) => {
                                                onLocationSelect(result.description)
                                            }}
                                            variant="text"
                                            color="info"
                                            className="px-2"
                                        >
                                            {result.description}
                                        </MKButton>
                                    </div>
                                )
                            })}
                        </div>
                    </Popover>
                    {/* <div className='mt-3'>

					{status == "OK" &&
					}
				</div> */}
                </>
            )}

            {props.disabled && !props.mapOnly && (
                <div
                    className={'text-center ' + props.to && 'ck-address-btn'}
                    onClick={(e) => {
                        if (props.to) {
                            history.push(props.to)
                        }
                    }}
                >
                    <MKTypography fontWeight="bold" variant="label" color="info">
                        {props.label}
                    </MKTypography>

                    <div className="mt-1">
                        <MKTypography variant="button" fontWeight="bold">
                            {props.location.$name}
                        </MKTypography>
                        <br></br>
                        <MKTypography variant="button">
                            {props.location.$street} {props.location.$streetNumber}
                        </MKTypography>
                        <br></br>
                        <MKTypography variant="button">
                            {props.location.$postalCode} {props.location.$city}
                        </MKTypography>
                        <br></br>
                    </div>
                </div>
            )}

            {props.showNavigate && (
                <MKButton
                    variant="gradient"
                    color="info"
                    className="mt-4 mb-2"
                    onClick={(e) => {
                        e.preventDefault()
                        if (isPlatform('ios')) {
                            window.open(`maps://maps.apple.com/q=${selected.lat},${selected.lng}`)
                        } else if (isPlatform('android')) {
                            window.open(`geo:0,0?q=${selected.lat},${selected.lng}`, '_system')
                        } else {
                            window.open(
                                `https://www.google.com/maps/dir//${inputValue.replace(' ', '+')}/@${selected.lat},${selected.lng},17z`
                            )
                        }
                    }}
                >
                    <DirectionsIcon className="me-2" /> Navigeer
                </MKButton>
            )}

            {!props.hideMap && (
                <div
                    className={props.disabled ? 'mt-3' : 'mt-5'}
                    style={{
                        width: props.disableFullWidth ? '100%' : 'calc(100% + 96px)',
                        marginLeft: props.disableFullWidth ? '0px' : '-48px',
                        height: '400px',
                        borderRadius: '0.65rem',
                    }}
                >
                    <GoogleMap zoom={zoom} center={center} mapContainerClassName="map-container">
                        {selected && <Marker position={selected} />}
                    </GoogleMap>
                </div>
            )}
        </div>
    )
}

const CKAddressPicker = (props) => {
    const [latitude, setLatitude] = useState(
        props.location && props.location.$lat ? props.location.$lat : 50.4991878
    )
    const [longitude, setLongitude] = useState(
        props.location && props.location.$lng ? props.location.$lng : 3.8054614
    )

    return (
        // Important! Always set the container height explicitly
        <>
            <GoogleMapsComponent
                InputProps={props.InputProps}
                types={props.types ? props.types : ['geocode', 'establishment']}
                className={props.className}
                showNavigate={props.showNavigate}
                disabled={props.disabled}
                label={props.label}
                placeholder={props.placeholder}
                error={props.error}
                location={props.location}
                mapOnly={props.mapOnly}
                defaultValue={props.defaultValue ?? props.location?.$fullAddress}
                to={props.to}
                disableFullWidth={props.disableFullWidth}
                hideMap={props.hideMap}
                selected={{ lat: latitude, lng: longitude }}
                onSelect={(result) => {
                    setLatitude(result.lat)
                    setLongitude(result.lng)
                    if (props.onSelect) {
                        props.onSelect(result)
                    }
                }}
            />
        </>
    )
}
export default CKAddressPicker
