import MKTypography from "components/MaterialKit/MKTypography"
import { FC } from "react"
import BeatLoader from "react-spinners/BeatLoader"

export interface ICKLoading {
    text?: string;
}

export const CKLoading: FC<ICKLoading> = ({ text }: ICKLoading) => {
    return (
        <div className="w-100 d-flex justify-content-center align-items-center flex-column py-5 h-100">
            <BeatLoader loading={true} color={'#1A73E8'} />
            <MKTypography fontWeight="bold" className="mt-2">
                {text ?? 'Laden...'}
            </MKTypography>
        </div>
    )
}