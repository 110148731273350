import { App as CapacitorApp } from '@capacitor/app'
import { FC, useEffect } from 'react'

interface Props {}
const MobileTweaks: FC<Props> = () => {
    useEffect(() => {
        CapacitorApp.addListener('backButton', ({ canGoBack }) => {
            if (!canGoBack) {
                CapacitorApp.exitApp()
            } else {
                window.history.back()
            }
        })
    }, [])
    return <></>
}
export default MobileTweaks
