import CKSelect from 'components/Form/CKSelect'
import { useUserContext } from 'context/UserContext'
import Organisation from 'models/Organisation'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useManagedOrganisations } from 'services/UserService'

export interface IOrganisationSelect {
    defaultOrganisation?: Organisation
    onChange?: (organisation: Organisation) => void
}

export const CKOrganisationSelect: React.FC<IOrganisationSelect> = ({
    defaultOrganisation,
    onChange,
}) => {
    const [selectedOrganisation, setSelectedOrganisation] = useState<Organisation | undefined>(
        defaultOrganisation
    )
    const eventId = useParams()['eventId']

    const userContext = useUserContext()

    const {
        organisations: managedOrganisations,
        default: defaultOrganisator,
        hasOrganisations,
    } = useManagedOrganisations()

    const organisatorOptions = [
        { value: 'me', label: 'Jijzelf (' + userContext?.user?.$name + ')' },
        ...managedOrganisations.map((org) => ({
            value: org.$id,
            label: org.$name,
        })),
    ]
    return (
        <>
            {hasOrganisations && defaultOrganisator && (
                <CKSelect
                    options={organisatorOptions}
                    className="w-100"
                    onSelect={(id: string | undefined) => {
                        if (id === 'me') {
                            setSelectedOrganisation(undefined)
                            if (onChange) {
                                onChange(undefined)
                            }
                            return
                        }
                        const e = id
                            ? managedOrganisations.find((org) => org.$id === id)
                            : undefined
                        console.log(e)
                        setSelectedOrganisation(e)
                        if (onChange) {
                            onChange(e)
                        }
                    }}
                    defaultValue={
                        selectedOrganisation
                            ? selectedOrganisation.$id
                            : eventId === 'new' && organisatorOptions.length == 2
                              ? organisatorOptions[1].value
                              : 'me'
                    }
                    label="Organisator"
                />
            )}
        </>
    )
}
