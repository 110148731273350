import { FormGroup } from '@mui/material'
import MKBox from 'components/MaterialKit/MKBox'
import MKInput from 'components/MaterialKit/MKInput'
import MKTypography from 'components/MaterialKit/MKTypography'
import CKModel from 'models/CKModel'
import Social from 'models/Social'
import { memo, useState } from 'react'
import { SocialIcon } from 'react-social-icons'

interface Props {
    model: CKModel
}

const CKSocialsForm = memo(function CKSocialsForm(props: Props) {
    const [socials, setSocials] = useState<Social[]>(
        props.model.$socials ? props.model.$socials : []
    )

    const options = ['Facebook', 'Instagram', 'Twitter']

    const onChange = (platform, link) => {
        const index = socials.findIndex((social) => social.$platform === platform)
        const _socials = socials.slice()

        if (index === -1) {
            _socials.push(new Social(undefined, platform, link))
        } else {
            _socials[index].$link = link
        }
        props.model.$socials = _socials
        setSocials(_socials)
    }

    return (
        <MKBox mb={2}>
            <MKTypography variant="button" color="text">
                Links naar social media
            </MKTypography>
            {options.map((platform) => {
                const social = socials.find((social) => social.$platform === platform)
                return (
                    <FormGroup key={`formgroup-${platform}`} className="my-3">
                        <div className="d-flex align-items-center">
                            <SocialIcon
                                network={platform.toLowerCase()}
                                className="me-2"
                                style={{ height: '2rem', width: '2rem' }}
                            />
                            <MKInput
                                id={`${platform}-input-url`}
                                type="url"
                                label={`${platform} link`}
                                value={social ? social.$link : ''}
                                onChange={(e) => onChange(platform, e.target.value)}
                                fullWidth
                            />
                        </div>
                    </FormGroup>
                )
            })}
        </MKBox>
    )
})
export default CKSocialsForm
