import { Grid } from '@mui/material'
import { FC } from 'react'

interface Props {
    children: any
    [rest: string]: any
}
const CKFormSubtitle: FC<Props> = ({ children, rest }) => {
    return (
        <Grid item xs={12} {...rest}>
            <div className="ck-form-subtitle">{children}</div>
        </Grid>
    )
}
export default CKFormSubtitle
