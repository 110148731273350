import { forwardRef } from 'react'
import MKAlertJS from './MKAlertJS'

const MKAlert = forwardRef((props: any, ref) => {
    return (
        //@ts-ignore
        <MKAlertJS ref={ref} {...props}></MKAlertJS>
    )
})
export default MKAlert
