
import MKBox from 'components/MaterialKit/MKBox'
import MKTypography from 'components/MaterialKit/MKTypography'
import CKModel from 'models/CKModel'
import Event from 'models/Event'
import { FC, useEffect, useState } from 'react'
import CKPagination from '../CKPagination'
import './style.scss'
import { CKEventRow } from '../CKEventRow'

interface Props {
    model: CKModel
    upcomingEventsFunction: (
        slug: string,
        page: number
    ) => Promise<{
        current_page: number
        total: number
        per_page: number
        last_page: number
        to: number
        events: Event[]
    }>
}

const CKEventList: FC<Props> = (props) => {
    const [paginationInfo, setPaginationInfo] = useState<{
        current_page: number
        total: number
        per_page: number
        last_page: number
        to: number
    }>()
    const [events, setEvents] = useState<Event[]>([])

    const loadPage = async (page) => {
        const resp = await props.upcomingEventsFunction(props.model.$slug, page)

        setEvents(resp.events)
        setPaginationInfo(resp)
    }

    useEffect(() => {
        loadPage(1)
    }, [])

    return (
        <>
            <MKBox className="d-flex justify-content-center">
                <MKTypography fontWeight="bold" variant="h5" color="info" className="mb-4">
                    Aankomende Evenementen
                </MKTypography>
            </MKBox>
            <MKBox>
                {events?.length > 0 ? (
                    events.map((event) => {
                        return <CKEventRow event={event} />
                    })
                ) : (
                    <MKTypography>Geen aankomende evenementen</MKTypography>
                )}
            </MKBox>
            <CKPagination info={paginationInfo} onSelect={(page) => loadPage(page)} />
        </>
    )
}
export default CKEventList
