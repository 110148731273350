import Image from 'models/Image'
import AuthService from './AuthService'
import { IMAGES } from './Constants'
interface ImageUploadProps {
    base64: string | ArrayBuffer
    extension: string | undefined
    name?: string | undefined
    description?: string | undefined
    type?: string | undefined
}
const ImageService = {
    upload: async function ({
        base64,
        extension,
        name = undefined,
        description = undefined,
        type = 'LOGO',
    }: ImageUploadProps): Promise<Image> {
        const resp = await AuthService.post(IMAGES, {
            name: name,
            content: base64,
            description: description,
            extension: extension,
            type: type,
        })
        return Image.fromJSON(resp.data)
    },
}
export default ImageService
