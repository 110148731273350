import { Grid, useMediaQuery } from '@mui/material'
import clsx from 'clsx'
import MKButton from 'components/MaterialKit/MKButton'
import { LoupeIcon, MicIcon } from 'components/UI/CKIcons'
import CKSpotEditCard from 'components/UI/CKSpotEditCard'
import Event from 'models/Event'
import EventSpot from 'models/EventSpot'
import React, { FC, useCallback, useState } from 'react'

interface ISpotConfig {
    event: Event
    onUpdate: (spots: EventSpot[]) => void
}

const SpotConfig: FC<ISpotConfig> = (props) => {
    const [enableSpots, setEnableSpots] = useState(props.event.$spots?.length > 0)
    const isMobile = useMediaQuery('(max-width:768px)')
    const [spots, setSpots] = useState<EventSpot[]>(props.event.$spots || [])

    const hasMC = () => {
        return spots.find((a) => a.$type === 'MC') !== undefined
    }

    const deleteSpot = (index: number) => {
        const _spots = [...spots]
        _spots.splice(index, 1)
        setSpots(_spots)
        props.onUpdate(_spots)
    }

    useCallback(() => {
        console.log('update', spots)
        props.onUpdate(spots)
    }, [spots, props])

    return (
        <Grid container spacing={3} className='mb-4'>
            <Grid item className="text-center" xs={12}>
                <MKButton
                    size="small"
                    type="submit"
                    variant="outlined"
                    color="info"
                    onClick={() => {
                        const _spots = [...spots].concat(new EventSpot())
                        setSpots(_spots)
                        props.onUpdate(_spots)
                    }}
                >
                    <div className="d-flex align-items-center justify-content-center">
                        <LoupeIcon className="me-2 ck-edit-icon" fontSize="large" />
                        <p className="m-0 p-0">Spot toevoegen</p>
                    </div>
                </MKButton>

                {!hasMC() && (
                    <MKButton
                        size="small"
                        type="submit"
                        variant="outlined"
                        color="info"
                        className={clsx({ 'ms-3': !isMobile }, { 'mt-3': isMobile })}
                        onClick={() => {
                            const spot = new EventSpot()
                            spot.$type = 'MC'
                            setSpots([spot].concat(spots))
                        }}
                    >
                        <div className="d-flex align-items-center justify-content-center">
                            <MicIcon className="me-2 ck-edit-icon" fontSize="large" />
                            <p className="m-0 p-0">Spot voor MC</p>
                        </div>
                    </MKButton>
                )}
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    {spots &&
                        spots.map((spot, index) => {
                            return (
                                <React.Fragment key={'spot-' + index}>
                                    <Grid item xs={12} justifyContent="center">
                                        <div className="w-100 d-flex justify-content-center">
                                            <CKSpotEditCard
                                                spot={spot}
                                                onDelete={(_spot) => deleteSpot(index)}
                                            />
                                        </div>
                                    </Grid>
                                </React.Fragment>
                            )
                        })}
                </Grid>
            </Grid>
        </Grid>
    )
}
export default SpotConfig
