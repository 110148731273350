import { Grid } from '@mui/material'
import React from 'react'
import { FC, ReactNode } from 'react'

interface Props {
    children: ReactNode
    className?: string
    [rest: string]: any
}

const CKModalFooter: FC<Props> = ({ children, className, ...rest }) => {
    className = (className ?? '') + ' CKModalFooter'

    return (
        <Grid container spacing={3} className={`${className} fixed-bottom pb-3 px-2`} {...rest}>
            {children}
        </Grid>
    )
}

export default CKModalFooter