import { Container } from "@mui/material"
import MKButton from "components/MaterialKit/MKButton"
import MKTypography from "components/MaterialKit/MKTypography"
import { ConnectIcon, DisconnectIcon } from "components/UI/CKIcons"
import Organisation from "models/Organisation"
import { useState } from "react"
import ClipLoader from "react-spinners/ClipLoader"
import { disconnectOrganisationMollie, useOrganisationMollieState } from "services/MollieService"

export interface IMollieConnect {
    organisation: Organisation
}
export const MollieConnect = ({ organisation }: IMollieConnect) => {
    const mollieClientId = process.env.REACT_APP_MOLLIE_CLIENT_ID;
    const redirectUrl = process.env.REACT_APP_MOLLIE_REDIRECT_URL;
    const scopes = ['payments.read', 'payments.write', 'profiles.read'];
    const { state } = useOrganisationMollieState(organisation);
    const authUrl = `https://www.mollie.com/oauth2/authorize?client_id=${mollieClientId}&redirect_uri=${redirectUrl}&response_type=code&scope=${scopes.join('+')}&state=${state}`;

    const [isDeleting, setIsDeleting] = useState(false)


    return (
        <Container className="text-center">
            <MKTypography variant="body2" className="mb-4" color="dark">
                Om uw betalingen veilig en efficiënt te verwerken, maken we gebruik van Mollie. Mollie is een gerenommeerde betalingsdienstaanbieder die ons helpt om betalingen voor uw evenementen te beheren. Door uw Mollie-account te koppelen aan ons platform, kunnen we transacties faciliteren en ervoor zorgen dat uw inkomsten op een betrouwbare manier worden overgemaakt. Uw gegevens en betalingen zijn bij Mollie in veilige handen.
            </MKTypography>

            <div>
                <MKButton
                    variant="gradient"
                    color="info"
                    disabled={organisation.$mollieConnected}
                    onClick={() => {
                        window.location.href = authUrl;
                    }}
                >
                    <ConnectIcon className="me-2" />
                    Verbind je Mollie account
                </MKButton>
            </div>
            {organisation.$mollieConnected && (
                <MKButton
                    variant="gradient"
                    color="error"
                    className="mt-3"
                    disabled={isDeleting}
                    onClick={async () => {
                        setIsDeleting(true)
                        await disconnectOrganisationMollie(organisation)
                        organisation.$mollieConnected = false
                        setIsDeleting(false)
                    }}
                >
                    {isDeleting ? (
                        <p className="mb-0 me-2">
                            <ClipLoader size={15} color={'white'} />
                        </p>
                    ) : (
                        <DisconnectIcon className="me-2" />
                    )}
                    Verbinding met Mollie verbreken
                </MKButton>
            )}

        </Container>
    )
}