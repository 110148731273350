import SearchIcon from '@mui/icons-material/Search'
import { InputAdornment } from '@mui/material'
import MKInput from 'components/MaterialKit/MKInput'
import { useCKSearchParams, useCKSetSearchParams } from 'context/SearchParamsContext'
import { FC, useState } from 'react'

interface Props {
    onSearch: (term: string) => void
    defaultValue?: string
    title?: string
    label?: string
    className?: string
    required?: boolean
    placeholder?: string
    variant?: 'standard' | 'filled' | 'outlined'
}
const CKSearchBar: FC<Props> = (props) => {
    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>()
    const { search } = useCKSearchParams()
    const setSearchParams = useCKSetSearchParams()
    // const [searchParams, setSearchParams] = useSearchParams()
    const [searchTerm, setSearchTerm] = useState(props.defaultValue ? props.defaultValue : search)
    if (search && props.onSearch) {
        props.onSearch(search)
    }


    return (
        <MKInput
            // variant={props.variant ? props.variant : 'standard'}
            title={props.title}
            label={props.label}
            placeholder={props.placeholder ? props.placeholder : 'Zoeken...'}
            value={searchTerm}
            className={props.className}
            required={props.required}
            onChange={(e) => {
                setSearchTerm(e.target.value)
                setSearchParams({ 'search': e.target.value })


                if (searchTimeout) {
                    clearTimeout(searchTimeout)
                    setSearchTimeout(undefined)
                }
                setSearchTimeout(
                    setTimeout(async () => {
                        props.onSearch(e.target.value)
                    }, 500)
                )
            }}
            fullWidth
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon fontSize="medium" />
                    </InputAdornment>
                ),
            }}
        />
    )
}
export default CKSearchBar
