import CloseIcon from '@mui/icons-material/Close'
import { Card, Modal, useMediaQuery } from '@mui/material'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import { FC } from 'react'
import './styles.scss'

interface Props {
    children?: any
    open: boolean
    width?: any
    closeButton?: boolean
    onClose: () => void
    [rest: string]: any
}
const CKModal: FC<Props> = ({ children, width, open, closeButton, onClose, ...rest }) => {
    const isMobile = useMediaQuery('(max-width:768px)')
    return (
        <Modal open={open} onClose={onClose} {...rest}>
            <Card
                className="ck-modal-box"
                sx={{
                    p: 2,
                    mx: { xs: 0, md: 2, lg: 3 },
                    mt: isMobile ? 8 : -8,
                    mb: isMobile ? 0 : -8,
                    width: width ? width : '700px',
                    minHeight: '500px',
                    backgroundColor: ({
                        //@ts-ignore
                        palette: { white },
                        //@ts-ignore
                        functions: { rgba },
                    }) => rgba(white.main, 0.7),
                    backdropFilter: 'saturate(200%) blur(15px)',
                    //@ts-ignore
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                {children}
                {closeButton && (
                    <MKButton className="ck-modal-close-btn" iconOnly onClick={onClose}>
                        <MKTypography variant="button" color="info">
                            <CloseIcon style={{ width: '20px', height: '20px' }} />
                        </MKTypography>
                    </MKButton>
                )}
            </Card>
        </Modal>
    )
}
export default CKModal
