import bgImage from 'assets/images/bgImage.jpg'
import CKCalendar from 'components/Dashboard/CKCalendar'
import CKEventManagement from 'components/Dashboard/CKEventManagement'
import CKLocationManagement from 'components/Dashboard/CKLocationManagement'
import { CKHero } from 'components/UI/CKHero'
import {
    CalendarMonthIcon,
    DashboardIcon,
    DomainAddIcon,
    EventIcon,
    OrganisationIcon,
    TicketIcon,
    WorkshopIcon,
} from 'components/UI/CKIcons'
import { CKPageBadge, CKPageContent, CKPageTitle } from 'components/UI/CKPageContent'
import { CKSidebarTabs } from 'components/UI/CKSidebarTabs'
import { FC } from 'react'
import './style.scss'
import CKOrganisationManagement from 'components/Dashboard/CKOrganisationManagement'
import { CKTicketManagement } from 'components/Dashboard/CKTicketManagement'
import CKWorkshopManagement from 'components/Dashboard/CKWorkshopManagement'
import { Container } from '@mui/material'

interface Props { }
const PageDashboard: FC<Props> = () => {
    const menu = {
        Algemeen: {
            calendar: {
                title: 'Kalender',
                component: <CKCalendar />,
                icon: <CalendarMonthIcon />,
            },
            'my-tickets': {
                title: 'Mijn tickets',
                component: <CKTicketManagement />,
                icon: <TicketIcon />,
            },
        },
        Beheer: {
            events: {
                title: 'Evenementen',
                component: <CKEventManagement />,
                icon: <EventIcon />,
            },
            locations: {
                title: 'Locaties',
                component: <CKLocationManagement />,
                icon: <DomainAddIcon />,
            },
            organisations: {
                title: 'Organisaties',
                component: <CKOrganisationManagement />,
                icon: <OrganisationIcon />,
            },
            workshops: {
                title: 'Workshops',
                component: <CKWorkshopManagement />,
                icon: <WorkshopIcon />,
            },
        },
    }

    return (
        <div className="ck-dashboard">
            <CKHero className="ck-chat-hero" bgImage={bgImage} size="sm" />
            <CKPageContent className="ck-settings-page-content">
                <div className="ck-settings-container">
                    <div className="ck-settings-hero mb-4">
                        <CKPageBadge background="linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))">
                            <DashboardIcon fontSize="large" style={{ color: 'white' }} />
                        </CKPageBadge>
                        <CKPageTitle>Beheer</CKPageTitle>
                    </div>
                    <Container>
                        <CKSidebarTabs sections={menu} hideTitle />
                    </Container>

                </div>
            </CKPageContent>
        </div>
    )
}

export default PageDashboard
