import { CKLoading } from "components/Generic/CKLoading"
import { CKHero } from "components/UI/CKHero"
import { DomainAddIcon } from "components/UI/CKIcons"
import { CKPageBadge, CKPageContent } from "components/UI/CKPageContent"
import bgImage from 'assets/images/bar_sidebar.jpg'
import { FC } from "react"
import Location from "models/Location"

export interface ILocationPageContainer {
    location?: Location
    children: any
    loading: boolean
}

export const LocationPageContainer: FC<ILocationPageContainer> = ({ location, loading, children }) => {
    return (
        <>
            <CKHero size="md" bgImage={location && location.$banner ? location.$banner.$url : bgImage} />
            <CKPageContent>
                {loading ? (
                    <CKLoading />
                ) : (
                    <>
                        {location && location.$logo && location.$logo.$url ? (
                            <CKPageBadge src={location.$logo.$url}></CKPageBadge>
                        ) : (
                            <CKPageBadge>
                                <DomainAddIcon fontSize="large" style={{ color: 'white' }} />
                            </CKPageBadge>
                        )}
                        {children}
                    </>
                )}
            </CKPageContent>
        </>
    )
}