import { forwardRef } from 'react'
import MKPaginationJS from './MKPaginationJS'

const MKPagination = forwardRef((props: any, ref) => {
    return (
        //@ts-ignore
        <MKPaginationJS ref={ref} {...props}></MKPaginationJS>
    )
})
export default MKPagination
