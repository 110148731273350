import { CKLoading } from "components/Generic/CKLoading";
import MKBox from "components/MaterialKit/MKBox";
import MKTypography from "components/MaterialKit/MKTypography";
import { FC, useState } from "react"
import { useMyTickets } from "services/TicketService"
import { EventTicketsCard } from "./EventTicketsCard";
import { Collapse } from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "components/UI/CKIcons";
import MKButton from "components/MaterialKit/MKButton";
import Event from "models/Event";

interface ICKTicketManagement {

}
export const CKTicketManagement: FC<ICKTicketManagement> = () => {
    const { events: upcomingEvents, isLoading: isLoadingUpcomingEvents } = useMyTickets({ filter: 'UPCOMING' });
    const { events: pastEvents, isLoading: isLoadingPastEvents } = useMyTickets({ filter: 'PAST' });
    const { events: thisWeekEvents, isLoading: isLoadingThisWeekEvents } = useMyTickets({ filter: 'THIS_WEEK' });


    const amountTickets: { [key: string]: number } = {}
    const hasTickets = upcomingEvents.length > 0 || pastEvents.length > 0 || thisWeekEvents.length > 0
    const isLoading = isLoadingUpcomingEvents || isLoadingPastEvents || isLoadingThisWeekEvents

    const Title = ({ title, open, toggle }: { title: string, open?: boolean, toggle: () => void }) => (
        <MKBox role="button" className="d-flex pe-auto justify-content-between alignt-items-center" onClick={toggle}>
            <MKTypography fontWeight="bold" variant="h5" color="info" className="m-2 ">
                {title}
            </MKTypography>
            <MKButton className="bg-transparent" iconOnly size="medium"  >
                {open ? <ExpandLessIcon fontSize="large" /> : <ExpandMoreIcon fontSize="large" />}
            </MKButton>
        </MKBox>
    )

    const Tickets = ({ title, events, open: defauLtOpen }: { title: string, events: Event[], open?: boolean }) => {
        const [open, setOpen] = useState(Boolean(defauLtOpen))
        return (
            <div className="mb-3">
                <Title title={title} open={open} toggle={() => setOpen(prev => !prev)} />
                <Collapse in={open}>
                    {events.map((event) => {
                        return (
                            <EventTicketsCard key={event.$id} event={event} />
                        )
                    })}
                </Collapse>
            </div>
        )
    }

    return (
        <div>
            {isLoading && <CKLoading />}
            {!hasTickets && !isLoading && (
                <div className="w-100 d-flex justify-content-center align-items-center flex-column my-5 py-4 h-100">
                    <MKTypography fontWeight="bold" className="mt-2">
                        Geen tickets gevonden...
                    </MKTypography>
                </div>
            )}
            {thisWeekEvents.length > 0 && (
                <Tickets title={"Evenementen deze week"} events={thisWeekEvents} open={true} />
            )}
            {!isLoading && upcomingEvents.length > 0 && (
                <Tickets title={"Aankomende Evenementen"} events={upcomingEvents} open={!thisWeekEvents || thisWeekEvents.length == 0} />
            )
            }
            {!isLoading && pastEvents.length > 0 && (
                <Tickets title={"Afgelopen Evenementen"} events={pastEvents} />
            )}
        </div>
    )
}