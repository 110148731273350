import { FC, useEffect, useMemo, useState } from 'react'
// @mui material components

// Material Kit 2 React components
import MKButton from 'components/MaterialKit/MKButton'
import MKInput from 'components/MaterialKit/MKInput'
import MKTypography from 'components/MaterialKit/MKTypography'

import SendIcon from '@mui/icons-material/Send'
import { Divider, Grid, useMediaQuery } from '@mui/material'
import bgImage from 'assets/images/bgImage.jpg'
import bgImage2 from 'assets/images/bgImage2.jpg'
import { CKForm, CKFormBody, CKFormDescription } from 'components/Form/CKForm'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import MKAlert from 'components/MaterialKit/MKAlert'
import { CKHero } from 'components/UI/CKHero'
import { CKPageBody, CKPageContent } from 'components/UI/CKPageContent'
import CKPagination from 'components/UI/CKPagination'
import { useUserContext } from 'context/UserContext'
import BugReport from 'models/BugReport'
import User from 'models/User'
import ClipLoader from 'react-spinners/ClipLoader'
import BugReportService from 'services/BugReportService'
import BugReportCard from './BugReportCard'
interface Props { }
const PageFeatureRequest: FC<Props> = () => {
    const isMobile = useMediaQuery('(max-width:768px)')
    const userContext = useUserContext()
    const [bugReport, setBugReport] = useState(new BugReport())
    const [saving, setSaving] = useState<boolean>(false)
    const [saveResult, setSaveResult] = useState<string>()
    const [errors, _] = useState<any>({})

    const [reports, setReports] = useState<BugReport[]>()
    const [paginationInfo, setPaginationInfo] = useState<{
        current_page: number
        total: number
        per_page: number
        last_page: number
        to: number
    }>()

    const init = async (page: number = 1) => {
        const resp = await BugReportService.index(page)
        setReports(resp.data)
        setPaginationInfo(resp)
    }

    useEffect(() => {
        init()
    }, [])

    useMemo(() => {
        if (userContext.user) {
            const user: User = userContext.user
            bugReport.$firstName = user.$first_name
            bugReport.$lastName = user.$last_name
        }
    }, [userContext, bugReport])

    const changeField = (key, value) => {
        const report = BugReport.fromJSON(bugReport)
        report[key] = value
        setBugReport(report)
    }

    const save = async () => {
        setSaving(true)

        try {
            await BugReportService.post(bugReport)
            setSaveResult('DONE')
            setBugReport(new BugReport())
        } catch (e) {
            setSaveResult('ERROR')
        }

        setTimeout(() => {
            setSaveResult(undefined)
        }, 5000)

        setSaving(false)
    }
    const renderForm = () => {
        return (
            <CKForm className="mt-2">
                <CKFormDescription className="text-center">
                    Bij ComedyKit verwelkomen we uw ideeën en feedback om ons platform te
                    verbeteren! Of u nu nieuwe functies voorstelt of problemen rapporteert, uw
                    bijdrage is van onschatbare waarde. Dank u voor het helpen bij het nog beter
                    maken van ComedyKit!
                </CKFormDescription>
                <CKFormBody className="mt-4">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <MKInput
                                variant="standard"
                                label="Voornaam"
                                error={errors['name'] ? true : false}
                                title="Voornaam"
                                value={bugReport.$firstName || ''}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    changeField('first_name', e.target.value)
                                }}
                                required
                                fullWidth
                            />
                            <CKFormFeedback>{errors['name']}</CKFormFeedback>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MKInput
                                variant="standard"
                                label="Achternaam"
                                error={errors['lastName'] ? true : false}
                                title="Achternaam"
                                value={bugReport.$lastName || ''}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    changeField('last_name', e.target.value)
                                }}
                                required
                                fullWidth
                            />
                            <CKFormFeedback>{errors['lastName']}</CKFormFeedback>
                        </Grid>
                        <Grid item xs={12}>
                            <MKInput
                                variant="standard"
                                label="Korte beschrijving"
                                error={errors['shortDescription'] ? true : false}
                                title="Korte beschrijving"
                                value={bugReport.$shortDescription || ''}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    if (e.target.value.length <= 100) {
                                        changeField('short_description', e.target.value)
                                    }
                                }}
                                required
                                fullWidth
                            />
                            <MKTypography
                                variant="caption"
                                className="text-end w-100 mt-1"
                                component="p"
                            >
                                {bugReport.$shortDescription
                                    ? bugReport.$shortDescription.length
                                    : 0}{' '}
                                / 100
                            </MKTypography>
                            <CKFormFeedback>{errors['shortDescription']}</CKFormFeedback>
                        </Grid>
                        <Grid item xs={12}>
                            <MKInput
                                type="standard"
                                multiline
                                value={bugReport.$description || ''}
                                placeholder="Geef een volledige beschrijving van uw verzoek of probleem..."
                                variant="standard"
                                title="Beschrijving"
                                label="Beschrijving"
                                onChange={(e) => {
                                    if (e.target.value.length <= 2500) {
                                        changeField('description', e.target.value)
                                    }
                                }}
                                InputLabelProps={{ shrink: true }}
                                rows={6}
                                fullWidth
                                required
                            />
                            <MKTypography
                                variant="caption"
                                className="text-end w-100 mt-1"
                                component="p"
                            >
                                {bugReport.$description ? bugReport.$description.length : 0} / 2500
                            </MKTypography>
                            <CKFormFeedback>{errors['shortDescription']}</CKFormFeedback>
                        </Grid>
                        <Grid container item justifyContent="end" xs={12} mt={1} mb={2}>
                            <MKButton
                                type="submit"
                                variant="gradient"
                                color="info"
                                disabled={
                                    !bugReport.$firstName ||
                                    !bugReport.$shortDescription ||
                                    !bugReport.$description ||
                                    !bugReport.$lastName
                                }
                                onClick={() => {
                                    save()
                                }}
                            >
                                <SendIcon className="me-2" />
                                {saving ? <ClipLoader size={15} color={'white'} /> : 'Indienen!'}
                            </MKButton>
                        </Grid>
                    </Grid>
                    {Boolean(saveResult) && (
                        <MKAlert
                            color={saveResult === 'DONE' ? 'success' : 'error'}
                            className="justify-content-center"
                            dismissible
                        >
                            {saveResult === 'DONE'
                                ? 'Je aanvraag is succesvol ingediend en wordt nu gevalideerd door het ComedyKit team!'
                                : 'Er ging iets mis bij het indienen van jouw aanvraag...'}
                        </MKAlert>
                    )}
                </CKFormBody>
            </CKForm>
        )
    }

    return (
        <>
            <CKHero
                bgImage={isMobile ? bgImage2 : bgImage}
                title={'Vraag een functie aan / meld een fout'}
                size="md"
                maxHeight={'500px'}
                height={'30vh'}
            />
            <CKPageContent>
                <CKPageBody>
                    {renderForm()}
                    <Divider orientation="horizontal" sx={{ mt: 1 }} />
                    {reports &&
                        reports.map((report) => {
                            return (
                                <BugReportCard key={'bug-report-' + report.$id} report={report} />
                            )
                        })}
                    <CKPagination info={paginationInfo} onSelect={(page) => init(page)} />
                </CKPageBody>
            </CKPageContent>
        </>
    )
}
export default PageFeatureRequest
