/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// Custom styles for MKButton
import MKButtonRoot from 'components/MaterialKit/MKButton/MKButtonRoot'

const MKButtonJS = forwardRef(
    ({ color, variant, size, circular, iconOnly, children, ...rest }, ref) => (
        <MKButtonRoot
            {...rest}
            ref={ref}
            color="primary"
            variant={variant === 'gradient' ? 'contained' : variant}
            size={size}
            ownerState={{ color, variant, size, circular, iconOnly }}
        >
            {children}
        </MKButtonRoot>
    )
)

// Setting default values for the props of MKButtonJS
MKButtonJS.defaultProps = {
    size: 'medium',
    variant: 'contained',
    color: 'white',
    circular: false,
    iconOnly: false,
}

// Typechecking props for the MKButtonJS
MKButtonJS.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    variant: PropTypes.oneOf(['text', 'contained', 'outlined', 'gradient']),
    color: PropTypes.oneOf([
        'default',
        'white',
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'light',
        'dark',
    ]),
    circular: PropTypes.bool,
    iconOnly: PropTypes.bool,
    children: PropTypes.node.isRequired,
}

export default MKButtonJS
