const PasswordUtils = {
    isStrongPassword: function (password: string): boolean {
        // Check if the password has at least 8 characters
        if (password.length < 8) {
            return false
        }

        // Check if the password contains at least 1 lowercase letter
        if (!/[a-z]/.test(password)) {
            return false
        }

        // Check if the password contains at least 1 uppercase letter
        if (!/[A-Z]/.test(password)) {
            return false
        }

        // Check if the password contains at least 1 number
        if (!/\d/.test(password)) {
            return false
        }

        // If all conditions are met, the password is strong
        return true
    },
}
export default PasswordUtils
