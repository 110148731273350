import ComedyTag from 'models/ComedyTag'
import AuthService from './AuthService'
import { COMEDY_TAGS } from './Constants'

const ComedyTagService = {
    getAll: async function (): Promise<ComedyTag[]> {
        const resp = await AuthService.get(COMEDY_TAGS)

        const comedyTags = []
        for (const comedyTag of resp.data) {
            comedyTags.push(ComedyTag.fromJSON(comedyTag))
        }

        return comedyTags
    },
}
export default ComedyTagService
