import MKTypography from 'components/MaterialKit/MKTypography'
import { FC } from 'react'

interface Props {
    children: any
    mb?: number
}
const CKPageTitle: FC<Props> = (props) => {
    return (
        <MKTypography
            variant="h2"
            color="info"
            mt={3}
            mb={props.mb}
            className="text-center ck-page-title"
            textGradient
        >
            {props.children}
        </MKTypography>
    )
}
export default CKPageTitle
