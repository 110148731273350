import { forwardRef } from 'react'
import MKBadgeJS from './MKBadgeJS'

const MKBadge = forwardRef((props: any, ref) => {
    return (
        //@ts-ignore
        <MKBadgeJS ref={ref} {...props}></MKBadgeJS>
    )
})
export default MKBadge
