import StoreIcon from '@mui/icons-material/Store'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import CKSearchBar from 'components/Form/CKSearchBar'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import { CKLocationDetailCard } from 'components/UI/CKLocationCard'
import Location from 'models/Location'
import { FC,  useState } from 'react'
import LocationService from 'services/LocationService'

interface Props {
    defaultValue?: Location
    onSelect?: (location: Location) => void
    onInputChange?: (value: string) => void
    label?: string
    [rest: string]: any
}
const CKLocationPicker: FC<Props> = (props) => {
    const [selected, setSelected] = useState<Location>(props.defaultValue)
    const [searchTerm, setSearchTerm] = useState(props.defaultValue ? props.defaultValue.$name : '')
    const [locationOptions, setLocationOptions] = useState<Location[]>()

    return (
        <>
            <CKSearchBar
                variant="outlined"
                onSearch={async (e) => {
                    try {
                        const resp = await LocationService.userLocations(e, 1)

                        setLocationOptions(resp)
                    } catch (e) {
                        console.error(e)
                    }
                }}
                defaultValue={searchTerm}
                label={props.label ? props.label : 'Locatie'}
            />
            <CKFormFeedback>{props.error}</CKFormFeedback>

            {locationOptions &&
                locationOptions.map((location, index) => {
                    if (selected && location.$id === selected.$id) return
                    const icon =
                        location.$logo && location.$logo.$url ? (
                            <img src={location.$logo.$url} width={50} className="me-2" />
                        ) : (
                            <StoreIcon fontSize={'large'} className="me-2" />
                        )
                    return (
                        <div key={location.$id}>
                            <MKButton
                                onClick={(e) => {
                                    if (props.onSelect) {
                                        props.onSelect(location)
                                    }
                                    setSelected(location)
                                    setSearchTerm('')
                                    setLocationOptions([])
                                }}
                                variant="text"
                                color="info"
                                className="px-2"
                            >
                                {icon}
                                {location.$name}
                            </MKButton>
                        </div>
                    )
                })}
            {selected && (
                <div className="my-3">
                    <MKTypography variant="h6" color="dark" className="mb-2">
                        Huidige selectie:
                    </MKTypography>
                    <CKLocationDetailCard location={selected} hideNavigateButton />
                </div>
            )}
        </>
    )
}
export default CKLocationPicker
