import { Container, useMediaQuery } from '@mui/material'
import bgImage from 'assets/images/bgImage.jpg'
import bgImage2 from 'assets/images/bgImage2.jpg'
import MKTypography from 'components/MaterialKit/MKTypography'
import { CKHero } from 'components/UI/CKHero'
import { CKPageBody, CKPageContent } from 'components/UI/CKPageContent'
import { FC } from 'react'

interface Props {}
const PagePrivacyPolicy: FC<Props> = () => {
    const isMobile = useMediaQuery('(max-width:768px)')

    return (
        <>
            <CKHero
                bgImage={isMobile ? bgImage2 : bgImage}
                title={'Privacybeleid'}
                size="md"
                maxHeight={'500px'}
                height={'30vh'}
            />
            <CKPageContent>
                <CKPageBody>
                    <Container>
                        <MKTypography variant="body2" className=" text-center mt-4">
                            Laatst bijgewerkt op 01/10/2023 <br></br>
                            <br></br>
                        </MKTypography>
                        <div className="text-center my-3">
                            Dit Privacybeleid beschrijft hoe ComedyKit ("wij", "ons" of "onze")
                            persoonlijke gegevens verzamelt, gebruikt, beschermt en deelt in verband
                            met uw gebruik van het ComedyKit Open Mic Comedy Platform (het
                            "Platform"). Door het Platform te gebruiken, gaat u akkoord met de
                            voorwaarden van dit Privacybeleid.
                        </div>
                        <MKTypography variant="body2" fontWeight="bold">
                            Verzameling van Persoonlijke Gegevens
                        </MKTypography>
                        Wij verzamelen persoonlijke gegevens die u vrijwillig aan ons verstrekt bij
                        het registreren van een account, het aanmaken van een gebruikersprofiel en
                        het gebruik van het Platform. Deze gegevens kunnen onder andere uw naam,
                        geboortedatum, e-mailadres, profielinformatie en andere gegevens omvatten
                        die u verstrekt.
                        <MKTypography variant="body2" fontWeight="bold" className="mt-3">
                            Gebruik van Persoonlijke Gegevens
                        </MKTypography>
                        Wij gebruiken uw persoonlijke gegevens voor de volgende doeleinden:
                        <ul className="my-2">
                            <li>
                                Het beheren van uw account en gebruikersprofiel op het Platform.
                            </li>
                            <li>Het bieden van functionaliteiten en diensten op het Platform.</li>
                            <li>
                                Het communiceren met u, inclusief het beantwoorden van vragen en het
                                verstrekken van informatie.
                            </li>
                            <li>
                                Het handhaven van de veiligheid en integriteit van het Platform.
                            </li>
                            <li>Het voldoen aan wettelijke verplichtingen.</li>
                        </ul>
                        <MKTypography variant="body2" fontWeight="bold" className="mt-3">
                            Delen van Persoonlijke Gegevens
                        </MKTypography>
                        Wij delen uw persoonlijke gegevens alleen met derden in overeenstemming met
                        de toepasselijke wetten en regelgeving. We kunnen uw gegevens delen met:
                        <ul className="my-2">
                            <li>
                                Serviceproviders die diensten verlenen namens ons, zoals
                                hostingdiensten of klantenservice.
                            </li>
                            <li>
                                Wetshandhavingsinstanties of andere overheidsinstanties indien
                                vereist door de wet.
                            </li>
                        </ul>
                        <MKTypography variant="body2" fontWeight="bold" className="mt-3">
                            Beveiliging van Persoonlijke Gegevens
                        </MKTypography>
                        Wij nemen redelijke maatregelen om uw persoonlijke gegevens te beschermen
                        tegen ongeoorloofde toegang, gebruik of openbaarmaking. We gebruiken
                        beveiligingsmaatregelen zoals versleuteling en beperkte toegangscontrole tot
                        uw gegevens.
                        <MKTypography variant="body2" fontWeight="bold" className="mt-3">
                            Uw Rechten en Keuzes
                        </MKTypography>
                        U heeft bepaalde rechten met betrekking tot uw persoonlijke gegevens,
                        waaronder het recht om uw gegevens bij te werken, toegang te krijgen tot uw
                        gegevens en uw gegevens te verwijderen. U kunt deze rechten uitoefenen door
                        contact met ons op te nemen via info@comedykit.be.
                        <MKTypography variant="body2" fontWeight="bold" className="mt-3">
                            Wijzigingen in het Privacybeleid
                        </MKTypography>
                        Dit Privacybeleid kan van tijd tot tijd worden bijgewerkt om te voldoen aan
                        wetswijzigingen of wijzigingen in onze praktijken. Het is uw
                        verantwoordelijkheid om regelmatig dit Privacybeleid te controleren op
                        updates.
                        <br></br>
                        <br></br>
                        Neem voor vragen of opmerkingen over dit Privacybeleid contact met ons op
                        via info@comedykit.be. Bedankt voor uw vertrouwen in ComedyKit en voor uw
                        deelname aan ons Platform.
                    </Container>
                </CKPageBody>
            </CKPageContent>
        </>
    )
}
export default PagePrivacyPolicy
