export default class Social {
    private id: number | undefined
    private platform: string
    private link: string

    constructor(id: number | undefined = undefined, platform: string = '', link: string = '') {
        this.id = id
        this.platform = platform
        this.link = link
    }

    public static fromJSON(json: any): Social {
        let result = new Social()
        result = Object.assign(result, json)

        return result
    }

    public get $platform(): string {
        return this.platform
    }
    public set $platform(value: string) {
        this.platform = value
    }

    public get $link(): string {
        return this.link
    }
    public set $link(value: string) {
        this.link = value
    }
}
