import { Device } from '@capacitor/device'
import { isPlatform } from '@ionic/react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import User from 'models/User'
import useSWR from 'swr'
import { AUTH, ME, USERS } from './Constants'

const JWT_TOKEN = 'ck_token'
const JWT_TOKEN_EXP = JWT_TOKEN + '_expiration'
const BASE_URL = process.env.REACT_APP_API_URL

const filterParams = (params = {}) => {
    const asArray = Object.entries(params)
    const filtered = asArray.filter(([_, value]) => {
        return Boolean(value)
    })
    return Object.fromEntries(filtered)
}

export const addSearchParams = (path, params) => {
    const url = new URL(path, window.location.origin)

    Object.keys(params).forEach((key) => {
        if(params[key] === null) return
        if(params[key] === undefined) return
        if(params[key] === '') return
        url.searchParams.set(key, params[key])
    })

    return url.toString().replace(window.location.origin + '/', '')
}

export const ckFetcher = async (url: string, params = {}) => {
    const response = await axios
        .get(BASE_URL + `${url}`, {
            params: filterParams(params),
            headers: { Authorization: AuthService._getJWT() },
        })
        .then((res) => res.data)
    return response
}

export const useSWRPaginated = (url: string) => {
    const { data, ...swrProps } = useSWR(url, ckFetcher)

    return {
        ...swrProps,
        data: data?.data,
        paginationInfo: {
            current_page: data?.current_page,
            total: data?.total,
            per_page: data?.per_page,
            last_page: data?.last_page,
            to: data?.to,
            links: data?.links,
        },
    }
}

const AuthService = {
    /**
     * Do a get request that is authenticated.
     * @param {string} path The path for the request tot the API
     * @throws error if the http response was incorrect.
     */
    get: async function (path: string, params = {}): Promise<any> {
        const response = await axios.get(BASE_URL + `${path}`, {
            params: filterParams(params),
            headers: { Authorization: this._getJWT() },
        })
        return response
        // const paginatedResponse = PaginationService._handlePagination(response);

        // return paginatedResponse;
    },

    /**
     * Do a get request that is authenticated.
     * @param {string} path The path for the request tot the API
     * @throws error if the http response was incorrect.
     */
    getUrl: async function (path: string): Promise<any> {
        const response = await axios.get(path, {
            headers: { Authorization: this._getJWT() },
        })
        return response
        // const paginatedResponse = PaginationService._handlePagination(response);

        // return paginatedResponse;
    },

    /**
     * Send an authenticated put request.
     * @param {string} path The path for the request tot the API
     * @param payload The payload for the put request.
     * @throws error if the http response was not 200/201
     */
    put: async function (path: string, payload: any): Promise<any> {
        const response = await axios.put(BASE_URL + `${path}`, payload, {
            headers: {
                Authorization: await this._getJWT(),
            },
        })

        return response
    },

    /**
     * Send an authenticated post request.
     * @param {string} path The path for the request tot the API
     * @param payload The payload for the put request.
     * @throws error if the http response was not 200/201
     */
    post: async function (path: string, payload: any, download: boolean = false): Promise<any> {
        const extraData = {}
        extraData['headers'] = {
            Authorization: await this._getJWT(),
        }
        if (download) {
            extraData['responseType'] = 'blob'
        }
        const response = await axios.post(BASE_URL + `${path}`, payload, extraData)

        return response
    },

    /**
     * Send an authenticated patch request.
     * @param {string} path The path for the request tot the API
     * @param payload The payload for the put request.
     * @throws error if the http response was not 200/201
     */
    patch: async function (path: string, payload: any): Promise<any> {
        const response = await axios.patch(BASE_URL + `${path}`, payload, {
            headers: {
                Authorization: await this._getJWT(),
            },
        })

        return response
    },

    /**
     * Do a delete request that is authenticated.
     * @param {string} path The path for the request tot the API
     * @throws error if the http response was incorrect.
     */
    delete: async function (path: string): Promise<any> {
        const response = await axios.delete(BASE_URL + `${path}`, {
            headers: { Authorization: this._getJWT() },
        })

        return response
    },

    logout: async function () {
        if (isPlatform('android') || isPlatform('ios')) {
            try {
                await this.post(`${AUTH}/logout`, {
                    device_id: (await Device.getId()).identifier,
                })
            } catch (error) {
                console.error(error)
            }
        }

        localStorage.removeItem(JWT_TOKEN)
        localStorage.removeItem(JWT_TOKEN_EXP)
    },

    login: async function (email: string, password: string): Promise<object> {
        const payload = { email: email, password: password }
        if (isPlatform('android') || isPlatform('ios')) {
            payload['device_id'] = (await Device.getId()).identifier
        }

        const response = await axios.post(BASE_URL + `${AUTH}/login`, payload)

        this._setToken(response.data['access_token'])

        return response.data['user']
    },

    hasJWT: function (): boolean {
        return localStorage.getItem(JWT_TOKEN) !== null
    },

    refreshToken: async function (): Promise<User> {
        const response = await this.post(`${AUTH}/refresh`, {})

        this._setToken(response.data['access_token'])
        return User.fromJSON(response.data['user'])
    },

    _getJWT: function (): string {
        const token = localStorage.getItem(JWT_TOKEN)

        if (this._isValidJWT() && token) {
            return 'Bearer ' + token
        } else {
            return ''
        }
    },

    _isValidJWT: function (): boolean {
        const exp = parseInt(localStorage.getItem(JWT_TOKEN_EXP))
        const now = Math.round(Date.now() / 1000) // Epoch milisecs to secs

        if (now > exp) {
            AuthService.removeTokens()
            return false
        }

        return true
    },

    _setToken: function (token): void {
        const decoded = jwt_decode(token)

        localStorage.setItem(JWT_TOKEN, token)
        localStorage.setItem(JWT_TOKEN_EXP, decoded['exp'])
    },

    registerUser: async function (
        user: User,
        password: string,
        picture_id: string | null = null
    ): Promise<object> {
        const payload = {
            first_name: user.$first_name,
            last_name: user.$last_name,
            birthdate: user.$birthdate,
            email: user.$email,
            password: password,
            picture_id: picture_id,
            started_at: user.$started_at,
            description: user.$description,
            mc: user.$isMC,
            organizer: user.$isOrganizer,
            openmicer: user.$isOpenMicer,
            socials: user.$socials,
        }
        const response = await axios.post(BASE_URL + `${AUTH}/register`, payload)
        return response.data.data
    },
    getMe: async function (): Promise<User> {
        const response = await axios.get(BASE_URL + `${USERS}/${ME}`, {
            headers: { Authorization: this._getJWT() },
        })

        return User.fromJSON(response.data.data)
    },
    forgotPassword: async function (email: string): Promise<void> {
        const payload = { email: email }
        try {
            const response = await axios.post(BASE_URL + `${AUTH}/forgot-password`, payload)

            return response.data
        } catch (error) {
            throw new Error(error.message)
        }
    },
    resetPassword: async function (passwordResetCode: string, password: string): Promise<any> {
        const payload = {
            resetCode: passwordResetCode,
            password: password,
        }
        const response = await axios.post(BASE_URL + `${AUTH}/reset-password`, payload)

        return response
    },
    versions: async function (): Promise<void> {
        const payload = {}
        try {
            const response = await axios.get(BASE_URL + 'versions', payload)

            return response.data
        } catch (error) {
            throw new Error(error.message)
        }
    },
    removeTokens: function () {
        localStorage.removeItem(JWT_TOKEN_EXP)
        localStorage.removeItem(JWT_TOKEN)
    },
}

export default AuthService
