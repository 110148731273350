import { useWindowScroll } from '@uidotdev/usehooks'
import { FC, useMemo, useRef } from 'react'
import { useIsMobile } from 'utils/ViewportUtils'
import './style.scss'

export interface IFloatingContainer {
    children: React.ReactNode
    className?: string
    style?: React.CSSProperties
    dropback?: boolean
}
export function scrollPercentage() {
    const h = document.documentElement,
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight'

    return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100
}

export const CKFloatingContainer: FC<IFloatingContainer> = ({
    children,
    className,
    style,
    dropback,
}) => {
    const [{ y }] = useWindowScroll()
    const containerRef = useRef<HTMLDivElement>(null)
    const isMobile = useIsMobile()

    useMemo(() => {
        if (isMobile) return
        const scroll = scrollPercentage()
        if (scroll > 65) {
            // Hide the button
            containerRef.current?.style.setProperty('opacity', '0')
        } else {
            // Show the button
            containerRef.current?.style.setProperty('opacity', '1')
        }
    }, [y])

    return (
        <div
            className={`ck-floating-container ${dropback && 'ck-floating-container-dropback'} ${className}`}
            style={style}
            ref={containerRef}
        >
            {children}
        </div>
    )
}
