import BugReport from 'models/BugReport'
import AuthService from './AuthService'
import { BUG_REPORTS } from './Constants'

const BugReportService = {
    post: async function (report: BugReport) {
        const resp = await AuthService.post(`${BUG_REPORTS}`, report)
        return resp.data.data
    },
    index: async function (page: number = 1) {
        const resp = await AuthService.get(BUG_REPORTS, { page: page })
        const result: BugReport[] = []

        for (const json of resp.data.data) {
            result.push(BugReport.fromJSON(json))
        }

        resp.data.data = result
        return resp.data
    },
    upvote: async function (report: BugReport) {
        const resp = await AuthService.post(`${BUG_REPORTS}/${report.$id}/upvote`, {})
        return resp
    },
    downvote: async function (report: BugReport) {
        const resp = await AuthService.post(`${BUG_REPORTS}/${report.$id}/downvote`, {})
        return resp
    },
}
export default BugReportService
