import EditIcon from '@mui/icons-material/Edit'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import MKButton from 'components/MaterialKit/MKButton'
import CKModel from 'models/CKModel'
import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import './style.scss'

interface Props {
    model: CKModel
    label?: string
    className?: string
    menuItem?: boolean
    [rest: string]: any
}
const CKEditModelButton: FC<Props> = ({ model, label, className, menuItem, ...rest }) => {
    className = (className && className) + ' ck-edit-button'
    const history = useHistory()
    return (
        <>
            {model.hasRight('FULL') && (
                <>
                    {menuItem ? (
                        <MenuItem
                            onClick={(e) => {
                                history.push(model.$adminUrl)
                            }}
                        >
                            <ListItemIcon>
                                <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Aanpassen</ListItemText>
                        </MenuItem>
                    ) : (
                        <MKButton
                            {...rest}
                            variant="gradient"
                            color="info"
                            // size="small"
                            className={className}
                            onClick={(e) => {
                                history.push(model.$adminUrl)
                            }}
                        >
                            <div className="d-flex align-items-center justify-content-center">
                                <EditIcon className="me-2 ck-edit-icon" fontSize="large" />
                                <p className="m-0 p-0">Aanpassen</p>
                            </div>
                        </MKButton>
                    )}
                </>
            )}
        </>
    )
}
export default CKEditModelButton
