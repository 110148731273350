import { forwardRef } from 'react'
import MKBoxJS from './MKBoxJS'

const MKBox = forwardRef((props: any, ref) => {
    return (
        //@ts-ignore
        <MKBoxJS ref={ref} {...props}></MKBoxJS>
    )
})
export default MKBox
