import CKModel from './CKModel'
import Image from './Image'
import Social from './Social'

export default class Organisation extends CKModel {
    private description: string | undefined

    private logo: Image | undefined
    private banner: Image | undefined

    private banner_id: string | undefined
    private logo_id: string | undefined

    private mollie_connected: boolean | undefined

    constructor(
        id: string | undefined = undefined,
        slug: string | undefined = undefined,
        name: string | undefined = undefined,
        description: string | undefined = undefined,
        logo: Image | undefined = undefined,
        banner: Image | undefined = undefined,
        socials: Social[] = []
    ) {
        super(id, slug, socials)

        this.name = name
        this.description = description
        this.logo = logo
        this.banner = banner
    }

    public static fromJSON(json: any) {
        let result = new Organisation()
        result = Object.assign(result, json)

        if (json.logo) {
            result.$logo = Image.fromJSON(json.logo)
        }

        if (json.banner) {
            result.$banner = Image.fromJSON(json.banner)
        }

        if (json.socials) {
            const socials: Social[] = []
            for (const social of json.socials) {
                socials.push(Social.fromJSON(social))
            }
            result.$socials = socials
        }

        return result
    }

    public get $description(): string | undefined {
        return this.description
    }
    public set $description(value: string | undefined) {
        this.description = value
    }

    public get $logo(): Image | undefined {
        return this.logo
    }
    public set $logo(value: Image | undefined) {
        this.logo = value
        this.logo_id = value.$id
    }

    public get $banner(): Image | undefined {
        return this.banner
    }
    public set $banner(value: Image | undefined) {
        this.banner = value
        this.banner_id = value.$id
    }

    public get $className(): string {
        return 'organisation'
    }

    public get $mollieConnected(): boolean {
        return Boolean(this.mollie_connected)
    }

    public set $mollieConnected(value: boolean) {
        this.mollie_connected = value
    }
}
