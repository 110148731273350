import { Divider, List, ListItem, ListItemText, Switch } from '@mui/material'
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import { FC, Fragment, useEffect, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import DeviceService from 'services/DeviceService'
import UserService from 'services/UserService'
import './../style.scss'
interface Props {}

const MobileNotificationSettings: FC<Props> = () => {
    const [settings, setSettings] = useState<any>({})
    const [saving, setSaving] = useState<boolean>(false)

    const [options, setOptions] = useState({
        chat: {
            primaryText: 'Chat',
            secondaryText: 'Nieuwe chatberichten',
        },
        lineup: {
            primaryText: 'Line-up',
            secondaryText:
                'Wijzigingen van jouw spots (confirmatie - annulering - tijdsduur veranderd)',
        },
        event_changes: {
            primaryText: 'Evenement wijziging',
            secondaryText: 'Wijzigingen in het evenement (tijdstippen - locatie)',
        },
        event_near: {
            primaryText: 'Evenement in de buurt',
            secondaryText: 'Krijg een mail als er een evenemnt in jouw buurt is',
            disabled: false,
            disabledText: 'Je moet eerst een adres instellen voor we je een melding kunnen sturen',
        },
        event_reminder: {
            primaryText: 'Evenement herinnering',
            secondaryText: 'Reminder 24u voordat een evenement begint',
        },
    })

    useEffect(() => {
        (async () => {
            try {
                const response = await DeviceService.getPreferences()
                setSettings(JSON.parse(response.data))

                const addr = await UserService.getMyAddress()
                console.log(addr)
                if (!addr) {
                    const _options = Object.assign({}, options)
                    _options['event_near']['disabled'] = true
                    setOptions(_options)
                }
            } catch (err) {
                console.log(err)
            }
        })()
    }, [options])

    const save = async () => {
        setSaving(true)

        try {
            await DeviceService.updatePreferences(settings)
        } catch (e) {
            console.error(e)
        }

        setSaving(false)
    }

    const toggleSetting = (option) => {
        const _settings = Object.assign({}, settings)
        _settings[option] = _settings.hasOwnProperty(option) ? !_settings[option] : false
        setSettings(_settings)
    }

    const notificationOption = (option, isLastElement) => {
        return (
            <>
                <ListItem
                    secondaryAction={
                        <Switch
                            id={`notification-mobile-option-${option}`}
                            key={`notification-email-option-${option}`}
                            color="primary"
                            checked={settings.hasOwnProperty(option) ? settings[option] : true}
                            onChange={() => toggleSetting(option)}
                        />
                    }
                >
                    <ListItemText
                        primary={options[option]['primaryText']}
                        secondary={options[option]['secondaryText']}
                        primaryTypographyProps={{
                            fontSize: 'h6.fontSize',
                            fontWeight: 'bold',
                        }}
                        secondaryTypographyProps={{
                            fontSize: '14px',
                        }}
                    />
                </ListItem>
                {options[option]['disabled'] && options[option]['disabledText'] && (
                    <MKTypography variant="caption" color="error">
                        {options[option]['disabledText']}
                    </MKTypography>
                )}
                {!isLastElement && <Divider />}
            </>
        )
    }

    return (
        <div className="">
            <List>
                {Object.keys(options).map((option, index) => {
                    const isLastElement = index + 1 === Object.keys(options).length
                    return (
                        <Fragment key={`notification-${option}`}>
                            {notificationOption(option, isLastElement)}
                        </Fragment>
                    )
                })}
            </List>
            <MKBox mt={4} mb={1}>
                <MKButton
                    variant="gradient"
                    color="info"
                    onClick={save}
                    fullWidth
                    disabled={saving}
                >
                    {saving ? <ClipLoader size={15} color={'white'} /> : 'Opslaan'}
                </MKButton>
            </MKBox>
        </div>
    )
}
export default MobileNotificationSettings
