import AuthService from './AuthService'
import { STATISTICS } from './Constants'

const MetaTagService = {
    get: async function (path: string) {
        const resp = await AuthService.get(`meta?path=${btoa(path)}`)
        return resp.data
    },
    statistics: async function () {
        const resp = await AuthService.get(STATISTICS)
        return resp.data
    },
}
export default MetaTagService
