import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom' // Assuming you're using React Router

export const useCKSearchParams = () => {
    const location = useLocation()
    const [searchParams, setSearchParams] = useState(new URLSearchParams(location.search))

    useEffect(() => {
        setSearchParams(new URLSearchParams(location.search))
    }, [location.search])

    return useMemo(() => {
        const result: { [key: string]: string } = {}
        for (const key of searchParams.keys()) {
            result[key] = searchParams.get(key)
        }
        return result
    }, [searchParams])
}

export const useCKSetSearchParams = () => {
    const location = useLocation()
    const [searchParams, setSearchParams] = useState(new URLSearchParams(location.search))

    useEffect(() => {
        setSearchParams(new URLSearchParams(location.search))
    }, [location.search])

    const updateSearchParams = async (values: { [key: string]: string }) => {
        const newSearchParams = new URLSearchParams(searchParams.toString())
        console.log(newSearchParams.toString())
        for (const key in values) {
            const value = values[key]
            newSearchParams.set(key, value)
        }
        console.log(newSearchParams.toString())
        setSearchParams(newSearchParams)
        const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + newSearchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);
    }

    return updateSearchParams
}