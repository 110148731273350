import DeleteIcon from '@mui/icons-material/Delete'
import { Divider, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import clsx from 'clsx'
import CKConfirmModal from 'components/UI/CKModal/CKConfirmModal'
import CKModel from 'models/CKModel'
import { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AuthService from 'services/AuthService'
import './style.scss'

interface Props {
    model: CKModel
    className?: string
}

const CKDeleteModelButton: FC<Props> = ({ model, className }) => {
    className = (className && className) + ' ck-delete-button'
    const history = useHistory()

    const [modalOpen, setModalOpen] = useState(false)

    return (
        <>
            <Divider />
            <MenuItem
                className={clsx('ck-delete-button', {
                    [className]: Boolean(className),
                })}
                onClick={(e) => {
                    setModalOpen(true)
                }}
            >
                <ListItemIcon>
                    <DeleteIcon color="error" fontSize="small" />
                </ListItemIcon>
                <ListItemText color="error">Verwijderen</ListItemText>
            </MenuItem>
            <CKConfirmModal
                open={modalOpen}
                color="error"
                message={"Bent u zeker dat u '" + model.$name + "' wilt verwijderen?"}
                label={'Verwijderen'}
                icon={<DeleteIcon />}
                onClose={() => {
                    setModalOpen(false)
                }}
                onConfirm={async () => {
                    const resp = await AuthService.delete(model.$className + 's/' + model.$slug)
                    history.push('/')
                }}
            />
        </>
    )
}
export default CKDeleteModelButton
