/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// react-router-dom components
import { Link } from 'react-router-dom'

// @mui material components
import Collapse from '@mui/material/Collapse'

// Material Kit 2 React components
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Divider } from '@mui/material'
import MKBox from 'components/MaterialKit/MKBox'
import MKTypography from 'components/MaterialKit/MKTypography'
function DefaultNavbarDropdown({
    name,
    icon,
    children,
    collapseStatus,
    light,
    href,
    route,
    action,
    collapse,
    divider,
    isMobile,
    ...rest
}) {
    const linkComponent = {
        component: 'a',
        href,
        target: '_blank',
        rel: 'noreferrer',
    }

    const routeComponent = {
        component: Link,
        to: route,
    }

    const actionComponent = {
        onClick: (e) => {
            console.log(e.target)
        },
    }

    return (
        <>
            {divider ? (
                <Divider
                    orientation={isMobile ? 'horizontal' : 'vertical'}
                    flexItem={true}
                    style={{
                        backgroundColor: 'rgba(52,71,103,0.7)',
                        height: !isMobile && '30px',
                        marginLeft: isMobile && 'auto',
                        marginRight: isMobile && 'auto',
                        marginTop: !isMobile && 'auto',
                        marginBottom: !isMobile && 'auto',
                        width: isMobile && '80%',
                    }}
                />
            ) : (
                <MKBox
                    {...rest}
                    mx={1}
                    p={1}
                    display="flex"
                    alignItems="center"
                    color={light ? 'white' : 'dark'}
                    opacity={light ? 1 : 0.6}
                    sx={{
                        cursor: 'pointer',
                        userSelect: 'none',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    {...(action && actionComponent)}
                    {...(route && routeComponent)}
                    {...(href && linkComponent)}
                >
                    <MKTypography
                        variant="body2"
                        lineHeight={1}
                        color="inherit"
                        sx={{
                            alignSelf: 'center',
                            '& *': { verticalAlign: 'middle' },
                        }}
                    >
                        {icon}
                    </MKTypography>
                    <MKTypography
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                        color={light ? 'white' : 'dark'}
                        sx={{ fontWeight: '100%', ml: 1, mr: 0.25 }}
                    >
                        {name}
                    </MKTypography>
                    <MKTypography
                        variant="body2"
                        color={light ? 'white' : 'dark'}
                        lineHeight={'1.5em'}
                        ml="auto"
                    >
                        {collapse && (
                            <>
                                {collapseStatus ? (
                                    <KeyboardArrowDownIcon
                                        sx={{
                                            fontWeight: 'normal',
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                ) : (
                                    <KeyboardArrowRightIcon
                                        sx={{
                                            fontWeight: 'normal',
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </MKTypography>
                </MKBox>
            )}
            {children && (
                <Collapse in={Boolean(collapseStatus)} timeout={400} unmountOnExit>
                    {children}
                </Collapse>
            )}
        </>
    )
}

// Setting default values for the props of DefaultNavbarDropdown
DefaultNavbarDropdown.defaultProps = {
    children: false,
    collapseStatus: false,
    light: false,
    href: '',
    route: '',
}

// Typechecking props for the DefaultNavbarDropdown
DefaultNavbarDropdown.propTypes = {
    name: PropTypes.any.isRequired,
    icon: PropTypes.node,
    children: PropTypes.node,
    collapseStatus: PropTypes.bool,
    light: PropTypes.bool,
    href: PropTypes.string,
    route: PropTypes.string,
    collapse: PropTypes.bool.isRequired,
}

export default DefaultNavbarDropdown
