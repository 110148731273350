/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React base styles
import borders from 'assets/theme/base/borders'
import boxShadows from 'assets/theme/base/boxShadows'

const { borderRadius } = borders
const { xxl } = boxShadows

export default {
    styleOverrides: {
        paper: {
            borderRadius: borderRadius.lg,
            boxShadow: xxl,
        },

        paperFullScreen: {
            borderRadius: 0,
        },
    },
}
