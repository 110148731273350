const TimeUtils = {
    toTimeString: function (date: Date, infix: string = 'u'): string {
        let result = ''

        result += date.getHours() + infix + ('0' + date.getMinutes()).slice(-2)

        return result
    },
    toExtendedTimeString: function (date: Date): string {
        if (!date) return
        const today = new Date()

        let result = ''
        if (date.toDateString() !== today.toDateString()) {
            result += TimeUtils.toDateString(date) + ' '
        }
        result += TimeUtils.toTimeString(date)
        return result
    },
    toDateString: function (date: Date, full: boolean = false): string {
        if (!full) {
            const result = `${('0' + date.getDate()).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-'${date.getFullYear() % 100}`
            return result
        } else {
            const result = `${('0' + date.getDate()).slice(-2)} ${date.toLocaleString('nl-NL', { month: 'long' })}`
            return result
        }
    },
}
export default TimeUtils
