import MKTypography from 'components/MaterialKit/MKTypography'
import { FC } from 'react'

interface Props {
    children?: any
    [rest: string]: any
}
const CKModalTitle: FC<Props> = ({ children, ...rest }) => {
    return (
        <MKTypography variant="h5" color="info" {...rest}>
            {children}
        </MKTypography>
    )
}
export default CKModalTitle
