import { forwardRef } from 'react'
import MKTypographyJS from './MKTypographyJS'

const MKTypography = forwardRef((props: any, ref) => {
    return (
        //@ts-ignore
        <MKTypographyJS ref={ref} {...props}></MKTypographyJS>
    )
})
export default MKTypography
