/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// Custom styles for the MKBadge
import MKBadgeRoot from 'components/MaterialKit/MKBadge/MKBadgeRoot'

const MKBadgeJS = forwardRef(
    ({ color, variant, size, circular, indicator, border, container, children, ...rest }, ref) => (
        <MKBadgeRoot
            {...rest}
            ownerState={{
                color,
                variant,
                size,
                circular,
                indicator,
                border,
                container,
                children,
            }}
            ref={ref}
            color="default"
        >
            {children}
        </MKBadgeRoot>
    )
)

// Setting default values for the props of MKBadge
MKBadgeJS.defaultProps = {
    color: 'info',
    variant: 'gradient',
    size: 'sm',
    circular: false,
    indicator: false,
    border: false,
    children: false,
    container: false,
}

// Typechecking props of the MKBadge
MKBadgeJS.propTypes = {
    color: PropTypes.oneOf([
        'white',
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'light',
        'dark',
    ]),
    variant: PropTypes.oneOf(['gradient', 'contained']),
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    circular: PropTypes.bool,
    indicator: PropTypes.bool,
    border: PropTypes.bool,
    children: PropTypes.node,
    container: PropTypes.bool,
}

export default MKBadgeJS
