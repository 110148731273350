import useWindowSize from 'HOC/WindowSize'
import { FC, useMemo, useRef, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

interface Props {
    children?: any
}
const CKGrowableScrollbar: FC<Props> = ({ children }) => {
    const scrollContainerRef = useRef<HTMLDivElement>()
    const [height, setHeight] = useState(0)
    const [windowWidth, windowHeight] = useWindowSize()
    const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

    useMemo(async () => {
        if (!scrollContainerRef.current) return
        setHeight(scrollContainerRef.current.clientHeight)
    }, [scrollContainerRef.current])

    useMemo(async () => {
        setHeight(0)
        await sleep(1)
        if (!scrollContainerRef.current) return
        setHeight(scrollContainerRef.current.clientHeight)
    }, [windowWidth, windowHeight])

    return (
        <div
            ref={scrollContainerRef}
            style={{
                flex: '1 1 auto',
                display: 'flex',
                overflow: 'auto',
                width: '100%',
            }}
        >
            <PerfectScrollbar style={{ height: height + 'px', width: '100%' }}>
                {children}
            </PerfectScrollbar>
        </div>
    )
}
export default CKGrowableScrollbar
