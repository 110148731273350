import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import SettingsIcon from '@mui/icons-material/Settings'
import { Menu } from '@mui/material'
import clsx from 'clsx'
import MKButton from 'components/MaterialKit/MKButton'
import React, { FC, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'
import './style.scss'

interface Props {
    children?: any
    hideWhenEmpty?: boolean
    className?: string
    [rest: string]: any
}
const CKSettingsButton: FC<Props> = ({ children, hideWhenEmpty, className, ...rest }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const open = Boolean(anchorEl)
    const elemId = uuid()
    const hasChildren = useMemo(() => {
        if (!children) return false
        if (typeof children === 'object' && !Array.isArray(children)) return true
        for (const child of children) {
            if (child) return true
        }
        return false
    }, [children])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!Array.isArray(children)) {
            const elem = document.getElementById(elemId)
            if (elem && elem.hasChildNodes()) {
                const child = elem.childNodes[0] as HTMLElement
                child.click()
            }
        } else {
            setAnchorEl(event.currentTarget)
        }
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            {(hasChildren || !hideWhenEmpty) && (
                <>
                    <MKButton
                        className={clsx('ck-settings-button', {
                            [className]: Boolean(className),
                        })}
                        size="small"
                        variant="gradient"
                        color="info"
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}
                    >
                        <SettingsIcon className='me-2'/> Beheer
                    </MKButton>
                    {!Array.isArray(children) ? (
                        <div id={elemId} className="ck-hidden-settings-item">
                            {children}
                        </div>
                    ) : (
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            className="ck-settings-dropdown-menu"
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            {children}
                        </Menu>
                    )}
                </>
            )}
        </>
    )
}
export default CKSettingsButton
