import { FormControl } from '@mui/material'
import CKAddressPicker from 'components/Form/CKAddressPicker'
import CKSelect from 'components/Form/CKSelect'
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import Address from 'models/Address'
import { FC, useEffect, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import UserService from 'services/UserService'

interface Props {}
const LocationSettings: FC<Props> = () => {
    const [address, setAddress] = useState<Address>()
    const [saving, setSaving] = useState<boolean>(false)
    const distances = [3, 5, 10, 15, 25, 50, 75]
    const [radius, setRadius] = useState(50)

    useEffect(() => {
        (async () => {
            try {
                const addr = await UserService.getMyAddress()
                console.log(addr)
                if (addr) {
                    setAddress(addr)
                } else {
                    setAddress(new Address())
                }

                const pref = await UserService.getPreferences()
                if (pref.event_near_radius) {
                    setRadius(pref.event_near_radius)
                }
            } catch (err) {
                console.log(err)
                setAddress(new Address())
            }
        })()
    }, [])

    const save = async () => {
        setSaving(true)

        try {
            await UserService.updateMyAddress(address)
            await UserService.updatePreferences({ event_near_radius: radius })
        } catch (e) {
            console.error(e)
        }

        setSaving(false)
    }

    return (
        <>
            <MKTypography variant="body2" component="div" color="text" mb={3}>
                We gebruiken jouw locatie enkel om je meldingen te sturen als er evenementen in jouw
                buurt zijn.
            </MKTypography>
            {address && (
                <CKAddressPicker
                    label="Adres"
                    location={address}
                    disableFullWidth
                    onSelect={(props) => {
                        address.$lat = props.lat
                        address.$lng = props.lng
                        address.$fullAddress = props.address
                        address.$city = props.city
                        address.$postalCode = props.postal_code
                        address.$street = props.street
                        address.$streetNumber = props.street_number
                        address.$region = props.region
                    }}
                />
            )}
            <FormControl fullWidth className="mt-3">
                <MKTypography variant="caption" color="main">
                    Straal*:
                </MKTypography>
                <CKSelect
                    variant="standard"
                    options={distances.map((a) => {
                        return { value: a, label: '< ' + a + 'km' }
                    })}
                    defaultValue={radius}
                    onSelect={(e) => {
                        const value: number = typeof e === 'string' ? parseInt(e) : e
                        setRadius(value)
                    }}
                />
            </FormControl>
            <MKBox mt={4} mb={1}>
                <MKButton
                    variant="gradient"
                    color="info"
                    onClick={save}
                    fullWidth
                    disabled={saving}
                >
                    {saving ? <ClipLoader size={15} color={'white'} /> : 'Opslaan'}
                </MKButton>
            </MKBox>
        </>
    )
}
export default LocationSettings
