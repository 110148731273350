import DomainAddIcon from '@mui/icons-material/DomainAdd'
import bgImage from 'assets/images/bar_sidebar.jpg'
import CKDeleteModelButton from 'components/Buttons/CKDeleteModelButton'
import CKEditModelButton from 'components/Buttons/CKEditModelButton'
import { CKElevatedUsersMenuItem, CKElevatedUsersModal } from 'components/Buttons/CKElevatedUsers'
import CKSettingsButton from 'components/Buttons/CKSettingsButton'
import CKShareButtons from 'components/Buttons/CKShareButtons'
import MKBox from 'components/MaterialKit/MKBox'
import { CKHero } from 'components/UI/CKHero'
import CKLoadingModel from 'components/UI/CKLoadingModel'
import { CKPageBadge, CKPageBody, CKPageContent, CKPageTitle } from 'components/UI/CKPageContent'
import CKSocials from 'components/UI/CKSocials'
import { FC, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useWorkshop } from 'services/WorkshopService'

interface Props { }

const PageWorkshopDetail: FC<Props> = () => {
    const workshopId = useParams()['workshopId']
    const { workshop, isLoading: loading } = useWorkshop(workshopId)
    const [elevatedUsersOpen, setElevatedUsersOpen] = useState(false)

    const banner = useMemo(() => {
        if (!workshop || !workshop.$banner || !workshop.$banner.$url) return bgImage

        return workshop.$banner.$url
    }, [workshop])

    return (
        <>
            {workshop ? (
                <MKBox>
                    <CKHero size="md" bgImage={banner} />
                    <CKPageContent>
                        <CKPageBadge src={workshop.$logo && workshop.$logo.$url}>
                            <DomainAddIcon fontSize="large" style={{ color: 'white' }} />
                        </CKPageBadge>
                        <CKPageTitle>
                            {workshop.$name}
                        </CKPageTitle>
                        <div className="text-center mt-3">
                            <CKShareButtons />
                            <CKSettingsButton hideWhenEmpty className="ms-4">
                                {workshop.hasRight('FULL') && (
                                    <CKEditModelButton
                                        className="h-100 w-100"
                                        model={workshop}
                                        menuItem
                                    />
                                )}
                                {workshop.isOwner() && (
                                    <CKElevatedUsersMenuItem
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setElevatedUsersOpen(true)
                                        }}
                                    />
                                )}
                                {workshop.isOwner() && (
                                    <CKDeleteModelButton
                                        className="h-100 w-100"
                                        model={workshop}
                                    />
                                )}
                            </CKSettingsButton>
                            <CKElevatedUsersModal
                                model={workshop}
                                open={elevatedUsersOpen}
                                onClose={() => setElevatedUsersOpen(false)}
                            />
                        </div>
                        <CKPageBody center>
                            <div className="mx-auto text-center my-3" style={{ maxWidth: '700px' }}>
                                {workshop.$description}
                            </div>
                            <MKBox mt={3}>
                                <CKSocials socials={workshop.$socials} />
                            </MKBox>
                            {/* <MKBox mt={4}>
                                <CKEventList
                                    model={workshop}
                                    upcomingEventsFunction={WorkshopService.upcomingEvents}
                                />
                            </MKBox> */}
                        </CKPageBody>
                        {/* {renderForm()} */}
                    </CKPageContent>
                </MKBox>
            ) : (
                <CKLoadingModel notFoundMessage="Geen workshop gevonden" loading={loading} />
            )}
        </>
    )
}
export default PageWorkshopDetail
