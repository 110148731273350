import CKFormFeedback from 'components/Form/CKFormFeedback'
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import MKInput from 'components/MaterialKit/MKInput'
import { FC, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { Zoom, toast } from 'react-toastify'
import UserService from 'services/UserService'
import PasswordUtils from 'utils/PasswordUtils'

interface Props {}

const PasswordChange: FC<Props> = () => {
    const [oldPassword, setOldPassword] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [errors, setErrors] = useState({})
    const [saving, setSaving] = useState<boolean>(false)

    const save = async () => {
        setSaving(true)

        if (!valid()) {
            setSaving(false)
            return
        }

        try {
            await UserService.changePassword(oldPassword, password)
            setOldPassword('')
            setPassword('')
            setConfirmPassword('')
            setErrors({})

            toast.success('Wachtwoord aangepast', {
                containerId: 'B',
                transition: Zoom,
            })
        } catch (e) {
            if (e.response.status === 400) {
                const _errors = {}
                _errors['oldPassword'] = 'Onjuist wachtwoord'
                setErrors(_errors)
            } else {
                console.error(e)
            }
        }
        setSaving(false)
    }

    const valid = () => {
        const _errors = {}

        if (!password) {
            _errors['password'] = 'Geef een wachtwoord in'
        } else if (!PasswordUtils.isStrongPassword(password)) {
            _errors['password'] = 'Wachtwoord niet sterk genoeg'
        } else if (password !== confirmPassword) {
            _errors['confirmPassword'] = 'Wachtwoorden komen niet overeen'
        }

        setErrors(_errors)
        return Object.keys(_errors).length === 0
    }

    /**
     * Event handler on chat input
     * @param e
     */
    const handleTextInput = async (e) => {
        if (e.key === 'Enter') {
            save()
        }
    }

    return (
        <>
            <MKBox mb={2}>
                <MKInput
                    type="password"
                    label="Huidig wachtwoord"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    fullWidth
                    onKeyPress={handleTextInput}
                    error={errors.hasOwnProperty('oldPassword')}
                />
                <CKFormFeedback>{errors['oldPassword']}</CKFormFeedback>
            </MKBox>
            <MKBox mb={2}>
                <MKInput
                    type="password"
                    label="Nieuw wachtwoord"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    onKeyPress={handleTextInput}
                    error={errors.hasOwnProperty('password')}
                />
                <CKFormFeedback>{errors['password']}</CKFormFeedback>
            </MKBox>
            <MKBox mb={2}>
                <MKInput
                    type="password"
                    label="Bevestig nieuw wachtwoord"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    fullWidth
                    onKeyPress={handleTextInput}
                    error={errors.hasOwnProperty('confirmPassword')}
                />
                <CKFormFeedback>{errors['confirmPassword']}</CKFormFeedback>
            </MKBox>
            <MKBox mt={4} mb={1}>
                <MKButton
                    variant="gradient"
                    color="info"
                    onClick={save}
                    fullWidth
                    disabled={saving}
                >
                    {saving ? <ClipLoader size={15} color={'white'} /> : 'Opslaan'}
                </MKButton>
            </MKBox>
        </>
    )
}
export default PasswordChange
