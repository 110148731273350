import { Share } from '@capacitor/share'
import { isPlatform } from '@ionic/react'
import { Grid } from '@mui/material'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import { ShareIcon } from 'components/UI/CKIcons'
import { CKModal, CKModalHeader, CKModalTitle } from 'components/UI/CKModal'
import CKModalBody from 'components/UI/CKModal/CKModalBody'
import { FC, useState } from 'react'
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share'

interface Props {
    shareTitle?: string
    shareText?: string
    className?: string
    [rest: string]: any
}
const CKShareButtons: FC<Props> = ({ shareTitle, shareText, className, ...rest }) => {
    const [modalOpen, setModalOpen] = useState(false)

    const desktopOnClick = (e: MouseEvent) => {
        setModalOpen(true)
    }

    const mobileOnClick = async (e: MouseEvent) => {
        await Share.share({
            url: 'https://www.comedykit.be' + window.location.pathname,
            title: shareTitle,
            text: shareText,
        })
    }

    const renderModal = () => {
        return (
            <CKModal
                closeButton
                width={'250px'}
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false)
                }}
            >
                <CKModalHeader className="mb-3">
                    <CKModalTitle>
                        <ShareIcon className="me-2" /> Delen
                    </CKModalTitle>
                </CKModalHeader>
                <CKModalBody className="mt-3">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} className="text-center">
                            <FacebookShareButton url={window.location.href} hashtag={'ComedyKit'}>
                                <MKTypography
                                    variant="label"
                                    style={{ color: '#4267B2' }}
                                    className="d-flex align-items-center justify-content-center"
                                >
                                    <FacebookIcon size={32} round className="me-2" /> Facebook
                                </MKTypography>
                            </FacebookShareButton>
                        </Grid>
                        <Grid item xs={12} md={12} className="text-center">
                            <TwitterShareButton url={window.location.href} hashtags={['ComedyKit']}>
                                <MKTypography
                                    variant="label"
                                    style={{ color: '#1da1f2' }}
                                    className="d-flex align-items-center justify-content-center"
                                >
                                    <TwitterIcon size={32} round className="me-2" /> Twitter
                                </MKTypography>
                            </TwitterShareButton>
                        </Grid>
                    </Grid>
                </CKModalBody>
            </CKModal>
        )
    }
    return (
        <>
            <MKButton
                {...rest}
                variant="outlined"
                className={className}
                color="info"
                size="small"
                onClick={(e) => {
                    if (isPlatform('android') || isPlatform('ios')) {
                        mobileOnClick(e)
                    } else {
                        desktopOnClick(e)
                    }
                }}
            >
                <ShareIcon className="me-2" /> Delen
            </MKButton>
            {!isPlatform('android') && !isPlatform('ios') && renderModal()}
        </>
    )
}
export default CKShareButtons
