import DomainAddIcon from '@mui/icons-material/DomainAdd'
import { Container, Grid } from '@mui/material'
import bgImage from 'assets/images/bar_sidebar.jpg'
import { CKForm, CKFormBody } from 'components/Form/CKForm'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import CKImageUpload from 'components/Form/CKImageUpload'
import CKSocialsForm from 'components/Form/CKSocialsForm'
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import MKInput from 'components/MaterialKit/MKInput'
import MKTypography from 'components/MaterialKit/MKTypography'
import { CKHero } from 'components/UI/CKHero'
import { ImageIcon, InfoIcon, MoneyIcon, SaveIcon, ShareIcon } from 'components/UI/CKIcons'
import CKLoadingModel from 'components/UI/CKLoadingModel'
import { CKPageBadge, CKPageContent, CKPageTitle } from 'components/UI/CKPageContent'
import { CKSidebarTabs } from 'components/UI/CKSidebarTabs'
import Organisation from 'models/Organisation'
import { FC, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import ImageService from 'services/ImageService'
import OrganisationService from 'services/OrganisationService'
import { MollieConnect } from './MollieConnect'

interface Props { }

const PageOrganisationEdit: FC<Props> = () => {
    const organisationId = useParams()['organisationId']
    const [organisation, setOrganisation] = useState<Organisation | undefined>()

    const [logoData, setLogoData] = useState<any>()
    const [bannerData, setBannerData] = useState<any>()
    const [errors, setErrors] = useState({})

    // const [logo, setLogo] = useState

    const [saving, setSaving] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)

    const history = useHistory()

    useMemo(async () => {
        setLoading(true)
        if (organisationId === 'new') {
            setOrganisation(new Organisation())
        } else {
            const _organisation = await OrganisationService.get(organisationId)
            if (!_organisation.hasRight('FULL')) history.push(_organisation.$detailUrl)
            setOrganisation(_organisation)
        }
        setLoading(false)
    }, [organisationId, history])

    const validate = () => {
        const _errors = {}
        const defaultMsg = 'Verplicht veld!'

        _errors['name'] = !organisation.$name ? defaultMsg : false

        setErrors(_errors)

        return !Object.values(_errors).find((a) => a !== false)
    }

    const save = async () => {
        setSaving(true)
        try {
            const validated = validate()
            if (validated) {
                if (logoData) {
                    organisation.$logo = await ImageService.upload({
                        base64: logoData.content,
                        extension: logoData.ext,
                        name: organisation.$name + ' - LOGO',
                        type: 'LOGO',
                    })
                }

                if (bannerData) {
                    organisation.$banner = await ImageService.upload({
                        base64: bannerData.content,
                        extension: bannerData.ext,
                        name: organisation.$name + ' - BANNER',
                        type: 'BANNER',
                    })
                }

                if (organisationId === 'new') {
                    const org = await OrganisationService.post(organisation)
                    history.push(org.$detailUrl)
                } else {
                    const updatedOrganisation = await OrganisationService.update(organisation)
                    history.push(updatedOrganisation.$detailUrl)
                }
            }
        } catch (e) {
            console.error(e)
        }
        setSaving(false)
    }

    const renderForm = () => {
        if (!organisation) return null
        return (
            <CKForm>
                {/* <CKFormDescription className="mb-3 text-center pt-2">
					Hoppa, nog een plaats bij om te lachen. Vul de gegevens van jouw
					organisatie hier in en zorg ervoor dat je meute aan comedians je optijd
					kunnen vinden!
				</CKFormDescription> */}
                <CKFormBody>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <MKInput
                                // variant="standard"
                                label="Naam"
                                title="Naam"
                                error={errors['name'] ? true : false}
                                defaultValue={organisation.$name}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    organisation.$name = e.target.value
                                }}
                                required
                                fullWidth
                            />
                            <CKFormFeedback>{errors['name']}</CKFormFeedback>
                        </Grid>
                        <Grid item xs={12}>
                            <MKInput
                                type="standard"
                                multiline
                                defaultValue={organisation.$description}
                                placeholder="Geef een beschrijving van de organisatie zodat mensen je weten wat je doet..."
                                // variant="standard"
                                title="Beschrijving"
                                label="Beschrijving"
                                onChange={(e) => {
                                    organisation.$description = e.target.value
                                }}
                                InputLabelProps={{ shrink: true }}
                                rows={8}
                                fullWidth
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <CKSocialsForm model={organisation} />
                        </Grid> */}
                    </Grid>
                </CKFormBody>
            </CKForm>
        )
    }

    const renderMedia = () => {
        if (!organisation) return null
        return (
            <CKForm>
                <CKFormBody>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={4}>
                            <MKTypography variant="caption">Logo</MKTypography>
                            <CKImageUpload
                                defaultImage={organisation.$logo}
                                onChange={(
                                    files: Array<{
                                        fileName: string
                                        ext: string
                                        content: string | ArrayBuffer
                                    }>
                                ) => {
                                    console.log(files)
                                    if (files.length === 0) return
                                    setLogoData(files[0])
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <MKTypography variant="caption">Banner</MKTypography>
                            <CKImageUpload
                                defaultImage={organisation.$banner}
                                onChange={(
                                    files: Array<{
                                        fileName: string
                                        ext: string
                                        content: string | ArrayBuffer
                                    }>
                                ) => {
                                    if (files.length === 0) return
                                    setBannerData(files[0])
                                }}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <CKSocialsForm model={organisation} />
                        </Grid> */}
                    </Grid>
                </CKFormBody>
            </CKForm>
        )
    }



    const sidebarSteps = {
        root: {
            algemeen: {
                title: 'Algemeen',
                icon: <InfoIcon />,
                component: renderForm(),
            },
            afbeeldingen: {
                title: 'Afbeeldingen',
                icon: <ImageIcon />,
                component: renderMedia(),
            },
            socials: {
                title: 'Socials',
                icon: <ShareIcon />,
                component: <CKSocialsForm model={organisation} />,
            },
            payments: {
                title: 'Betalingen',
                icon: <MoneyIcon />,
                component: <MollieConnect organisation={organisation} />,
            }
        },
    }
    return (
        <>
            <MKBox>
                {organisation ? (
                    <>
                        <CKHero size="md" bgImage={organisation?.$banner?.$url ?? bgImage} />
                        <CKPageContent>
                            <CKPageBadge background="linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))">
                                <DomainAddIcon fontSize="large" style={{ color: 'white' }} />
                            </CKPageBadge>
                            <CKPageTitle>
                                {organisationId === 'new'
                                    ? 'Creëer een organisatie'
                                    : 'Pas organisatie aan'}
                            </CKPageTitle>
                            <Container className="my-3">
                                <CKSidebarTabs
                                    sections={sidebarSteps}
                                    footer={
                                        <div className="text-center">
                                            <MKButton
                                                type="submit"
                                                variant="gradient"
                                                color="info"
                                                onClick={save}
                                                disabled={saving}
                                            >
                                                {saving ? (
                                                    <p className="mb-0 me-2">
                                                        <ClipLoader size={15} color={'white'} />
                                                    </p>
                                                ) : (
                                                    <SaveIcon className="me-2" />
                                                )}
                                                {organisationId === 'new'
                                                    ? 'Creëer organisatie!'
                                                    : 'Sla op!'}
                                            </MKButton>
                                        </div>
                                    }
                                    hideGroup
                                />
                            </Container>
                        </CKPageContent>
                    </>
                ) : (
                    <CKLoadingModel loading={loading} notFoundMessage="Organisatie niet gevonden" />
                )}
            </MKBox>
        </>
    )
}
export default PageOrganisationEdit
