import { CKHero } from "components/UI/CKHero";
import { useCKSearchParams } from "context/SearchParamsContext"
import { FC, useMemo } from "react"
import bgImage from 'assets/images/bgImage.jpg'
import { CKPageContent } from "components/UI/CKPageContent";
import { CKLoading } from "components/Generic/CKLoading";
import { postMollieOAuthCallback } from "services/MollieService";
import { useHistory } from 'react-router-dom'
import Organisation from "models/Organisation";

export const PageOrganisationMollieOAuthCallback: FC = () => {
    const { state, code } = useCKSearchParams();
    const history = useHistory()

    useMemo(async () => {
        if (!state || !code) return
        const resp: Organisation = await postMollieOAuthCallback(state, code);
        if (resp && resp.$slug) {
            history.push(resp.$adminUrl + '?tab=payments')
        }
    }, [state, code, history])

    return (
        <>
            <CKHero
                bgImage={bgImage}
                size="md"
            />
            <CKPageContent>
                <div className="my-4">
                    <CKLoading text={"We verbinden je Mollie account met ComedyKit"} />
                </div>
            </CKPageContent>
        </>
    )
}