import { Capacitor } from '@capacitor/core'
import { Grid, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import useSize from '@react-hook/size'
import MKBox from 'components/MaterialKit/MKBox'
import MKTypography from 'components/MaterialKit/MKTypography'
import { FC, useEffect, useRef, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useHistory, useLocation } from 'react-router-dom'
import { ISidebarTabs } from '.'
export const CKDesktopSidebarTabs: FC<ISidebarTabs> = ({
    sections,
    hideTitle,
    hideGroup,
    footer,
}) => {
    const [selectedTab, setSelectedTab] = useState<string>('')
    const { search } = useLocation()
    const history = useHistory()

    const onTabClicked = (tab) => {
        if (tab === selectedTab) return

        const searchParams = new URLSearchParams(search)
        searchParams.set('tab', tab)
        setSelectedTab(tab)
        history.push({
            pathname: history.location.pathname,
            search: '?' + searchParams.toString(),
        })
    }

    const getFirstTab = () => {
        const firstSection = Object.keys(sections)[0]
        const firstTab = Object.keys(sections[firstSection])[0]
        return firstTab
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(search)
        const tab = searchParams.get('tab')
        if (tab) {
            setSelectedTab(tab)
        } else if (!Capacitor.isNativePlatform()) {
            const startTab = getFirstTab()
            searchParams.set('tab', startTab)
            setSelectedTab(startTab)
            history.replace({
                pathname: history.location.pathname,
                search: '?' + searchParams.toString(),
            })
        }
    }, [search])

    const renderContent = () => {
        for (const section of Object.keys(sections)) {
            if (sections[section].hasOwnProperty(selectedTab)) {
                return sections[section][selectedTab].component
            }
        }
    }

    const renderTitle = () => {
        for (const section of Object.keys(sections)) {
            if (sections[section].hasOwnProperty(selectedTab)) {
                return sections[section][selectedTab].title
            }
        }
    }

    const leftColumn = useRef(null)
    const [, height] = useSize(leftColumn)

    return (
        <Grid container>
            <Grid item xs={12} md={4} lg={3} className="ck-settings-menu py-2" ref={leftColumn}>
                <div className="d-flex flex-column justify-content-between h-100">
                    <List>
                        {Object.keys(sections).map((group) => {
                            const groupMenu = sections[group]
                            return (
                                <div className="mb-4" key={`settings-group-${group}`}>
                                    {!hideGroup && group}

                                    {Object.keys(groupMenu).map((key) => {
                                        const menuItem = groupMenu[key]
                                        if (
                                            menuItem.hasOwnProperty('onlyMobile') &&
                                            !Capacitor.isNativePlatform()
                                        ) {
                                            return
                                        }
                                        return (
                                            <ListItemButton
                                                key={`ck-settings-menu-option-${key}`}
                                                className="ck-settings-menu-option"
                                                selected={selectedTab === key}
                                                onClick={() => onTabClicked(key)}
                                            >
                                                <ListItemIcon className='mt-1'>{menuItem.icon}</ListItemIcon>
                                                <ListItemText
                                                    primary={menuItem.title}
                                                    className='m-0 p-0'
                                                    primaryTypographyProps={{
                                                        fontSize: 'h6.fontSize',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginTop: '0.5rem',
                                                    }}
                                                />
                                            </ListItemButton>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </List>
                    {footer}
                </div>
            </Grid>
            <Grid item md={8} lg={9}>
                <PerfectScrollbar style={{ maxHeight: height + 'px', height: '100%' }}>
                    <MKBox
                        className="ck-settings-option-box py-2 px-3"
                        flexDirection="column"
                        justifyContent="center"
                        // mx={{ xs: 0, md: 3, lg: 10, xl: 15 }}
                        sx={{ boxShadow: 0 }}
                    >
                        {!hideTitle && (
                            <MKTypography
                                sx={{ fontSize: 'h6.fontSize', fontWeight: 'bold' }}
                                className="setting-title text-center mb-4"
                            >
                                {renderTitle()}
                            </MKTypography>
                        )}
                        {renderContent()}
                    </MKBox>
                </PerfectScrollbar>
            </Grid>
        </Grid>
    )
}
