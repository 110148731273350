import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useMediaQuery, useTheme } from '@mui/material'
import MKPagination from 'components/MaterialKit/MKPagination'
import { FC, useEffect, useMemo } from 'react'

interface Props {
    info: any
    onSelect: (page: number) => void
}
const CKPagination: FC<Props> = (props) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const links = useMemo(() => {
        if (!props.info) return []
        if (!isMobile) return props.info.links

        const result = []

        if (props.info.current_page > 3) {
            result.push({
                label: '...',
                to: 1,
                active: false,
            })
        }

        if (props.info.current_page <= 3) {
            for (let i = 1; i <= Math.min(5, props.info.links.length - 2); i++) {
                result.push({
                    label: '' + i,
                    active: props.info.links[i]?.active,
                })
            }
        } else {
            for (
                let i = props.info.current_page - 2;
                i <= Math.min(props.info.current_page + 2, props.info.last_page);
                i++
            ) {
                result.push({
                    label: '' + i,
                    active: props.info.links.find((a) => a.label === '' + i)?.active,
                })
            }
        }

        if (
            props.info.current_page < props.info.last_page &&
            props.info.current_page + 2 < props.info.last_page
        ) {
            result.push({
                label: '...',
                to: props.info.last_page,
            })
        }
        return result
    }, [props.info])

    useEffect(() => { }, [])

    return (
        <>
            <style>{`
				.visually-hidden {
					display: none;
				}
		`}</style>
            {props.info && props.info.last_page !== 1 && (
                <div className="py-4 d-flex justify-content-center w-100 ck-pagination-container">
                    <MKPagination className="ck-pagination-wrapper">
                        <MKPagination
                            item
                            disabled={props.info.prev_page_url === null}
                            onClick={(e) => {
                                props.onSelect(props.info.current_page - 1)
                            }}
                        >
                            <ChevronLeftIcon />
                        </MKPagination>
                        {links &&
                            links.map((link, index) => {
                                if (link.label.includes('Previous') || link.label.includes('Next'))
                                    return

                                // const diff = Math.abs(props.info.current_page - link.label);
                                // console.log(diff)
                                // if (isMobile && (isNaN(diff) || diff > 2)) {
                                // 	if (diff == 3) {
                                // 		return (
                                // 			<MKPagination
                                // 				item
                                // 				key={`paginationItem-index-${index}`}
                                // 				active={link.active}
                                // 			>
                                // 				...
                                // 			</MKPagination>
                                // 		)
                                // 	} else {
                                // 		return
                                // 	}
                                // };

                                return (
                                    <MKPagination
                                        item
                                        key={`paginationItem-index-${index}`}
                                        disabled={link.label === '...' && !link.to}
                                        active={link.active}
                                        onClick={(e) => {
                                            props.onSelect(link.to ? link.to : link.label)
                                        }}
                                    >
                                        {link.label}
                                    </MKPagination>
                                )
                            })}
                        <MKPagination
                            item
                            disabled={props.info.next_page_url === null}
                            onClick={(e) => {
                                props.onSelect(props.info.current_page + 1)
                            }}
                        >
                            <ChevronRightIcon />
                        </MKPagination>
                    </MKPagination>
                </div>
            )}
        </>
    )
}
export default CKPagination
