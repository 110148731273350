import CKModel from './CKModel'

export default class Image extends CKModel {
    private description: string | undefined
    private path: string | undefined
    private url: string | undefined

    constructor(
        id: string | undefined = undefined,
        name: string | undefined = undefined,
        description: string | undefined = undefined,
        path: string | undefined = undefined,
        url: string | undefined = undefined
    ) {
        super(id)

        this.name = name
        this.description = description
        this.path = path
        this.url = url
    }

    public static fromJSON(json: any): Image {
        let result = new Image()
        result = Object.assign(result, json)

        return result
    }

    public get $description(): string {
        return this.description
    }
    public set $description(value: string) {
        this.description = value
    }

    public get $path(): string {
        return this.path
    }
    public set $path(value: string) {
        this.path = value
    }

    public get $url(): string {
        return this.url
    }

    public get $className(): string {
        return 'image'
    }
}
