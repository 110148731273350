import { default as mqtt } from 'mqtt'
import * as mqttWildcard from 'mqtt-wildcard'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'

export const MqttContext = createContext(undefined)

export const defaultMQTTBroker = process.env.REACT_APP_MQTT_BROKER

export function MqttProvider({ children }) {
    const [client, setClient] = useState<mqtt.MqttClient>()
    const subListeners = useRef([])

    useEffect(() => {
        if (!client) {
            try {
                const _client = mqtt.connect(defaultMQTTBroker)
                _client.stream.on('error', (err) => {
                    console.error(err)
                    _client.end()
                })
                _client.on('message', onMessage)
                setClient(_client)
            } catch (e) {
                console.error(e)
            }
        }
    }, [client])

    function onMessage(topic, message) {
        message = JSON.parse(new TextDecoder('utf-8').decode(message))

        for (const subListener of subListeners.current) {
            const topicMatch = mqttWildcard(topic, subListener.topic)
            if (topicMatch !== null) {
                subListener.listener(message)
            }
        }
    }

    function subscribe(topic, handler) {
        client.subscribe(topic, (err) => {
            if (err) {
                console.error(err)
            }
        })
        return addListener(topic, handler)
        // client.on("message", onMessage);
    }

    function unsubscribe(listenerId) {
        const _subListeners = subListeners.current.filter((a) => a.id !== listenerId)
        subListeners.current = _subListeners
    }

    const addListener = (topic, eventListener, forceUnique = true) => {
        if (forceUnique) {
            let index = 0
            for (const subListener of subListeners.current) {
                if (
                    subListener.topic === topic &&
                    subListener.listener.toString() === eventListener.toString()
                ) {
                    subListeners.current.splice(index, 1)
                }
                index++
            }
        }

        const id = uuid()
        subListeners.current.push({
            id: id,
            topic: topic,
            listener: eventListener,
        })

        return id
    }

    return (
        <MqttContext.Provider
            value={{
                subscribeTo: subscribe,
                unsubscribe: unsubscribe,
                client: client,
            }}
        >
            {children}
        </MqttContext.Provider>
    )
}

export function useMqtt() {
    return useContext(MqttContext)
}
