import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import MetaTagService from 'services/MetaTagService'

interface Props {}
const CKHelmet: FC<Props> = () => {
    const history = useHistory()
    const [tag, setTag] = useState<{ title: string; description: string }>()

    const init = async () => {
        history.listen(async (location) => {
            if (!location || !location.pathname) return
            const resp = await MetaTagService.get(location.pathname)
            setTag(resp)
        })

        try {
            const resp = await MetaTagService.get(window.location.pathname)
            setTag(resp)
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        init()
    }, [])
    return (
        <>
            {tag && (
                <Helmet>
                    <title>
                        {tag.title === 'Comedy Kit' ? tag.title : 'Comedy Kit - ' + tag.title}
                    </title>
                    <meta name="description" content={tag.description} />
                </Helmet>
            )}
        </>
    )
}
export default CKHelmet
