import { LocalNotifications } from '@capacitor/local-notifications'
import {
    ActionPerformed,
    PushNotifications,
    PushNotificationSchema,
    Token,
} from '@capacitor/push-notifications'
import { Toast } from '@capacitor/toast'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import DeviceService from 'services/DeviceService'

const CKPushNotifications = () => {
    const history = useHistory()

    useEffect(() => {
        PushNotifications.checkPermissions().then((res) => {
            if (res.receive !== 'granted') {
                PushNotifications.requestPermissions().then((res) => {
                    if (res.receive === 'denied') {
                        showToast('Push Notification permission denied')
                    } else {
                        showToast('Push Notification permission granted')
                        register()
                    }
                })
            } else {
                register()
            }
        })
    }, [])

    const register = () => {
        console.log('Initializing registration')

        // Register witgeth Apple / Google to receive push via APNS/FCM
        PushNotifications.register()

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration', async (token: Token) => {
            console.log('Push registration success')
            try {
                await DeviceService.register(token.value)
            } catch (e) {
                console.error(e)
            }
        })

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError', (error: any) => {
            alert('Error on registration: ' + JSON.stringify(error))
        })

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener(
            'pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                console.log('########')
                console.log(notification.title)
                console.log(notification.data)
                // console.log(notification);
                LocalNotifications.schedule({
                    notifications: [
                        {
                            title: notification.title,
                            body: notification.body,
                            id: Number(notification.id),
                            schedule: { at: new Date(Date.now()) },
                            sound: null,
                            attachments: null,
                            actionTypeId: '',
                            extra: null,
                            smallIcon: 'build/favicon',
                        },
                    ],
                })
            }
        )

        // Method called when tapping on a notification
        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
                console.log('*********--------')
                console.log(notification.notification.data.title)
                // console.log(notification.notification);
                if (notification.notification.data.navigate_to) {
                    history.push(notification.notification.data.navigate_to)
                }
            }
        )
    }

    const showToast = async (msg: string) => {
        await Toast.show({
            text: msg,
        })
    }

    return <></>
}

export default CKPushNotifications
