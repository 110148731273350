/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// Custom styles for MKTypography
import MKTypographyRoot from 'components/MaterialKit/MKTypography/MKTypographyRoot'

const MKTypographyJS = forwardRef(
    (
        {
            color,
            fontWeight,
            textTransform,
            verticalAlign,
            textGradient,
            opacity,
            children,
            ...rest
        },
        ref
    ) => (
        <MKTypographyRoot
            {...rest}
            ref={ref}
            ownerState={{
                color,
                textTransform,
                verticalAlign,
                fontWeight,
                opacity,
                textGradient,
            }}
        >
            {children}
        </MKTypographyRoot>
    )
)

// Setting default values for the props of MKTypographyJS
MKTypographyJS.defaultProps = {
    color: 'dark',
    fontWeight: false,
    textTransform: 'none',
    verticalAlign: 'unset',
    textGradient: false,
    opacity: 1,
}

// Typechecking props for the MKTypographyJS
MKTypographyJS.propTypes = {
    color: PropTypes.oneOf([
        'inherit',
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'light',
        'dark',
        'text',
        'white',
    ]),
    fontWeight: PropTypes.oneOf([false, 'light', 'regular', 'medium', 'bold']),
    textTransform: PropTypes.oneOf(['none', 'capitalize', 'uppercase', 'lowercase']),
    verticalAlign: PropTypes.oneOf([
        'unset',
        'baseline',
        'sub',
        'super',
        'text-top',
        'text-bottom',
        'middle',
        'top',
        'bottom',
    ]),
    textGradient: PropTypes.bool,
    children: PropTypes.node,
    opacity: PropTypes.number,
}

export default MKTypographyJS
