import defaultBanner from 'assets/images/user_banner_default.jpg'
import MKBox from 'components/MaterialKit/MKBox'
import { FC } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { Card } from 'reactstrap'
import { CKHero } from '../CKHero'
import { CKPageTitle } from '../CKPageContent'

interface Props {
    notFoundMessage: string
    loading: boolean
    hideBanner?: boolean
}

const CKLoadingModel: FC<Props> = (props) => {
    return (
        <MKBox>
            {!props.hideBanner && <CKHero size="md" bgImage={defaultBanner} />}
            <Card>
                {props.loading ? (
                    <div className="d-flex justify-content-center py-4">
                        <ClipLoader color="rgb(26, 115, 232)" />
                    </div>
                ) : (
                    <CKPageTitle mb={3}>{props.notFoundMessage}</CKPageTitle>
                )}
            </Card>
        </MKBox>
    )
}
export default CKLoadingModel
