/* eslint-disable no-param-reassign */
/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Fragment, useEffect, useRef, useState } from 'react'

// react-router components
import { Link } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @mui material components
import { Card, useMediaQuery } from '@mui/material'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Grow from '@mui/material/Grow'
import MuiLink from '@mui/material/Link'
import Popper from '@mui/material/Popper'

// Material Kit 2 React components
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton/MKButtonJS'
import MKTypography from 'components/MaterialKit/MKTypography'

// Material Kit 2 React example components
import DefaultNavbarDropdown from 'examples/Navbars/DefaultNavbar/DefaultNavbarDropdown'
import DefaultNavbarMobile from 'examples/Navbars/DefaultNavbar/DefaultNavbarMobile'

// Material Kit 2 React base styles
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import MenuIcon from '@mui/icons-material/Menu'
import breakpoints from 'assets/theme/base/breakpoints'
import CKDarkModeToggle from 'components/Buttons/CKDarkModeToggle'
function DefaultNavbar({
    brand,
    routes,
    transparent,
    darkModeToggle,
    light,
    action,
    sticky,
    relative,
    center,
}) {
    const [dropdown, setDropdown] = useState('')
    const [dropdownEl, setDropdownEl] = useState('')
    const [dropdownName, setDropdownName] = useState('')
    const [nestedDropdown, setNestedDropdown] = useState('')
    const [nestedDropdownEl, setNestedDropdownEl] = useState('')
    const [nestedDropdownName, setNestedDropdownName] = useState('')
    // const [arrowRef, setArrowRef] = useState(null);
    const arrowRef = useRef()
    const [mobileNavbar, setMobileNavbar] = useState(false)
    const [mobileView, setMobileView] = useState(false)

    const openMobileNavbar = () => setMobileNavbar(!mobileNavbar)

    useEffect(() => {
        // A function that sets the display state for the DefaultNavbarMobile.
        function displayMobileNavbar() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true)
                setMobileNavbar(false)
            } else {
                setMobileView(false)
                setMobileNavbar(false)
            }
        }

        /** 
         The event listener that's calling the displayMobileNavbar function when 
         resizing the window.
        */
        window.addEventListener('resize', displayMobileNavbar)

        // Call the displayMobileNavbar function to set the state with the initial value.
        displayMobileNavbar()

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', displayMobileNavbar)
    }, [])

    const renderNavbarItems = routes.map(
        ({ name, icon, href, route, collapse, hide, divider, action }, index) => {
            if (hide) return
            return (
                <DefaultNavbarDropdown
                    key={'navbar-item-' + index}
                    name={name}
                    icon={icon}
                    href={href}
                    action={action}
                    collapseStatus={Boolean(dropdown)}
                    route={route}
                    collapse={Boolean(collapse)}
                    divider={divider}
                    onMouseEnter={({ currentTarget }) => {
                        if (collapse) {
                            setDropdown(currentTarget)
                            setDropdownEl(currentTarget)
                            setDropdownName(name)
                        }
                    }}
                    onMouseLeave={() => collapse && setDropdown(null)}
                    light={light}
                />
            )
        }
    )

    // Render the routes on the dropdown menu
    const renderRoutes = routes.map(({ name, collapse, columns, rowsPerColumn, hide }, index) => {
        if (hide) return
        let template

        // Render the dropdown menu that should be display as columns
        if (collapse && columns && name === dropdownName) {
            const calculateColumns = collapse.reduce((resultArray, item, index) => {
                const chunkIndex = Math.floor(index / rowsPerColumn)

                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = []
                }

                resultArray[chunkIndex].push(item)

                return resultArray
            }, [])

            template = (
                <Grid key={'route-' + index} container spacing={3} py={1} px={1.5}>
                    {calculateColumns.map((cols, key) => {
                        const gridKey = `grid-${key}`
                        const dividerKey = `divider-${key}`

                        return (
                            <Grid
                                key={gridKey}
                                item
                                xs={12 / columns}
                                sx={{ position: 'relative' }}
                            >
                                {cols.map((col, index) => (
                                    <Fragment key={'col-' + index}>
                                        <MKTypography
                                            display="block"
                                            variant="button"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                            py={1}
                                            px={0.5}
                                            mt={index !== 0 ? 2 : 0}
                                        >
                                            {col.name}
                                        </MKTypography>
                                        {col.collapse.map((item, itemIndex) => (
                                            <MKTypography
                                                key={'item-' + itemIndex}
                                                component={item.route ? Link : MuiLink}
                                                to={item.route ? item.route : ''}
                                                href={
                                                    item.href
                                                        ? item.href
                                                        : (e) => e.preventDefault()
                                                }
                                                target={item.href ? '_blank' : ''}
                                                rel={item.href ? 'noreferrer' : 'noreferrer'}
                                                minWidth="11.25rem"
                                                display="block"
                                                variant="button"
                                                color="text"
                                                textTransform="capitalize"
                                                fontWeight="regular"
                                                py={0.625}
                                                px={2}
                                                sx={({
                                                    palette: { grey, dark },
                                                    borders: { borderRadius },
                                                }) => ({
                                                    borderRadius: borderRadius.md,
                                                    cursor: 'pointer',
                                                    transition: 'all 300ms linear',

                                                    '&:hover': {
                                                        backgroundColor: grey[200],
                                                        color: dark.main,
                                                    },
                                                })}
                                            >
                                                {item.name}
                                            </MKTypography>
                                        ))}
                                    </Fragment>
                                ))}
                                {key !== 0 && (
                                    <Divider
                                        key={dividerKey}
                                        orientation="vertical"
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '-4px',
                                            transform: 'translateY(-45%)',
                                            height: '90%',
                                        }}
                                    />
                                )}
                            </Grid>
                        )
                    })}
                </Grid>
            )

            // Render the dropdown menu that should be display as list items
        } else if (collapse && name === dropdownName) {
            template = collapse.map((item, itemIndex) => {
                const linkComponent = {
                    component: MuiLink,
                    href: item.href,
                    target: '_blank',
                    rel: 'noreferrer',
                }

                const action = item.action

                const routeComponent = {
                    component: Link,
                    to: item.route,
                }

                const actionComponent = {
                    onClick: (e) => {
                        item.action(e)
                    },
                }

                return (
                    <MKTypography
                        key={'item-' + itemIndex}
                        {...(item.route ? routeComponent : linkComponent)}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        variant="button"
                        {...(action && actionComponent)}
                        textTransform="capitalize"
                        minWidth={item.description ? '14rem' : '12rem'}
                        color={item.description ? 'dark' : 'text'}
                        fontWeight={item.description ? 'bold' : 'regular'}
                        py={item.description ? 1 : 0.625}
                        px={2}
                        sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
                            borderRadius: borderRadius.md,
                            cursor: 'pointer',
                            transition: 'all 300ms linear',

                            '&:hover': {
                                backgroundColor: grey[200],
                                color: dark.main,

                                '& *': {
                                    color: dark.main,
                                },
                            },
                        })}
                        onMouseEnter={({ currentTarget }) => {
                            if (item.dropdown) {
                                setNestedDropdown(currentTarget)
                                setNestedDropdownEl(currentTarget)
                                setNestedDropdownName(item.name)
                            }
                        }}
                        onMouseLeave={() => {
                            if (item.dropdown) {
                                setNestedDropdown(null)
                            }
                        }}
                    >
                        {item.description ? (
                            <MKBox>
                                {item.name}
                                <MKTypography
                                    display="block"
                                    variant="button"
                                    color="text"
                                    fontWeight="regular"
                                    sx={{ transition: 'all 300ms linear' }}
                                >
                                    {item.description}
                                </MKTypography>
                            </MKBox>
                        ) : (
                            item.name
                        )}
                        {item.collapse && (
                            <KeyboardArrowRightIcon
                                fontSize="small"
                                sx={{
                                    fontWeight: 'normal',
                                    verticalAlign: 'middle',
                                    mr: -0.5,
                                }}
                            />
                        )}
                    </MKTypography>
                )
            })
        }

        return template
    })

    // Routes dropdown menu
    const dropdownMenu = (
        <Popper
            anchorEl={dropdown}
            popperRef={null}
            open={Boolean(dropdown)}
            placement="top-start"
            // transition
            style={{ zIndex: 10 }}
            // modifiers={[
            // 	{
            // 		name: "arrow",
            // 		enabled: true,
            // 		options: {
            // 			element: arrowRef.current,
            // 		},
            // 	},
            // ]}
            onMouseEnter={() => setDropdown(dropdownEl)}
            onMouseLeave={() => {
                if (!nestedDropdown) {
                    setDropdownEl(null)
                    setDropdown(null)
                    setDropdownName('')
                }
            }}
        >
            <Card
                sx={{
                    transformOrigin: 'left top',
                    background: ({ palette: { white } }) => white.main,
                    backdropFilter: 'saturate(200%) blur(15px)',
                    //@ts-ignore
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <MKBox borderRadius="lg" className="ck-dropdown-menu">
                    <MKTypography
                        variant="h1"
                        color="white"
                        style={{ position: 'absolute' }}
                        sx={{ mt: -4.5 }}
                    >
                        <ArrowDropUpIcon ref={arrowRef} />
                    </MKTypography>
                    <MKBox shadow="lg" borderRadius="lg" p={2}>
                        {renderRoutes}
                    </MKBox>
                </MKBox>
            </Card>
        </Popper>
    )

    // Render routes that are nested inside the dropdown menu routes
    const renderNestedRoutes = routes.map(({ collapse, columns }) =>
        collapse && !columns
            ? collapse.map(({ name: parentName, collapse: nestedCollapse }) => {
                let template

                if (parentName === nestedDropdownName) {
                    template =
                        nestedCollapse &&
                        nestedCollapse.map((item) => {
                            const linkComponent = {
                                component: MuiLink,
                                href: item.href,
                                target: '_blank',
                                rel: 'noreferrer',
                            }

                            const routeComponent = {
                                component: Link,
                                to: item.route,
                            }

                            return (
                                <MKTypography
                                    key={item.name}
                                    {...(item.route ? routeComponent : linkComponent)}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    variant="button"
                                    textTransform="capitalize"
                                    minWidth={item.description ? '14rem' : '12rem'}
                                    color={item.description ? 'dark' : 'text'}
                                    fontWeight={item.description ? 'bold' : 'regular'}
                                    py={item.description ? 1 : 0.625}
                                    px={2}
                                    sx={({
                                        palette: { grey, dark },
                                        borders: { borderRadius },
                                    }) => ({
                                        borderRadius: borderRadius.md,
                                        cursor: 'pointer',
                                        transition: 'all 300ms linear',

                                        '&:hover': {
                                            backgroundColor: grey[200],
                                            color: dark.main,

                                            '& *': {
                                                color: dark.main,
                                            },
                                        },
                                    })}
                                >
                                    {item.description ? (
                                        <MKBox>
                                            {item.name}
                                            <MKTypography
                                                display="block"
                                                variant="button"
                                                color="text"
                                                fontWeight="regular"
                                                sx={{
                                                    transition: 'all 300ms linear',
                                                }}
                                            >
                                                {item.description}
                                            </MKTypography>
                                        </MKBox>
                                    ) : (
                                        item.name
                                    )}
                                    {item.collapse && (
                                        <KeyboardArrowRightIcon
                                            fontSize="small"
                                            sx={{
                                                fontWeight: 'normal',
                                                verticalAlign: 'middle',
                                                mr: -0.5,
                                            }}
                                        />
                                    )}
                                </MKTypography>
                            )
                        })
                }

                return template
            })
            : null
    )

    // Dropdown menu for the nested dropdowns
    const nestedDropdownMenu = (
        <Popper
            anchorEl={nestedDropdown}
            popperRef={null}
            open={Boolean(nestedDropdown)}
            placement="right-start"
            transition
            style={{ zIndex: 10 }}
            onMouseEnter={() => {
                setNestedDropdown(nestedDropdownEl)
            }}
            onMouseLeave={() => {
                setNestedDropdown(null)
                setNestedDropdownName('')
                setDropdown(null)
            }}
        >
            {({ TransitionProps }) => (
                <Grow
                    {...TransitionProps}
                    sx={{
                        transformOrigin: 'left top',
                        background: ({ palette: { white } }) => white.main,
                    }}
                >
                    <MKBox ml={2.5} mt={-2.5} borderRadius="lg">
                        <MKBox shadow="lg" borderRadius="lg" py={1.5} px={1} mt={2}>
                            {renderNestedRoutes}
                        </MKBox>
                    </MKBox>
                </Grow>
            )}
        </Popper>
    )
    const isMobile = useMediaQuery('(max-width:768px)')

    const navbarContainerSx = () => {
        const result = {}
        if(sticky) {
            result.position = 'sticky'
            result.top = 0
            result.zIndex = 10
        }
        if(isMobile) {
            result.maxWidth = '100% !important' 
            result.padding = '0 !important'
            result.margin= '0 !important'
        }
        // result.maxWidth = {sm: '100% !important'}
        return result
    }

    return (
        <Container
            className="navbar-container"
            sx={navbarContainerSx()}
        >
            <MKBox
                py={1}
                px={{ xs: 2, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
                my={relative ? 0 : 2}
                mx={relative ? 0 : 3}
                width={relative ? '100%' : 'calc(100% - 48px)'}
                borderRadius="xl"
                shadow={transparent ? 'none' : 'md'}
                color={light ? 'white' : 'dark'}
                position={relative ? 'relative' : 'absolute'}
                left={0}
                zIndex={3}
                className={'navbar-box'}
                sx={({
                    palette: { transparent: transparentColor, white },
                    functions: { rgba },
                }) => ({
                    backgroundColor: transparent ? transparentColor.main : rgba(white.main, 0.8),
                    backdropFilter: transparent ? 'none' : `saturate(200%) blur(30px)`,
                })}
            >
                <MKBox display="flex" justifyContent="space-between" alignItems="center">
                    <MKBox
                        component={Link}
                        to="/"
                        lineHeight={1}
                        py={transparent ? 1.5 : 0.75}
                        pl={relative || transparent ? 0 : { xs: 0, lg: 1 }}
                    >
                        <MKTypography
                            variant="button"
                            fontWeight="bold"
                            color={light ? 'white' : 'dark'}
                        >
                            {brand}
                        </MKTypography>
                    </MKBox>
                    <MKBox
                        color="inherit"
                        display={{ xs: 'none', lg: 'flex' }}
                        ml="auto"
                        mr={center ? 'auto' : 0}
                    >
                        {renderNavbarItems}
                    </MKBox>
                    {!mobileView && (
                        <>
                            <MKBox ml={{ xs: 'auto', lg: 0 }}>
                                {action &&
                                    (action.type === 'internal' ? (
                                        <MKButton
                                            {...(action.route
                                                ? {
                                                    to: action.route,
                                                    component: Link,
                                                }
                                                : {})}
                                            onClick={() => {
                                                if (action.action) {
                                                    action.action()
                                                }
                                            }}
                                            variant={
                                                action.color === 'white' ||
                                                    action.color === 'default'
                                                    ? 'contained'
                                                    : 'gradient'
                                            }
                                            color={action.color ? action.color : 'info'}
                                        >
                                            {action.label}
                                        </MKButton>
                                    ) : (
                                        <MKButton
                                            component="a"
                                            href={action.route}
                                            target="_blank"
                                            onClick={() => {
                                                if (action.action) {
                                                    action.action()
                                                }
                                            }}
                                            rel="noreferrer"
                                            variant={
                                                action.color === 'white' ||
                                                    action.color === 'default'
                                                    ? 'contained'
                                                    : 'gradient'
                                            }
                                            color={action.color ? action.color : 'info'}
                                        // size="small"
                                        >
                                            {action.label}
                                        </MKButton>
                                    ))}
                            </MKBox>

                            {darkModeToggle && <CKDarkModeToggle />}
                        </>
                    )}
                    <MKBox
                        display={{ xs: 'inline-block', lg: 'none' }}
                        lineHeight={0}
                        py={1.5}
                        pl={1.5}
                        color={transparent ? 'white' : 'inherit'}
                        sx={{ cursor: 'pointer' }}
                        onClick={openMobileNavbar}
                    >
                        {mobileNavbar ? <CloseIcon /> : <MenuIcon />}
                    </MKBox>
                </MKBox>
                <MKBox
                    bgColor={transparent ? 'white' : 'transparent'}
                    shadow={transparent ? 'lg' : 'none'}
                    borderRadius="xl"
                    px={transparent ? 2 : 0}
                >
                    {mobileView && (
                        <DefaultNavbarMobile
                            darkModeToggle={darkModeToggle}
                            action={action}
                            routes={routes}
                            open={mobileNavbar}
                            onNavigate={() => setMobileNavbar(false)}
                        />
                    )}
                </MKBox>
            </MKBox>
            {dropdownMenu}
            {nestedDropdownMenu}
        </Container>
    )
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
    brand: <>Material Kit 2</>,
    transparent: false,
    light: false,
    action: false,
    sticky: false,
    relative: false,
    darkModeToggle: false,
    center: false,
}

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
    brand: PropTypes.any,
    routes: PropTypes.arrayOf(PropTypes.shape).isRequired,
    transparent: PropTypes.bool,
    light: PropTypes.bool,
    action: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            type: PropTypes.oneOf(['external', 'internal']).isRequired,
            route: PropTypes.string,
            action: PropTypes.func,
            color: PropTypes.oneOf([
                'primary',
                'secondary',
                'info',
                'success',
                'warning',
                'error',
                'dark',
                'light',
                'default',
                'white',
            ]),
            label: PropTypes.any.isRequired,
        }),
    ]),
    sticky: PropTypes.bool,
    darkModeToggle: PropTypes.bool,
    relative: PropTypes.bool,
    center: PropTypes.bool,
}

export default DefaultNavbar
