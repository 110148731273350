import { Avatar, Badge, Grid } from '@mui/material'
import MKAvatar from 'components/MaterialKit/MKAvatar'
import MKBox from 'components/MaterialKit/MKBox'
import { FC } from 'react'
import './styles.scss'
interface Props {
    children?: any
    badgeContent?: any
    src?: string
    background?: string
    style?: any
    bgColor?: string
}

const CKModalBadge: FC<Props> = (props) => {
    return (
        <Grid
            container
            item
            xs={12}
            style={props.style}
            justifyContent="center"
            mx="auto"
            className="ck-modal-badge"
        >
            <MKBox mt={{ xs: -10, md: -10 }} mb={2} textAlign="center">
                {props.badgeContent ? (
                    <>
                        <Badge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            badgeContent={props.badgeContent}
                        >
                            <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                        </Badge>
                        <MKAvatar
                            src={props.src}
                            className="ck-page-badge-container"
                            size="xxl"
                            shadow="xl"
                            sx={{
                                backgroundColor: props.bgColor ? props.bgColor : '#fffffcc',
                            }}
                        >
                            {props.children}
                        </MKAvatar>
                    </>
                ) : (
                    <>
                        <MKAvatar
                            src={props.src}
                            className="ck-page-badge-container"
                            size="xxl"
                            shadow="xl"
                            sx={{
                                backgroundColor:
                                    (props.bgColor ? props.bgColor : '#fffffcc') + ' !important',
                            }}
                            style={{
                                background: props.background ? props.background : '#ffffffcc',
                            }}
                        >
                            {props.children}
                        </MKAvatar>
                    </>
                )}
            </MKBox>
        </Grid>
    )
}
export default CKModalBadge
