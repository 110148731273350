/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React base styles
import borders from 'assets/theme/base/borders'
import colors from 'assets/theme/base/colors'

// Material Kit 2 React helper functions
import pxToRem from 'assets/theme/functions/pxToRem'

const { borderWidth } = borders
const { light } = colors

export default {
    styleOverrides: {
        root: {
            padding: `${pxToRem(12)} ${pxToRem(16)}`,
            borderBottom: `${borderWidth[1]} solid ${light.main}`,
        },
    },
}
