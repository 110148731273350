import { FC } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

interface Props {
    children: any
    className?: any
    [rest: string]: any
}
const CKModalBody: FC<Props> = ({ children, className, ...rest }) => {
    className = (className ? className : '') + ' d-flex justify-content-center flex-column'

    return (
        <div className={className} {...rest}>
            <PerfectScrollbar style={{ maxHeight: 'calc(70vh - 60px)' }}>
                {children}
            </PerfectScrollbar>
        </div>
    )
}
export default CKModalBody
