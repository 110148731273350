import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import { Card, Divider, useMediaQuery, useTheme } from '@mui/material'
import clsx from 'clsx'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import BugReport from 'models/BugReport'
import { FC, useEffect, useRef, useState } from 'react'
import BugReportService from 'services/BugReportService'

interface Props {
    report: BugReport
}
const BugReportCard: FC<Props> = (props) => {
    const [report, setReport] = useState(props.report)
    const theme = useTheme()
    const isMobile = useMediaQuery('(max-width:768px)')
    const descriptionRef = useRef<HTMLElement>()
    const [isOverflown, setIsOverflown] = useState(false)
    const [expanded, setExpanded] = useState(false)

    useEffect(() => {
        if (!descriptionRef.current) return

        setIsOverflown(
            descriptionRef.current.clientHeight - descriptionRef.current.scrollHeight < 0
        )
    }, [descriptionRef])

    window.onresize = function () {
        if (!descriptionRef.current) return

        setIsOverflown(
            descriptionRef.current.clientHeight - descriptionRef.current.scrollHeight < 0
        )
    }

    return (
        <Card
            style={{
                backgroundColor: theme['palette'].info.main + '10',
                minHeight: '127px',
            }}
            className="d-flex flex-row mb-3"
        >
            <div className="px-2 py-3">
                <MKTypography variant="h2" className="text-center">
                    {report.$score}
                </MKTypography>
                <div
                    className={clsx('d-flex flex-row mt-2', {
                        'flex-column': isMobile,
                    })}
                    style={{ gap: '0.5rem' }}
                >
                    <MKButton
                        size="small"
                        variant="gradient"
                        color="success"
                        disabled={report.$ownVote === 1}
                        onClick={async () => {
                            await BugReportService.upvote(report)
                            const _report = BugReport.fromJSON(report)
                            _report.$ownVote = 1
                            _report.$score++
                            setReport(_report)
                        }}
                    >
                        <ThumbUpIcon />
                    </MKButton>
                    <MKButton
                        size="small"
                        variant="gradient"
                        color="error"
                        disabled={report.$ownVote === -1}
                        onClick={async () => {
                            await BugReportService.downvote(report)
                            const _report = BugReport.fromJSON(report)
                            _report.$ownVote = -1
                            _report.$score--
                            setReport(_report)
                        }}
                    >
                        <ThumbDownIcon />
                    </MKButton>
                </div>
            </div>
            <Divider orientation="vertical" sx={{ mx: 1 }} />
            <div className="p-2 pe-4">
                <MKTypography variant="h6" fontWeight="bold">
                    {report.$shortDescription}
                </MKTypography>
                <MKTypography
                    ref={descriptionRef}
                    variant="body2"
                    className="mt-2"
                    style={
                        expanded
                            ? {}
                            : {
                                textOverflow: 'ellipsis',
                                WebkitLineClamp: isMobile ? '4' : '3',
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                            }
                    }
                >
                    {report.$description}
                </MKTypography>
            </div>
            {isOverflown && (
                <div
                    style={{
                        position: 'absolute',
                        top: '0px',
                        right: 0,
                    }}
                >
                    <MKButton
                        style={{
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                        }}
                        className="px-1"
                        size="large"
                        onClick={() => {
                            setExpanded((prev) => !prev)
                        }}
                    >
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </MKButton>
                </div>
            )}
        </Card>
    )
}
export default BugReportCard
