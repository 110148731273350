import { forwardRef } from 'react'
import MKInputJS from './MKInputJS'

const MKInput = forwardRef((props: any, ref) => {
    return (
        //@ts-ignore
        <MKInputJS ref={ref} {...props} />
    )
})
export default MKInput
