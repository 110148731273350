import { Device } from '@capacitor/device'
import { isPlatform } from '@ionic/react'
import AuthService from './AuthService'
import { AUTH, DEVICES } from './Constants'

const DeviceService = {
    register: async function (token: string) {
        const payload = { push_token: token }

        if (isPlatform('android') || isPlatform('ios')) {
            payload['device_id'] = (await Device.getId()).identifier
            payload['android'] = isPlatform('android')
        } else {
            throw new Error('Device does not use android or ios.')
        }

        return await AuthService.post(`${AUTH}/register-device`, payload)
    },
    getPreferences: async function () {
        if (!(isPlatform('android') || isPlatform('ios'))) {
            console.error('Should not be happening')
            throw new Error('Device does not use android or ios.')
        }
        const deviceId = (await Device.getId()).identifier
        return await AuthService.get(`${DEVICES}/${deviceId}/preferences`)
    },
    updatePreferences: async function (preferences: any) {
        if (!(isPlatform('android') || isPlatform('ios'))) {
            console.error('Should not be happening')
            throw new Error('Device does not use android or ios.')
        }

        const deviceId = (await Device.getId()).identifier
        const payload = {
            preferences: preferences,
        }
        return await AuthService.put(`${DEVICES}/${deviceId}/preferences`, payload)
    },
}
export default DeviceService
