import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import { Grid, useMediaQuery } from '@mui/material'
import bgImage2 from 'assets/images/bgImage2.jpg'
import clsx from 'clsx'
// import CKChatBox from 'components/UI/CKChatBox'
import CKChatRoomCard from 'components/UI/CKChatroomCard'
import { CKHero } from 'components/UI/CKHero'
// import { CKModal } from 'components/UI/CKModal'
import { CKPageBadge, CKPageContent, CKPageTitle } from 'components/UI/CKPageContent'
import { FC, useMemo, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useHistory, useLocation } from 'react-router-dom'
import BeatLoader from 'react-spinners/BeatLoader'
import ChatService, { useChatRooms } from 'services/ChatService'
// import PageChatBody from './PageChatBody'
import CKChatBox from 'components/UI/CKChatBox'
import { CKModal } from 'components/UI/CKModal'
import './style.scss'
interface Props {}
const PageChatOverview: FC<Props> = () => {
    // const [rooms, setRooms] = useState<ChatRoom[]>([])
    const { rooms, isLoading: loading, fetchNextPage } = useChatRooms()
    const [page, setPage] = useState(1)
    const [selectedRoom, setSelectedRoom] = useState(undefined)
    const { search } = useLocation()
    const isMobile = useMediaQuery('(max-width:768px)')

    const history = useHistory()

    useMemo(async () => {
        const query = new URLSearchParams(search)
        const roomSlug = query.get('open')
        if (roomSlug) {
            const room = await ChatService.getChatRoom(roomSlug)
            setSelectedRoom(room)
        }
    }, [search])

    const ChatBox = useMemo(() => {
        return () => {
            if (selectedRoom) {
                return <CKChatBox room={selectedRoom} />
            }
        }
    }, [selectedRoom])

    return (
        <div className="ck-chat-page">
            <CKHero className="ck-chat-hero" bgImage={bgImage2} size="md" />
            <CKPageContent>
                <CKPageBadge background="linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))">
                    <ChatBubbleIcon fontSize="large" style={{ color: 'white' }} />
                </CKPageBadge>
                <CKPageTitle>Berichten</CKPageTitle>

                <Grid container className="justify-content-center">
                    <Grid item xs={12} md={4}>
                        <PerfectScrollbar
                            style={{ height: '100%', maxHeight: '850px', width: '100%' }}
                            onYReachEnd={fetchNextPage}
                        >
                            <div className="px-4">
                                {rooms.map((room) => {
                                    return (
                                        <CKChatRoomCard
                                            room={room}
                                            key={'chat-room-card' + room.$slug}
                                            onClick={() => {
                                                history.push('chat?open=' + room.$slug)
                                                // setSelectedRoom(room);
                                            }}
                                        />
                                    )
                                })}
                            </div>
                            {loading && (
                                <div
                                    className={clsx(
                                        'w-100 d-flex align-items-center justify-content-center',
                                        { 'h-100': rooms.length === 0 }
                                    )}
                                >
                                    <BeatLoader loading={true} color={'#1A73E8'} />
                                </div>
                            )}
                        </PerfectScrollbar>
                    </Grid>
                    {selectedRoom && (
                        <>
                            {isMobile ? (
                                <CKModal
                                    closeButton
                                    open={Boolean(selectedRoom)}
                                    className="ck-chat-modal"
                                    style={{
                                        width: '100%',
                                        height: '100vh',
                                    }}
                                    onClose={() => {
                                        setSelectedRoom(undefined)
                                        history.push('chat')
                                    }}
                                >
                                    <ChatBox />
                                </CKModal>
                            ) : (
                                <Grid item xs={12} md={8} style={{ maxHeight: '850px' }}>
                                    <ChatBox />
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>

                {/* <div className="ck-chat-container">
                    <div className="ck-chat-hero">
                    </div>

                    <PageChatBody
                        center
                        style={{ height: 'calc(100vh - 70px)' }}
                        className="ck-chat-page-body"
                    >
                        <div className="ck-chat-body-container">
                            <div
                                className="ck-chat-overview pt-2"
                                style={{
                                    margin: !isMobile && !selectedRoom && 'auto',
                                }}
                            >
                                <PerfectScrollbar
                                    style={{ height: '100%', width: '100%' }}
                                    onYReachEnd={() => {
                                        if (!loading && page < amountPages)
                                            setPage((prev) => prev + 1)
                                    }}
                                >
                                    <div className="px-4">
                                        {rooms.map((room) => {
                                            return (
                                                <CKChatRoomCard
                                                    room={room}
                                                    key={'chat-room-card' + room.$slug}
                                                    onClick={() => {
                                                        history.push('chat?open=' + room.$slug)
                                                        // setSelectedRoom(room);
                                                    }}
                                                />
                                            )
                                        })}
                                    </div>
                                    {loading && (
                                        <div
                                            className={clsx(
                                                'w-100 d-flex align-items-center justify-content-center',
                                                { 'h-100': rooms.length === 0 }
                                            )}
                                        >
                                            <BeatLoader loading={true} color={'#1A73E8'} />
                                        </div>
                                    )}
                                </PerfectScrollbar>
                            </div>
                            {selectedRoom && (
                                <>
                                    {isMobile ? (
                                        <CKModal
                                            closeButton
                                            open={Boolean(selectedRoom)}
                                            className="ck-chat-modal"
                                            style={{
                                                width: '100%',
                                                height: '100vh',
                                            }}
                                            onClose={() => {
                                                setSelectedRoom(undefined)
                                                history.push('chat')
                                            }}
                                        >
                                            <CKChatBox room={selectedRoom} />
                                        </CKModal>
                                    ) : (
                                        <CKChatBox room={selectedRoom} />
                                    )}
                                </>
                            )}
                        </div>
                    </PageChatBody>
                </div> */}
            </CKPageContent>
        </div>
    )
}
export default PageChatOverview
