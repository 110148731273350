import { FC } from 'react'
import AuthService from 'services/AuthService'
import { useUserContextDispatch } from '.'

interface Props { }
const UserContextComponent: FC<Props> = () => {
    const userDispatch = useUserContextDispatch()

    if (!AuthService.hasJWT()) {
        return
    }

    try {
        AuthService.refreshToken().then((resp) => {
            userDispatch({
                type: 'LOGIN',
                user: resp,
            })
        }).catch(() => {
            console.error('Failed to refresh token')
        })
    } catch (e) {
        console.error(e)
    }


    return <></>
}
export default UserContextComponent
