import { Card, Grid } from '@mui/material'
import MKBox from 'components/MaterialKit/MKBox'
import MKTypography from 'components/MaterialKit/MKTypography'
import User from 'models/User'
import { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { useIsMobile } from 'utils/ViewportUtils'
import './style.scss'
interface Props {
    user: User
}
const CKArtistCard: FC<Props> = ({ user }) => {
    const history = useHistory()
    const isMobile = useIsMobile()

    const UserLabels = () => {
        const labels = []
        if (user.$isOpenMicer) labels.push("Open-Mic'er")
        if (user.$isMC) labels.push('MC')

        return (
            <MKTypography variant="h6" color={'text'} mt={1} className="text-center">
                {labels.join(' / ')}
            </MKTypography>
        )
    }
    return (
        <Card
            sx={{ mt: 3 }}
            className="ck-artist-card"
            onClick={(e) => {
                e.preventDefault()
                history.push(user.$detailUrl)
            }}
        >
            <Grid container>
                <Grid item xs={4} sx={{ mt: -4 }}>
                    <MKBox
                        width="100%"
                        pt={2}
                        pb={{ xs: 1, xl: 2 }}
                        px={{ xs: 1, xl: 2 }}
                        className="d-flex align-items-center flex-column"
                    >
                        <MKBox
                            component="img"
                            maxWidth={isMobile ? '100%' : '5vw'}
                            className="ck-artist-picture"
                            src={user.$picture.$url}
                            alt={user.$first_name + ' ' + user.$last_name}
                            width="100%"
                            borderRadius="md"
                            shadow="lg"
                        />
                        <UserLabels />
                    </MKBox>
                </Grid>
                <Grid item xs={8} className="d-flex align-items-start">
                    <MKBox pt={{ xs: 1, lg: 2.5 }} pb={{ xs: 0.5, lg: 1.5 }} px={1} lineHeight={1}>
                        <MKTypography variant="h5">
                            {user.$first_name} {user.$last_name}
                        </MKTypography>
                        <MKTypography
                            variant="body2"
                            color="text"
                            className="ck-artist-description"
                        >
                            {user.$description}
                        </MKTypography>
                    </MKBox>
                    <MKBox component="div" className="ck-artist-read-more m-3 mt-0">
                        <MKTypography variant="h6" color="info">
                            Bekijk pagina...
                        </MKTypography>
                    </MKBox>
                </Grid>
            </Grid>
        </Card>
    )
}
export default CKArtistCard
