import { useMediaQuery } from '@uidotdev/usehooks'
import { useMemo } from 'react';

export const useIsMobile = () => {
    const isMobile = useMediaQuery('(max-width:768px)');

    const memoizedIsMobile = useMemo(() => {
        return isMobile;
    }, [isMobile]);

    return memoizedIsMobile;
}
