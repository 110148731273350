import ErrorIcon from '@mui/icons-material/Error'
import { Grid, useTheme } from '@mui/material'
import MKButton from 'components/MaterialKit/MKButton'
import MKTypography from 'components/MaterialKit/MKTypography'
import { FC, useMemo } from 'react'
import CKModal from './CKModal'
import CKModalBadge from './CKModalBadge'
import CKModalBody from './CKModalBody'
import CKModalHeader from './CKModalHeader'

interface Props {
    open: boolean
    onClose: () => void
    icon?: any
    color?:
        | 'inherit'
        | 'info'
        | 'disabled'
        | 'action'
        | 'primary'
        | 'secondary'
        | 'error'
        | 'success'
        | 'warning'
    message: string
    label?: any
    onConfirm: () => void
}

const CKConfirmModal: FC<Props> = (props) => {
    const theme = useTheme()
    const open = useMemo(() => {
        return props.open
    }, [props.open])

    return (
        <CKModal open={open} onClose={props.onClose}>
            <CKModalHeader>
                <CKModalBadge
                    bgColor={theme['palette'][props.color ? props.color : 'info']['main'] + '77'}
                >
                    <div style={{ fontSize: '3rem' }}>
                        {props.icon ? (
                            <>{props.icon}</>
                        ) : (
                            <ErrorIcon color={props.color ? props.color : 'info'} />
                        )}
                    </div>
                </CKModalBadge>
                <CKModalBody>
                    <MKTypography variant="body2" className="mt-2">
                        {props.message}
                    </MKTypography>
                    <Grid container className="mt-2" spacing={3}>
                        <Grid item xs={6} className="text-end">
                            <MKButton
                                color={props.color ? props.color : 'info'}
                                variant="gradient"
                                onClick={props.onConfirm}
                            >
                                {props.label ? props.label : 'Bevestigen'}
                            </MKButton>
                        </Grid>
                        <Grid item xs={6} className="text-start">
                            <MKButton onClick={props.onClose}>Annuleren</MKButton>
                        </Grid>
                    </Grid>
                </CKModalBody>
            </CKModalHeader>
        </CKModal>
    )
}
export default CKConfirmModal
