import SaveIcon from '@mui/icons-material/Save'
import { Grid } from '@mui/material'
import bgImage from 'assets/images/bar_sidebar.jpg'
import { CKForm, CKFormBody, CKFormDescription, CKFormSubtitle } from 'components/Form/CKForm'
import CKFormFeedback from 'components/Form/CKFormFeedback'
import CKImageUpload from 'components/Form/CKImageUpload'
// import CKLocationPicker from 'components/Form/CKLocationPicker'
import MKBox from 'components/MaterialKit/MKBox'
import MKButton from 'components/MaterialKit/MKButton'
import MKInput from 'components/MaterialKit/MKInput'
import MKTypography from 'components/MaterialKit/MKTypography'
import { CKHero } from 'components/UI/CKHero'
import { CKPageBadge, CKPageContent, CKPageTitle } from 'components/UI/CKPageContent'
import Workshop from 'models/Workshop'
import { FC, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import WorkshopService, { useWorkshop } from 'services/WorkshopService'
import ImageService from 'services/ImageService'
import EditCalendar from '@mui/icons-material/EditCalendar'
import CKDatePicker from 'components/Form/CKDatePicker'
// import CKSocialsForm from 'components/Form/CKSocialsForm'
// import MKAlert from 'components/MaterialKit/MKAlert'
import CKLoadingModel from 'components/UI/CKLoadingModel'
import { useUserLocations } from 'services/LocationService'
// import SpotConfig from './SpotConfig'
// import { ITicketConfigRef, TicketConfig } from './TicketConfig'
import { CKOrganisationSelect } from 'components/Form/CKOrganisationSelect'

interface Props { }

const PageWorkshopEdit: FC<Props> = () => {
    const workshopId = useParams()['workshopId']

    const [saving, setSaving] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)

    const { hasLocations, isLoading: isLoadingLocations } = useUserLocations();

    // const ticketConfig = useRef<ITicketConfigRef>()

    const [workshop, setWorkshop] = useState<Workshop>()

    const [logoData, setLogoData] = useState<any>()
    const [bannerData, setBannerData] = useState<any>()

    const history = useHistory()

    const [errors, setErrors] = useState<any>({})

    const { mutate } = useWorkshop(workshopId)

    const banner = useMemo(() => {
        if (!workshop || !workshop.$banner || !workshop.$banner.$url) return bgImage

        return workshop.$banner.$url
    }, [workshop])


    useMemo(async () => {
        setLoading(true)
        if (workshopId === 'new') {
            setWorkshop(new Workshop())
        } else {
            try {
                const resp = await WorkshopService.get(workshopId)
                if (!resp.hasRight('FULL')) history.push(resp.$detailUrl)

                setWorkshop(resp)
            } catch (err) {
                console.error(err)
            }
        }
        setLoading(false)
    }, [workshopId, history])

    const renderRegistrationTimes = () => {
        return (
            <>
                <Grid item xs={12} md={6}>
                    <CKDatePicker
                        label="Datum"
                        required
                        defaultValue={workshop.$registration_start_time}
                        onChange={(e) => {
                            const _date = new Date(e)
                            if (workshop.$registration_start_time) {
                                _date.setHours(workshop.$registration_start_time.getHours())
                                _date.setMinutes(workshop.$registration_start_time.getMinutes())
                            }
                            workshop.$registration_start_time = new Date(_date)

                            if (workshop.$registration_end_time) {
                                _date.setHours(workshop.$registration_end_time.getHours())
                                _date.setMinutes(workshop.$registration_end_time.getMinutes())
                            }
                            workshop.$registration_end_time = new Date(_date)
                        }}
                    />
                    <CKFormFeedback>{errors['date']}</CKFormFeedback>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CKDatePicker
                        label="Starttijd"
                        timeOnly
                        required
                        defaultValue={workshop.$registration_start_time}
                        onChange={(e) => {
                            const _date = new Date(e)
                            if (!workshop.$registration_start_time) {
                                workshop.$registration_start_time = _date
                            }

                            workshop.$registration_start_time.setHours(_date.getHours())
                            workshop.$registration_start_time.setMinutes(_date.getMinutes())

                            workshop.$registration_end_time = new Date(workshop.$registration_start_time.getTime() - 30 * 60000)
                        }}
                    />
                    <CKFormFeedback>{errors['registration_start_time']}</CKFormFeedback>
                </Grid>

                <Grid item xs={12} md={6}>
                    <CKDatePicker
                        label="Datum"
                        required
                        defaultValue={workshop.$registration_end_time}
                        onChange={(e) => {
                            const _date = new Date(e)
                            if (workshop.$registration_end_time) {
                                _date.setHours(workshop.$registration_end_time.getHours())
                                _date.setMinutes(workshop.$registration_end_time.getMinutes())
                            }
                            workshop.$registration_end_time = new Date(_date)

                            if (workshop.$registration_end_time) {
                                _date.setHours(workshop.$registration_end_time.getHours())
                                _date.setMinutes(workshop.$registration_end_time.getMinutes())
                            }
                            workshop.$registration_end_time = new Date(_date)
                        }}
                    />
                    <CKFormFeedback>{errors['date']}</CKFormFeedback>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CKDatePicker
                        label="Starttijd"
                        timeOnly
                        required
                        defaultValue={workshop.$registration_end_time}
                        onChange={(e) => {
                            const _date = new Date(e)
                            if (!workshop.$registration_end_time) {
                                workshop.$registration_end_time = _date
                            }

                            workshop.$registration_end_time.setHours(_date.getHours())
                            workshop.$registration_end_time.setMinutes(_date.getMinutes())

                            workshop.$registration_end_time = new Date(workshop.$registration_end_time.getTime() - 30 * 60000)
                        }}
                    />
                    <CKFormFeedback>{errors['registration_end_time']}</CKFormFeedback>
                </Grid>
            </>
        )
    }

    const renderGeneral = () => {
        return (
            <>
                <CKFormSubtitle>
                    <MKTypography variant="label" style={{ fontWeight: 'bold' }}>
                        Algemeen
                    </MKTypography>
                </CKFormSubtitle>
                <Grid item xs={12}>
                    <MKInput
                        label="Naam"
                        error={errors['name'] ? true : false}
                        title="Naam"
                        defaultValue={workshop.$name}
                        InputLabelProps={{ shrink: "true" }}
                        onChange={(e) => {
                            workshop.$name = e.target.value
                        }}
                        required
                        fullWidth
                    />
                    <CKFormFeedback>{errors['name']}</CKFormFeedback>
                </Grid>
                <Grid item xs={12}>
                    <MKInput
                        multiline
                        defaultValue={workshop.$description ?? ""}
                        placeholder="Geef een beschrijving van de workshop..."
                        title="Beschrijving"
                        label="Beschrijving"
                        variant="outlined"
                        onChange={(e) => {
                            workshop.$description = e.target.value
                        }}
                        InputLabelProps={{ shrink: "true" }}
                        rows={4}
                        fullWidth
                    />
                </Grid>
                {/* {renderRegistrationTimes()} */}
                <Grid item xs={12} md={6}>
                    <CKOrganisationSelect
                        defaultOrganisation={workshop.$organisation}
                        onChange={(org) => {
                            workshop.$organisation = org
                        }}
                    />
                </Grid>
                <CKFormSubtitle>
                    <MKTypography variant="label" style={{ fontWeight: 'bold' }}>
                        Media
                    </MKTypography>
                </CKFormSubtitle>
                <Grid item xs={12} md={4}>
                    <MKTypography variant="caption">Logo</MKTypography>
                    <CKImageUpload
                        defaultImage={workshop.$logo}
                        onChange={(
                            files: Array<{
                                fileName: string
                                ext: string
                                content: string | ArrayBuffer
                            }>
                        ) => {
                            setLogoData(files[0])
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    <MKTypography variant="caption">Banner</MKTypography>
                    <CKImageUpload
                        defaultImage={workshop.$banner}
                        onChange={(
                            files: Array<{
                                fileName: string
                                ext: string
                                content: string | ArrayBuffer
                            }>
                        ) => {
                            setBannerData(files[0])
                        }}
                    />
                </Grid>

                {/* <Grid item xs={12}>
                    <CKSocialsForm model={workshop} />
                </Grid> */}
            </>
        )
    }

    const renderForm = () => {
        return (
            <CKForm>
                <CKFormDescription className="mb-3 text-center pt-2">
                    Welkom bij het workshop creatie- en Aanpassingsformulier! Met dit formulier kun
                    je eenvoudig een nieuwe workshop aanmaken of een bestaande workshop bewerken.
                </CKFormDescription>
                {/* {!isLoadingLocations && !hasLocations && (
                    <MKAlert dismissible color="error">
                        Je hebt nog geen locaties om workshops te organiseren.{' '}
                        <a
                            className="mx-1"
                            style={{
                                color: 'white',
                                textDecoration: 'underline',
                            }}
                            href="/admin/location/new"
                        >
                            <u>Klik hier</u>
                        </a>{' '}
                        om een locatie aan te maken!
                    </MKAlert>
                )} */}
                <CKFormBody>
                    <Grid container spacing={3}>
                        {renderGeneral()}
                        {/* <SpotConfig
                            workshop={workshop}
                            onUpdate={(spots) => {
                                console.log(spots)
                                workshop.$spots = spots
                                setWorkshop(workshop)
                            }}
                        />
                        <TicketConfig ref={ticketConfig} workshop={workshop} /> */}
                    </Grid>
                    <Grid container className="text-center">
                        <Grid item justifyContent="center" xs={12} mt={5} mb={2}>
                            <MKButton
                                type="submit"
                                variant="gradient"
                                color="info"
                                onClick={save}
                                disabled={saving}
                            >
                                <SaveIcon className="me-2" />
                                {saving ? (
                                    <ClipLoader size={15} color={'white'} />
                                ) : workshopId === 'new' ? (
                                    'Creëer workshop!'
                                ) : (
                                    'Sla op!'
                                )}
                            </MKButton>
                        </Grid>
                    </Grid>
                </CKFormBody>
            </CKForm>
        )
    }

    const validate = () => {
        const _errors = {}

        const defaultMsg = 'Verplicht veld!'

        _errors['name'] = !workshop.$name ? defaultMsg : false

        // _errors['registration_start_date'] = !workshop.$registration_start_time ? defaultMsg : false
        // _errors['registration_start_time'] = !workshop.$registration_start_time ? defaultMsg : false

        // _errors['registration_end_date'] = !workshop.$registration_start_time ? defaultMsg : false
        // _errors['registration_end_time'] = !workshop.$registration_end_time ? defaultMsg : false

        // if (workshop.$registration_start_time && workshop.$registration_end_time && workshop.$registration_start_time < workshop.$registration_end_time) {
        //     _errors['registration_end_time'] = 'Ongeldige aankomsttijd'
        // }

        // _errors['location'] = !workshop.$location ? defaultMsg : false

        setErrors(_errors)
        return !Object.values(_errors).find((a) => a !== false)
    }

    const save = async () => {
        setSaving(true)
        try {
            const validated = validate()
            if (validated) {
                if (logoData) {
                    workshop.$logo = await ImageService.upload({
                        base64: logoData.content,
                        extension: logoData.ext,
                        name: workshop.$name + ' - LOGO',
                        type: 'LOGO',
                    })
                }

                if (bannerData) {
                    workshop.$banner = await ImageService.upload({
                        base64: bannerData.content,
                        extension: bannerData.ext,
                        name: workshop.$name + ' - BANNER',
                        type: 'BANNER',
                    })
                }

                // if (ticketConfig.current) workshop.$tickets = ticketConfig.current.getTickets()

                let _workshop
                if (workshopId === 'new') {
                    _workshop = await WorkshopService.post(workshop)
                } else {
                    _workshop = await WorkshopService.update(workshop)
                    await mutate()
                }
                history.push(_workshop.$detailUrl)
            }
        } catch (e) {
            console.error(e)
        }
        setSaving(false)
    }

    return (
        <>
            <MKBox>
                {workshop ? (
                    <>
                        <CKHero size="md" bgImage={banner} />
                        <CKPageContent>
                            <CKPageBadge src={workshop.$logo && workshop.$logo.$url}>
                                {workshop.$logo ? (
                                    <></>
                                ) : (
                                    <EditCalendar fontSize="large" style={{ color: 'white' }} />
                                )}
                            </CKPageBadge>
                            <CKPageTitle>
                                {workshopId === 'new' ? 'Creëer een workshop' : 'Pas workshop aan'}
                            </CKPageTitle>
                            {workshop && !isLoadingLocations && renderForm()}
                        </CKPageContent>
                    </>
                ) : (
                    <CKLoadingModel loading={loading} notFoundMessage="Workshop niet gevonden" />
                )}
            </MKBox>
        </>
    )
}
export default PageWorkshopEdit
