import MKBox from 'components/MaterialKit/MKBox'
import { FC } from 'react'
import './style.scss'
interface Props {
    children: any
    [rest: string]: any
}
const CKForm: FC<Props> = ({ children, ...rest }) => {
    return (
        <MKBox
            className="ck-form"
            // bgColor="rgba(255, 255, 255, 0.8)"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mx={{ xs: 0, md: 3, lg: 10, xl: 15 }}
            sx={{ boxShadow: 0 }}
            {...rest}
        >
            {children}
        </MKBox>
    )
}
export default CKForm
