import { Card, Grid } from '@mui/material'
import MKAlert from 'components/MaterialKit/MKAlert'
import MKButton from 'components/MaterialKit/MKButton'
import MKInput from 'components/MaterialKit/MKInput'
import { BookmarkAddIcon, DeleteIcon, ErrorIcon } from 'components/UI/CKIcons'
import Event from 'models/Event'
import Ticket from 'models/Ticket'
import {   useMemo, useState } from 'react'

export interface ITicketConfig {
    event: Event;
    onUpdate: (tickets: Ticket[]) => void;
}

export const TicketConfig = ({event, onUpdate}: ITicketConfig ) => {
    const [tickets, setTickets] = useState<Ticket[]>(event.$tickets || [])

    const removeTicket = (index: number) => {
        const _tickets = [...tickets]
        _tickets.splice(index, 1)
        setTickets(_tickets)
    }

    useMemo(() => {
        onUpdate(tickets)
    }, [tickets, onUpdate])

    const TicketBlock = ({ ticket, index }: { ticket: Ticket; index: number }) => {
        return (
            <Grid item xs={12}>
                <Card className="py-5 px-3 w-100">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <MKInput
                                label="Titel"
                                title="Titel"
                                defaultValue={ticket.$title}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    ticket.$title = e.target.value
                                }}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MKInput
                                type="standard"
                                multiline
                                defaultValue={ticket.$description}
                                placeholder="Geef een beschrijving van de locatie zodat mensen je kunnen vinden..."
                                title="Beschrijving"
                                label="Beschrijving"
                                onChange={(e) => {
                                    ticket.$description = e.target.value
                                }}
                                InputLabelProps={{ shrink: true }}
                                rows={3}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MKInput
                                type="standard"
                                label="Prijs (€)"
                                title="Prijs"
                                defaultValue={ticket.$price}
                                onChange={(e) => {
                                    ticket.$price = parseInt(e.target.value)
                                }}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MKInput
                                type="standard"
                                label="Aantal"
                                title="Aantal"
                                defaultValue={ticket.$amount}
                                onChange={(e) => {
                                    ticket.$amount = parseInt(e.target.value)
                                }}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} className="text-center">
                            <MKButton
                                variant={'outlined'}
                                color="error"
                                size="small"
                                className="m-1"
                                onClick={(e) => {
                                    e.preventDefault()
                                    removeTicket(index)
                                }}
                            >
                                <DeleteIcon className="me-2" /> Verwijder ticket
                            </MKButton>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        )
    }

    if(!event.$organisation || !event.$organisation.$mollieConnected) {
        return (
            <MKAlert color="error">
                <div className="d-flex align-items-center justify-content-center flex-column">
                <ErrorIcon fontSize='large' className='mb-3'/>
                    <p className="m-0 p-0 text-center">Je moet een mollie account hebben en gekoppeld zijn met de organisatie om tickets te kunnen verkopen.</p>
                </div>
            </MKAlert>
        )
    }

    return (
        <Grid container className='mb-3'>
            <Grid item className="text-center" xs={12}>
                <MKButton
                    size="small"
                    type="submit"
                    variant="outlined"
                    color="info"
                    onClick={() => {
                        // props.event.$tickets.concat([new Ticket()])
                        setTickets((prevTickets) => prevTickets.concat([new Ticket()]))
                    }}
                >
                    <div className="d-flex align-items-center justify-content-center">
                        <BookmarkAddIcon className="me-2 ck-edit-icon" fontSize="large" />
                        <p className="m-0 p-0">Ticket toevoegen</p>
                    </div>
                </MKButton>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    {tickets &&
                        tickets.map((ticket, index) => {
                            return (
                                <TicketBlock
                                    key={`ticket-block-${index}`}
                                    index={index}
                                    ticket={ticket}
                                />
                            )
                        })}
                </Grid>
            </Grid>
        </Grid>
    )
}
