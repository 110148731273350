import ChatMessage from './ChatMessage'
import CKModel from './CKModel'
import Image from './Image'
import User from './User'

export default class ChatRoom extends CKModel {
    private users: User[] | undefined
    private icon: Image | undefined
    private amount_unread: number | undefined
    private last_message: ChatMessage | undefined

    public get $className() {
        return 'chat'
    }

    constructor(
        users: User[] | undefined = undefined,
        icon: Image | undefined = undefined,
        amount_unread: number | undefined = undefined
    ) {
        super()

        this.users = users
        this.icon = icon
        this.amount_unread = amount_unread
    }

    public get $users(): User[] | undefined {
        return this.users
    }
    public set $users(value: User[] | undefined) {
        this.users = value
    }

    public get $icon(): Image | undefined {
        return this.icon
    }
    public set $icon(value: Image | undefined) {
        this.icon = value
    }

    public get $amountUnread(): number | undefined {
        return this.amount_unread
    }
    public set $amountUnread(value: number | undefined) {
        this.amount_unread = value
    }

    public get $lastMessage(): ChatMessage | undefined {
        return this.last_message
    }
    public set $lastMessage(value: ChatMessage | undefined) {
        this.last_message = value
    }

    public static fromJSON(json: any): ChatRoom {
        let result = new ChatRoom()
        result = Object.assign(result, json)

        if (json.users) {
            result.$users = []
            for (const user of json.users) {
                result.$users.push(User.fromJSON(user))
            }
        }

        if (json.icon) {
            result.$icon = Image.fromJSON(json.icon)
        }

        if (json.last_message) {
            result.$lastMessage = ChatMessage.fromJSON(json.last_message)
        }

        return result
    }
}
