import { useUserContext } from 'context/UserContext'
import { FC, useMemo } from 'react'
import { Route, useLocation } from 'react-router-dom'
import routes from 'routes'

interface Props {
    setDisableOverflow: (value: boolean) => void
}
const CKRoutes: FC<Props> = (props) => {
    const { pathname } = useLocation()
    const userContext = useUserContext()

    useMemo(() => {
        if (!routes) {
            props.setDisableOverflow(false)
        }

        const route = routes.find((a) => a.route === window.location.pathname)
        if (route) {
            return route.fullscreen
                ? props.setDisableOverflow(true)
                : props.setDisableOverflow(false)
        }
        return props.setDisableOverflow(false)
    }, [pathname])

    return (
        <>
            {routes.map((route: any, index: number) => {
                if (route.collapse) {
                    return getRoutes(route.collapse)
                }

                if (route.route) {
                    return (
                        <Route
                            exact
                            path={route.route}
                            component={
                                userContext.user &&
                                userContext.user.$id &&
                                Boolean(route.auth_component)
                                    ? route.auth_component
                                    : route.component
                            }
                            key={'route-' + index}
                        />
                    )
                }

                return <></>
            })}
        </>
    )
}
export default CKRoutes

const getRoutes: any = (allRoutes: any[]) => allRoutes.map((route: any, index: number) => {})
