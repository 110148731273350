import { Card, CardMedia, useMediaQuery } from '@mui/material'
import MKAvatar from 'components/MaterialKit/MKAvatar'
import MKBox from 'components/MaterialKit/MKBox'
import MKTypography from 'components/MaterialKit/MKTypography'
import Organisation from 'models/Organisation'
import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import './style.scss'
import defaultBg from 'assets/images/user_banner_default.jpg'

interface Props {
    organisation: Organisation;
    useAdminUrl?: boolean;
}
const CKOrganisationCompactCard: FC<Props> = (props) => {
    const isMobile = useMediaQuery('(max-width:768px)')
    const history = useHistory()

    return (
        <>
            <MKBox
                mb={isMobile ? 2 : 1}
                className="ck-organisation-compact-container w-100"
                onClick={(e) => {
                    if (props.useAdminUrl) {
                        history.push(props.organisation.$adminUrl)
                    } else {
                        history.push(props.organisation.$detailUrl)
                    }
                }}
            >
                <Card>
                    <CardMedia
                        className="ck-organisation-compact-banner"
                        image={props.organisation.$banner?.$url ?? defaultBg}
                    />
                    <MKAvatar
                        src={props.organisation.$logo.$url}
                        className="ck-organisation-compact-badge"
                        size="lg"
                        shadow="xl"
                    />
                    <MKTypography variant="h5" my={1} className={'event-card-title text-center'}>
                        {props.organisation.$name}
                    </MKTypography>
                </Card>
            </MKBox>
        </>
    )
}
export default CKOrganisationCompactCard
