import { Container, useMediaQuery } from '@mui/material'
import bgImage from 'assets/images/bgImage.jpg'
import bgImage2 from 'assets/images/bgImage2.jpg'
import MKTypography from 'components/MaterialKit/MKTypography'
import { CKHero } from 'components/UI/CKHero'
import { CKPageBody, CKPageContent } from 'components/UI/CKPageContent'
import { FC } from 'react'

interface Props {}
const PageTermsAndConditions: FC<Props> = () => {
    const isMobile = useMediaQuery('(max-width:768px)')

    const AccountRegistration = () => {
        return (
            <>
                <MKTypography variant="h5" className="text-center my-2">
                    1. Accountregistratie en Gebruikersprofielen
                </MKTypography>
                <MKTypography variant="body2" fontWeight="bold">
                    1.1 Registratiegegevens:
                </MKTypography>
                Om toegang te krijgen tot het ComedyKit Platform, dient u een account aan te maken
                door nauwkeurige en actuele informatie te verstrekken, waaronder uw naam,
                geboortedatum, e-mailadres en andere vereiste informatie. U bent verantwoordelijk
                voor het bijwerken van uw accountgegevens om deze actueel en accuraat te houden.
                <MKTypography variant="body2" fontWeight="bold" className="mt-3">
                    1.2 Gebruikersprofiel:
                </MKTypography>
                U kunt een gebruikersprofiel maken waarin u informatie over uzelf kunt delen,
                inclusief een beschrijving. Het is ten strengste verboden om ongepaste, beledigende,
                of discriminerende inhoud in uw profiel op te nemen. Het Platform behoudt zich het
                recht voor om gebruikersprofielen te verwijderen die in strijd zijn met deze
                Voorwaarden.
            </>
        )
    }

    const PlatformUsage = () => {
        return (
            <>
                <MKTypography variant="h5" className="text-center mb-2 mt-5">
                    2. Gebruik van het ComedyKit Platform
                </MKTypography>
                <MKTypography variant="body2" fontWeight="bold">
                    2.1 Toegestane Gebruikers:
                </MKTypography>
                Het ComedyKit Platform is bedoeld voor organisatoren, comedians en bezoekers van
                open mic comedy-evenementen in België.
                <MKTypography variant="body2" fontWeight="bold" className="mt-3">
                    2.2 Gebruiksrichtlijnen:
                </MKTypography>
                U stemt ermee in om het ComedyKit Platform te gebruiken in overeenstemming met alle
                toepasselijke wetten en regelgeving, evenals de richtlijnen van het Platform. Het is
                niet toegestaan om:
                <ol type="a" className="mt-2">
                    <li>
                        Inhoud te plaatsen die inbreuk maakt op de rechten van anderen, waaronder
                        auteursrechten, handelsmerken, privacyrechten, of andere intellectuele
                        eigendomsrechten;
                    </li>
                    <li>
                        Inhoud te plaatsen die lasterlijk, obsceen, discriminerend, beledigend of
                        anderszins ongepast is;
                    </li>
                    <li>
                        Inhoud te plaatsen die virussen, malware, spyware of andere schadelijke
                        software bevat of verspreidt;
                    </li>
                    <li>
                        Ongeautoriseerde toegang te verkrijgen tot accounts van andere gebruikers,
                        persoonlijke informatie te verzamelen of op enigerlei wijze inbreuk te maken
                        op de privacy van anderen.
                    </li>
                </ol>
                <MKTypography variant="body2" fontWeight="bold" className="mt-3">
                    2.3 Verantwoordelijkheid voor inhoud:
                </MKTypography>
                U bent als enige verantwoordelijk voor de inhoud die u plaatst op het ComedyKit
                Platform, inclusief uw gebruikersprofiel en eventuele bijdragen aan open mic
                comedy-evenementen. Het Platform is niet verantwoordelijk voor de inhoud geplaatst
                door gebruikers, maar behoudt zich het recht voor om inhoud te verwijderen die in
                strijd is met deze Voorwaarden.
            </>
        )
    }

    const Privacy = () => {
        return (
            <>
                <MKTypography variant="h5" className="text-center mb-2 mt-5">
                    3. Privacy en Gegevensverwerking
                </MKTypography>
                <MKTypography variant="body2" fontWeight="bold">
                    3.1 Gegevensverzameling en -gebruik:
                </MKTypography>
                Door gebruik te maken van het ComedyKit Platform stemt u in met de verzameling en
                verwerking van uw persoonlijke gegevens zoals beschreven in ons{' '}
                <MKTypography component="a" variant="body2" href="/privacy" color="info">
                    Privacybeleid
                </MKTypography>
                .
            </>
        )
    }

    const Ending = () => {
        return (
            <>
                <MKTypography variant="h5" className="text-center my-2 mt-5">
                    4. Beëindiging
                </MKTypography>
                <MKTypography variant="body2" fontWeight="bold">
                    4.1 Beëindiging door Gebruiker:
                </MKTypography>
                U kunt uw account op elk moment verwijderen door contact op te nemen met de
                klantenservice van het ComedyKit Platform.
                <MKTypography variant="body2" fontWeight="bold" className="mt-3">
                    4.2 Beëindiging door het ComedyKit Platform:
                </MKTypography>
                Het ComedyKit Platform behoudt zich het recht voor om uw account op te schorten of
                te beëindigen zonder voorafgaande kennisgeving als u deze Voorwaarden schendt.
            </>
        )
    }

    const Disclaimer = () => {
        return (
            <>
                <MKTypography variant="h5" className="text-center my-2 mt-5">
                    5. Disclaimer en Aansprakelijkheidsbeperking
                </MKTypography>
                <MKTypography variant="body2" fontWeight="bold">
                    5.1 Geen Garantie:
                </MKTypography>
                Het ComedyKit Platform wordt geleverd zoals het is, zonder enige garantie van welke
                aard dan ook, expliciet of impliciet. U gebruikt het ComedyKit Platform op eigen
                risico.
                <MKTypography variant="body2" fontWeight="bold" className="mt-3">
                    5.2 Beperkte Aansprakelijkheid:
                </MKTypography>
                In geen geval zal het ComedyKit Platform aansprakelijk zijn voor enige indirecte,
                incidentele, speciale, punitieve, of gevolgschade, inclusief verlies van winst,
                goodwill, gegevens of andere immateriële verliezen die voortvloeien uit of verband
                houden met uw gebruik van het Platform.
            </>
        )
    }

    return (
        <>
            <CKHero
                bgImage={isMobile ? bgImage2 : bgImage}
                title={'Gebruikersvoorwaarden'}
                size="md"
                maxHeight={'500px'}
                height={'30vh'}
            />
            <CKPageContent>
                <CKPageBody>
                    <Container>
                        <MKTypography variant="body2" className=" text-center mt-4 mb-3">
                            Laatst bijgewerkt op 01/10/2023 <br></br>
                            <br></br>
                            Welkom bij het ComedyKit Open Mic Comedy Platform in België (hierna
                            "ComedyKit"). Dit zijn de gebruiksvoorwaarden (hierna "Voorwaarden")
                            waaronder u toegang kunt krijgen tot en gebruik kunt maken van het
                            Platform. Gelieve deze Voorwaarden zorgvuldig te lezen voordat u zich
                            registreert of gebruikmaakt van het Platform. Door gebruik te maken van
                            het Platform, gaat u akkoord met deze Voorwaarden.
                        </MKTypography>
                        <AccountRegistration />
                        <PlatformUsage />
                        <Privacy />
                        <Ending />
                        <Disclaimer />
                        <MKTypography
                            variant="body2"
                            fontWeight="bold"
                            className="mt-5 text-center"
                        >
                            Wijzigingen in de Voorwaarden
                        </MKTypography>
                        Het ComedyKit Platform behoudt zich het recht voor om deze Voorwaarden op
                        elk moment te wijzigen. Het is uw verantwoordelijkheid om regelmatig de
                        Voorwaarden te controleren op updates. Uw voortdurende gebruik van het
                        ComedyKit Platform na eventuele wijzigingen wordt beschouwd als uw
                        acceptatie van de gewijzigde Voorwaarden.
                        <MKTypography
                            variant="body2"
                            fontWeight="bold"
                            className="mt-5 text-center"
                        >
                            Contact
                        </MKTypography>
                        Neem voor vragen of opmerkingen over deze Voorwaarden contact met ons op via
                        info@comedykit.be.
                        <MKTypography variant="body2" className="mt-5  text-center">
                            Door gebruik te maken van het ComedyKit Open Mic Comedy Platform in
                            België, gaat u akkoord met deze Voorwaarden. Veel plezier op het
                            Platform en bedankt voor uw deelname aan het versterken van het open mic
                            comedy circuit in België!
                        </MKTypography>
                    </Container>
                </CKPageBody>
            </CKPageContent>
        </>
    )
}
export default PageTermsAndConditions
