import { GoogleMap } from '@react-google-maps/api'
import Location from 'models/Location'
import { FC, useMemo, useRef, useState } from 'react'
import CKLocationMarker from './CKLocationMarker'
import './style.scss'

interface Props {
    locations: Location[]
    center?: { lat: number; lng: number }
}
const CKLocationMapOverview: FC<Props> = (props) => {
    const mapRef = useRef()
    const [zoom, setZoom] = useState(8)

    const locations = useMemo(() => {
        return props.locations
    }, [props.locations])

    const center = useMemo(() => {
        if (!props.center) return { lat: 50.501048, lng: 4.476476 }
        setZoom(10)
        return props.center
    }, [props.center])

    return (
        <>
            <div className="ck-location-map-container">
                <GoogleMap
                    ref={mapRef}
                    zoom={zoom}
                    center={center}
                    mapContainerClassName="map-container"
                >
                    {locations.map((loc, index) => {
                        return (
                            <CKLocationMarker location={loc} key={'location-marker-' + loc.$id} />
                        )
                    })}
                </GoogleMap>
            </div>
        </>
    )
}
export default CKLocationMapOverview
