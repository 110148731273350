import { scrollPercentage } from 'components/Generic/CKFloatingContainer'
import MKTypography from 'components/MaterialKit/MKTypography'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import './style.scss'

const CKCoffee = () => {
    const { pathname } = useLocation()
    const hide = useMemo(() => {
        return pathname === '/login' || pathname === '/register'
    }, [pathname])

    let prevScrollpos = window.pageYOffset
    window.onscroll = function () {
        const currentScrollPos = window.pageYOffset
        const elem = document.getElementById('ck-coffee-banner')
        if (!elem) return
        const scroll = scrollPercentage()
        if (prevScrollpos < currentScrollPos && scroll > 50) {
            elem.style.display = 'block'
        } else {
            elem.style.display = 'none'
        }
        prevScrollpos = currentScrollPos
    }

    return (
        <>
            {!hide && (
                <MKTypography
                    id="ck-coffee-banner"
                    color="info"
                    className="ck-coffee-banner"
                    component="a"
                    href="https://www.buymeacoffee.com/comedykitbe"
                    target="_blank"
                >
                    ☕ Kunt ge een koffieke missen?
                </MKTypography>
            )}
        </>
    )
}

export default CKCoffee
