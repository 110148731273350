import { FC } from 'react'

interface Props {
    children: any
    className?: string
    [rest: string]: any
}
const CKModalHeader: FC<Props> = ({ children, className, ...rest }) => {
    className = (className ? className : '') + ' text-center'
    return (
        <div className={className} {...rest}>
            {children}
        </div>
    )
}
export default CKModalHeader
